import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tdoc-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  @Input() public title: string;

  constructor() {}
}
