import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, retry } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import {
  APIService,
  Appointment,
  AppointmentsQuery,
  AppointmentStatus,
} from '../API.service';
import { ProfileService } from '../profile/profile.service';
import {
  createISODateTime,
  getFirstDayOfWeekFromDate,
  getLastDayOfWeekFromDate,
  isSameDay,
} from '../shared/date-conversions/date-conversions';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsService {
  private pendingAppointments = new BehaviorSubject<Appointment[]>([]);
  public pendingAppointments$: Observable<Appointment[]> =
    this.pendingAppointments
      .asObservable()
      .pipe(map((a) => this.sortAppointments(a, 'desc')));

  private todaysAppointments = new BehaviorSubject<Appointment[]>([]);
  public todaysAppointments$: Observable<Appointment[] | []> =
    this.todaysAppointments
      .asObservable()
      .pipe(map((a) => this.sortAppointments(a)));

  private weeklyAppointments = new BehaviorSubject<Appointment[]>([]);
  public weeklyAppointments$: Observable<Appointment[] | []> =
    this.weeklyAppointments
      .asObservable()
      .pipe(map((a) => this.sortAppointments(a)));

  private activeWeekFrom: string;
  private activeWeekTo: string;

  constructor(private api: APIService, private profileService: ProfileService) {
    this.getTodaysAppointments().pipe(take(1)).subscribe();
    this.getPendingAppointments().pipe(take(1)).subscribe();
    this.subscribeToAppointmentUpdates();
  }

  public clearCache() {
    this.pendingAppointments = new BehaviorSubject<Appointment[]>([]);
    this.pendingAppointments$ = this.pendingAppointments
      .asObservable()
      .pipe(map((a) => this.sortAppointments(a, 'desc')));

    this.todaysAppointments = new BehaviorSubject<Appointment[]>([]);
    this.todaysAppointments$ = this.todaysAppointments
      .asObservable()
      .pipe(map((a) => this.sortAppointments(a)));

    this.weeklyAppointments = new BehaviorSubject<Appointment[]>([]);
    this.weeklyAppointments$ = this.weeklyAppointments
      .asObservable()
      .pipe(map((a) => this.sortAppointments(a)));
  }

  public reloadAppointments() {
    this.getTodaysAppointments().pipe(take(1)).subscribe();
    this.getPendingAppointments().pipe(take(1)).subscribe();
  }

  public getWeeklyAppointments(someDay: Date): Observable<Appointment[]> {
    this.activeWeekFrom = getFirstDayOfWeekFromDate(someDay);
    this.activeWeekTo = getLastDayOfWeekFromDate(someDay);

    return from(
      this.api.Appointments(
        {
          from: this.activeWeekFrom,
          to: this.activeWeekTo,
          status: AppointmentStatus.DOCTOR_CONFIRMED,
        },
        { size: 1000 },
      ),
    ).pipe(
      map((appointments: AppointmentsQuery) => {
        this.weeklyAppointments.next(appointments.results);
        return appointments.results;
      }),
    );
  }

  public cancelAppointmentRequest(appointmentId: string) {
    return from(this.api.CancelAppointment(appointmentId));
  }

  public confirmAppointment(appointmentId: string) {
    return from(this.api.ConfirmAppointment(appointmentId));
  }

  public joinAppointmentMeeting(appointmentId: string) {
    return from(this.api.JoinAppointmentMeeting(appointmentId));
  }

  public getAppointmentFileUrl({
    appointmentId,
    fileAttachmentId,
  }: {
    appointmentId: string;
    fileAttachmentId: string;
  }) {
    return from(
      this.api.AppointmentAttachmentDownloadUrl({
        appointmentId,
        fileAttachmentId,
      }),
    );
  }

  getPendingAppointments(): Observable<Appointment[]> {
    return from(
      this.api.Appointments({
        status: AppointmentStatus.DOCTOR_CONFIRMATION_PENDING,
      }),
    ).pipe(
      map((appointments: AppointmentsQuery) => {
        this.pendingAppointments.next(appointments.results);
        return appointments.results;
      }),
    );
  }

  private getTodaysAppointments(): Observable<Appointment[]> {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return from(
      this.api.Appointments({
        from: createISODateTime(today),
        to: createISODateTime(tomorrow),
      }),
    ).pipe(
      map((appointments: AppointmentsQuery) => {
        const filteredResults = appointments.results.filter(
          (appointment: Appointment) => {
            const status = appointment.appointmentStatus;
            return (
              status === AppointmentStatus.DOCTOR_CONFIRMED ||
              status === AppointmentStatus.INITIALISING ||
              status === AppointmentStatus.STARTED
            );
          },
        );
        this.todaysAppointments.next(filteredResults);
        return filteredResults;
      }),
    );
  }

  private subscribeToAppointmentUpdates(): void {
    this.profileService.doctorProfile$
      .pipe(
        take(1),
        tap((doctorProfile) => {
          const doctorId = doctorProfile.doctorId;
          this.api.OnAppointmentUpdatedListener(doctorId).subscribe({
            next: (result: any) => {
              console.log(
                'Got appointment by subscription',
                JSON.stringify(result),
              );
              const appointment: Appointment =
                result?.value?.data?.onAppointmentUpdated;
              this.updatePendingAppointments(appointment);
              this.updateTodaysAppointments(appointment);
              this.updateWeeklyAppointments(appointment);
            },
            complete: () => {
              console.log('OnAppointmentUpdatedListener COMPLETE');
            },
          });
        }),
        retry(3),
      )
      .subscribe();
  }

  private updatePendingAppointments(appointment: Appointment): void {
    let pendingAppointments = this.pendingAppointments.value;
    if (
      appointment.appointmentStatus ===
      AppointmentStatus.DOCTOR_CONFIRMATION_PENDING
    ) {
      pendingAppointments = this.upsertAppointment(
        pendingAppointments,
        appointment,
      );
    } else {
      pendingAppointments = this.removeAppointment(
        pendingAppointments,
        appointment,
      );
    }
    this.pendingAppointments.next(pendingAppointments);
  }

  private updateTodaysAppointments(appointment: Appointment) {
    const today = new Date(Date.now());
    const dateFrom = new Date(appointment.from);
    if (!isSameDay(today, dateFrom)) {
      return;
    }
    const appointmentStatus = appointment.appointmentStatus;
    let todaysAppointments = this.todaysAppointments.value;
    if (
      appointmentStatus === AppointmentStatus.DOCTOR_CONFIRMED ||
      appointmentStatus === AppointmentStatus.INITIALISING ||
      appointmentStatus === AppointmentStatus.STARTED
    ) {
      todaysAppointments = this.upsertAppointment(
        todaysAppointments,
        appointment,
      );
    } else {
      todaysAppointments = this.removeAppointment(
        todaysAppointments,
        appointment,
      );
    }
    this.todaysAppointments.next(todaysAppointments);
  }

  private updateWeeklyAppointments(appointment: Appointment) {
    if (
      this.activeWeekFrom > appointment.from ||
      appointment.from > this.activeWeekTo
    ) {
      return;
    }
    const appointmentStatus = appointment.appointmentStatus;
    let weeklyAppointments = this.weeklyAppointments.value;
    if (
      appointmentStatus === AppointmentStatus.DOCTOR_CONFIRMED ||
      appointmentStatus === AppointmentStatus.INITIALISING ||
      appointmentStatus === AppointmentStatus.STARTED
    ) {
      weeklyAppointments = this.upsertAppointment(
        weeklyAppointments,
        appointment,
      );
    } else {
      weeklyAppointments = this.removeAppointment(
        weeklyAppointments,
        appointment,
      );
    }
    this.weeklyAppointments.next(weeklyAppointments);
  }

  private upsertAppointment(
    appointments: Appointment[] = [],
    newAppointment: Appointment,
  ): Appointment[] {
    const currentAppointments = Array.from(appointments);
    const foundAppointmentIndex = currentAppointments.findIndex(
      (a) => a.appointmentId === newAppointment.appointmentId,
    );
    if (foundAppointmentIndex !== -1) {
      currentAppointments.splice(foundAppointmentIndex, 1, newAppointment);
    } else {
      currentAppointments.push(newAppointment);
    }
    return currentAppointments;
  }

  private removeAppointment(
    appointments: Appointment[] = [],
    newAppointment: Appointment,
  ): Appointment[] {
    return appointments.filter(
      (a) => a.appointmentId !== newAppointment.appointmentId,
    );
  }

  private sortAppointments(
    appointments: Appointment[] = [],
    sortDirection: SortDirection = 'asc',
  ): Appointment[] {
    const sort = {
      asc: (a: any, b: any) => (a.from > b.from ? 1 : a.from < b.from ? -1 : 0),
      desc: (a: any, b: any) =>
        a.from < b.from ? 1 : a.from > b.from ? -1 : 0,
    };
    return Array.from(appointments).sort(sort[sortDirection]);
  }
}

type SortDirection = 'asc' | 'desc';
