import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TdocFormlyModule } from '../tdoc-formly/tdoc-formly.module';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DoctorVideoTileModule } from '../../patients/patient-call/doctor-video-tile/doctor-video-tile.module';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceSetupComponent } from './device-setup.component';

@NgModule({
  declarations: [DeviceSetupComponent],
  imports: [
    CommonModule,
    TdocFormlyModule,
    MaterialModule,
    FormsModule,
    DoctorVideoTileModule,
    ReactiveFormsModule,
    FormlyModule.forChild(),
    TranslateModule,
  ],
  exports: [DeviceSetupComponent],
})
export class DeviceSetupModule {}
