import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { PatientMedicalFileQuery } from 'src/app/API.service';
import { PatientsService } from '../patients.service';

@Component({
  selector: 'tdoc-patient-files',
  templateUrl: './patient-files.component.html',
  styleUrls: ['./patient-files.component.scss'],
})
export class PatientFilesComponent implements OnInit {
  @Input() medicalFile: PatientMedicalFileQuery;
  translations$: Observable<any>;
  translations: any;

  patientFiles: any;

  private accessId: string;

  constructor(
    private patientService: PatientsService,
    private translate: TranslateService,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.patientFiles = this.medicalFile.patientFiles;
    this.accessId = this.medicalFile.accessId;
  }

  openFile(file): void {
    this.patientService
      .getPatientFileUrl(file.fileAttachmentId, this.accessId)
      .pipe(
        take(1),
        tap((response) => window.open(response.url)),
      )
      .subscribe();
  }

  private initTranslations(): void {
    this.translations$ = this.translate.get('PATIENTS').pipe(
      tap((translations) => {
        this.translations = translations;
      }),
    );
  }
}
