<mat-card appearance="outlined">
  <div *ngIf="showDetails" class="container">
    <div class="title">
      <h1 class="header">
        {{ 'COOKIES_BANNER.COOKIES_DETAILS.TITLE' | translate }}
      </h1>
    </div>
    <div class="section">
      <div class="row">
        <mat-slide-toggle
          [(ngModel)]="essential"
          [disabled]="true"
        ></mat-slide-toggle>
        <mat-label>{{
          'COOKIES_BANNER.COOKIES_DETAILS.ESSENTIAL.TITLE' | translate
        }}</mat-label>
      </div>
      <div class="row">
        <span>{{
          'COOKIES_BANNER.COOKIES_DETAILS.ESSENTIAL.MESSAGE' | translate
        }}</span>
      </div>
    </div>
    <div class="section">
      <div class="row">
        <mat-slide-toggle [(ngModel)]="tracking"></mat-slide-toggle>
        <mat-label>{{
          'COOKIES_BANNER.COOKIES_DETAILS.TRACKING.TITLE' | translate
        }}</mat-label>
      </div>
      <div class="row">
        <span>{{
          'COOKIES_BANNER.COOKIES_DETAILS.TRACKING.MESSAGE' | translate
        }}</span>
      </div>
    </div>
    <mat-dialog-actions class="button-container">
      <button
        mat-raised-button
        color="primary"
        type="submit"
        (click)="enableCookies()"
        [mat-dialog-close]="true"
      >
        {{ 'COOKIES_BANNER.COOKIES_DETAILS.ACCEPT' | translate }}
      </button>
      <button mat-raised-button (click)="toSummary()">
        {{ 'COOKIES_BANNER.COOKIES_DETAILS.BACK' | translate }}
      </button>
    </mat-dialog-actions>
  </div>
  <div *ngIf="!showDetails" class="container">
    <div class="title">
      <h1 class="header">
        {{ 'COOKIES_BANNER.COOKIES_SUMMARY.TITLE' | translate }}
      </h1>
    </div>
    <div class="section">
      <span>{{ 'COOKIES_BANNER.COOKIES_SUMMARY.MESSAGE' | translate }}</span>
      <div class="new-line">
        <button id="cookieModal" class="link-button" (click)="toDetails()">
          <mat-icon>settings</mat-icon>
          {{ 'COOKIES_BANNER.COOKIES_SUMMARY.SETTING' | translate }}
        </button>
      </div>
    </div>
    <mat-dialog-actions class="button-container">
      <button mat-raised-button [mat-dialog-close]="true" (click)="onDecline()">
        {{ 'COOKIES_BANNER.COOKIES_SUMMARY.REJECT_ALL' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="onConfirm()"
        [mat-dialog-close]="true"
      >
        {{ 'COOKIES_BANNER.COOKIES_SUMMARY.ACCEPT_ALL' | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</mat-card>
