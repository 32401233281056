<mat-card appearance="outlined">
  <mat-card-content class="card">
    <ng-container *ngIf="meetingStatus$ | async as status">
      <ng-container *ngIf="status === meetingStatus.Succeeded">
        <div class="patient tile">
          <tdoc-patient-video-tile></tdoc-patient-video-tile>
          <div class="doctor-tile">
            <tdoc-doctor-video-tile></tdoc-doctor-video-tile>
          </div>
        </div>
        <tdoc-call-controls></tdoc-call-controls>
      </ng-container>

      <ng-container *ngIf="status !== meetingStatus.Succeeded">
        <div class="call-ended">
          <mat-icon>call_end</mat-icon>
          {{ 'PATIENTS.NO_CALL.ENDED' | translate }}
        </div>
      </ng-container>
    </ng-container>
  </mat-card-content>
</mat-card>
