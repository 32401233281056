export interface AppointmentPanelConfig {
  type?: AppointmentType;
  title?: string;
  titleTranslateKey?: string;
}

export enum AppointmentType {
  call = 'call',
  confirmation = 'confirmation',
}
