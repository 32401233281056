import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { Visit } from 'src/app/API.service';
import { PatientsService } from '../../patients.service';

@Component({
  selector: 'tdoc-past-visit-dialog',
  templateUrl: './past-visit-dialog.component.html',
  styleUrls: ['./past-visit-dialog.component.scss'],
})
export class PastVisitDialogComponent implements OnInit {
  translations$: Observable<any>;
  translations: any;
  formGroup = new UntypedFormGroup({});
  model: Visit;
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { visit: any },
    private translate: TranslateService,
    private patientService: PatientsService,
  ) {}

  ngOnInit(): void {
    this.model = this.data.visit;
    this.translations$ = this.translate.get('PATIENTS.PASTVISITS').pipe(
      tap((translations) => {
        this.translations = translations;
        this.setupFormFields();
      }),
    );
  }

  openPrescription(visitId: string, prescriptionId: string): void {
    console.log('requesting url ', JSON.stringify([visitId, prescriptionId]));
    this.patientService
      .getPrescriptionUrl(visitId, prescriptionId)
      .pipe(take(1))
      .subscribe((next) => {
        console.log('got url for prescription ', JSON.stringify(next));
        window.open(next.url, '_blank');
      });
  }

  private setupFormFields(): void {
    this.fields = [
      {
        key: 'clinicalReport.anamnese',
        type: 'textarea',
        templateOptions: {
          label: this.translations['ANAMNESE'],
          rows: 4,
          resize: 'none',
          readonly: true,
        },
      },
      {
        key: 'clinicalReport.diagnosis',
        type: 'textarea',
        templateOptions: {
          label: this.translations['DIAGNOSIS'],
          rows: 4,
          resize: 'none',
          readonly: true,
        },
      },
      {
        key: 'clinicalReport.treatment',
        type: 'textarea',
        templateOptions: {
          label: this.translations['TREATMENT'],
          rows: 4,
          resize: 'none',
          readonly: true,
        },
      },
    ];
  }
}
