import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { PatientVideoTileComponent } from './patient-video-tile.component';

@NgModule({
  declarations: [PatientVideoTileComponent],
  imports: [CommonModule, MaterialModule],
  exports: [PatientVideoTileComponent],
})
export class PatientVideoTileModule {}
