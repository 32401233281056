import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import {
  DoctorProfileQuery,
  HoursOfAvailability,
  HoursOfAvailabilityInput,
  Weekday,
} from 'src/app/API.service';
import { ProfileService } from 'src/app/profile/profile.service';
import { AddAvailabilityDialogComponent } from './add-availability-dialog/add-availability-dialog.component';
import {
  Availability,
  HoursOfAvailabilityService,
} from './hours-of-availability.service';

@Component({
  selector: 'tdoc-hours-of-availability',
  templateUrl: './hours-of-availability.component.html',
  styleUrls: ['./hours-of-availability.component.scss'],
})
export class HoursOfAvailabilityComponent implements OnInit {
  @Input() profile: DoctorProfileQuery;

  translations$: Observable<any>;
  translations: any;
  displayedColumns: string[] = ['weekday', 'from', 'to', 'actions'];
  initData$: Observable<HoursOfAvailabilityInput[]>;
  dataSource: MatTableDataSource<HoursOfAvailabilityInput>;

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private profileService: ProfileService,
    private hoursOfAvailablilityService: HoursOfAvailabilityService,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.initData();
  }

  addAvailability() {
    const availabilities = this.mapToAvailabilities(
      this.profile.hoursOfAvailability || [],
    );
    const dialog = this.dialog.open(AddAvailabilityDialogComponent, {
      data: { availabilities },
    });

    dialog
      .afterClosed()
      .pipe(
        take(1),
        tap(() => this.initData()),
      )
      .subscribe();
  }

  deleteAvailability(availabilityToDelete: HoursOfAvailabilityInput): void {
    const availabilities = this.profile.hoursOfAvailability;
    const deleteIndex = availabilities.findIndex(
      (availability: HoursOfAvailabilityInput) => {
        return availability === availabilityToDelete;
      },
    );

    availabilities.splice(deleteIndex, 1);
    const hoursOfAvailabilities: HoursOfAvailabilityInput[] =
      availabilities.map(({ weekday, from, to, timezone }) => {
        return {
          weekday,
          from,
          to,
          timezone,
        };
      });
    this.profileService
      .addHourOfAvailability(hoursOfAvailabilities)
      .pipe(
        tap(() => {
          this.initData();
        }),
      )
      .subscribe();
  }

  private initData() {
    const availabilities = this.sortAvailabilites(
      this.profile.hoursOfAvailability || [],
    );
    this.dataSource = new MatTableDataSource(availabilities);
  }

  private initTranslations() {
    this.translations$ = this.translate.get('AVAILABILITY').pipe(
      tap((translations) => {
        this.translations = translations;
      }),
    );
  }

  private sortAvailabilites(
    availabilities: HoursOfAvailabilityInput[],
  ): HoursOfAvailabilityInput[] {
    return availabilities.sort(
      (a: HoursOfAvailabilityInput, b: HoursOfAvailabilityInput) => {
        const weekdayA = this.hoursOfAvailablilityService.getWeekdayNumber(
          a.weekday,
        );
        const weekdayB = this.hoursOfAvailablilityService.getWeekdayNumber(
          b.weekday,
        );
        if (weekdayA > weekdayB) {
          return 1;
        }
        if (weekdayA < weekdayB) {
          return -1;
        }
        if (weekdayA === weekdayB) {
          if (a.from > b.from) {
            return 1;
          } else {
            return -1;
          }
        }
      },
    );
  }

  private mapToAvailabilities(
    hoursOfAvailability: HoursOfAvailability[],
  ): Availability[] {
    return hoursOfAvailability.map((availablity) => {
      return this.hoursOfAvailablilityService.mapToAvailability(availablity);
    });
  }
}
