import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DoctorProfileQuery } from 'src/app/API.service';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'tdoc-profile-address',
  templateUrl: './profile-address.component.html',
  styleUrls: ['./profile-address.component.scss'],
})
export class ProfileAddressComponent implements OnInit {
  @Input() profile: DoctorProfileQuery;

  translations$: Observable<any>;
  translations: any;
  formGroup = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];

  constructor(
    private profileService: ProfileService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.model = this.profile;
  }

  onChange() {
    this.profileService.updateProfile(this.model).pipe(take(1)).subscribe();
  }

  private initTranslations() {
    this.translations$ = this.translate.get('PROFILE.ADDRESS').pipe(
      tap((translations) => {
        this.translations = translations;
        this.setupFormFields();
      }),
    );
  }

  private setupFormFields() {
    this.fields = [
      {
        fieldGroupClassName: 'form-group display-flex',
        fieldGroup: [
          {
            className: 'flex-3',
            id: 'street',
            key: 'address.street',
            type: 'input',
            templateOptions: {
              label: this.translations.STREET,
            },
            modelOptions: {
              debounce: { default: 1000 },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'form-group display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            id: 'postCode',
            key: 'address.postCode',
            type: 'input',
            templateOptions: {
              label: this.translations.POSTCODE,
            },
            modelOptions: {
              debounce: { default: 1000 },
            },
          },
          {
            className: 'flex-3',
            id: 'city',
            key: 'address.city',
            type: 'input',
            templateOptions: {
              label: this.translations.CITY,
            },
            modelOptions: {
              debounce: { default: 1000 },
            },
          },
        ],
      },
      {
        key: 'address.country.name',
        id: 'country',
        type: 'input',
        templateOptions: {
          label: this.translations.COUNTRY,
        },
        modelOptions: {
          debounce: { default: 1000 },
        },
      },
    ];
  }
}
