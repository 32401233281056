import { Injectable } from '@angular/core';
import moment from 'moment';
import { HoursOfAvailabilityInput, Weekday } from 'src/app/API.service';

@Injectable({
  providedIn: 'root',
})
export class HoursOfAvailabilityService {
  public isValidAvailability({ weekday, from, to }: Availability): boolean {
    let isValid = false;
    if (weekday && from && to) {
      isValid = true;
    }
    return isValid;
  }

  public isValidHoursOfAvailability({
    weekday,
    from,
    to,
    timezone,
  }: HoursOfAvailabilityInput): boolean {
    let isValid = false;
    if (weekday && from && to && timezone) {
      isValid = true;
    }
    return isValid;
  }

  public mapToHoursOfAvailability({
    weekday,
    from,
    to,
  }: Availability): HoursOfAvailabilityInput {
    return {
      weekday,
      from: moment()
        .startOf('week')
        .add(this.getWeekdayNumber(weekday), 'days')
        .hours(this.getHours(from))
        .minutes(this.getMinutes(from))
        .toISOString(),
      to: moment()
        .startOf('week')
        .add(this.getWeekdayNumber(weekday), 'days')
        .hours(this.getHours(to))
        .minutes(this.getMinutes(to))
        .toISOString(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  }

  public mapToAvailability({
    weekday,
    from,
    to,
  }: HoursOfAvailabilityInput): Availability {
    return {
      weekday,
      from: moment(from).format('HH:mm'),
      to: moment(to).format('HH:mm'),
    };
  }

  public getWeekdayNumber(weekday: Weekday): number {
    const weekdayMap = new Map<string, number>([
      [Weekday.MONDAY, 1],
      [Weekday.TUESDAY, 2],
      [Weekday.WEDNESDAY, 3],
      [Weekday.THURSDAY, 4],
      [Weekday.FRIDAY, 5],
      [Weekday.SATURDAY, 6],
      [Weekday.SUNDAY, 7],
    ]);
    return weekdayMap.get(weekday);
  }

  private getHours(hoursAndMinutes: string): number {
    const hours = hoursAndMinutes.slice(0, 2);
    return Number(hours);
  }

  private getMinutes(hoursAndMinutes: string): number {
    const hours = hoursAndMinutes.slice(-2);
    return Number(hours);
  }
}

export interface Availability {
  weekday: Weekday;
  from: string;
  to: string;
}
