<ng-container *ngIf="profile$ | async as profile">
  <div class="layout">
    <tdoc-account-settings
      class="account-card"
      [profile]="profile"
      [hideMedicalSpecialistSelection]="true"
    ></tdoc-account-settings>
    <tdoc-hours-of-availability
      class="availability-card"
      [profile]="profile"
    ></tdoc-hours-of-availability>
    <ng-container *ngIf="profile.medicalSpecialist">
      <tdoc-price class="price-card" [profile]="profile"> </tdoc-price>
    </ng-container>
    <tdoc-availability class="availability-card"></tdoc-availability>
    <tdoc-clinic class="clinic-card" [profile]="profile"></tdoc-clinic>
  </div>
</ng-container>
