import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, from, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
  APIService,
  DeleteDoctorFileMutation,
  DoctorProfileInput,
  DoctorProfileQuery,
  GetDoctorFileDownloadUrlQuery,
  GetDoctorFilesQuery,
  HoursOfAvailabilityInput,
  SaveDoctorProfileMutation,
} from '../API.service';
import { RegionCode } from '../shared/region/region-code.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private doctorProfile = new BehaviorSubject<DoctorProfileQuery>(undefined);
  doctorProfile$: Observable<DoctorProfileQuery> = this.doctorProfile
    .asObservable()
    .pipe(filter((profile) => profile !== undefined));

  medicalSpecialties: any[] = [];

  constructor(
    private api: APIService,
    private translate: TranslateService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {
    this.getMedicalSpecialties();
  }

  getProfile() {
    from(this.api.DoctorProfile())
      .pipe(
        take(1),
        tap((profile: DoctorProfileQuery) => {
          this.doctorProfile.next(profile);
        }),
      )
      .subscribe();
  }

  updateProfile(
    profile: DoctorProfileInput,
  ): Observable<SaveDoctorProfileMutation> {
    return from(
      this.api.SaveDoctorProfile(this.createDoctorProfileInput(profile)),
    ).pipe(
      tap((returnedProfile: DoctorProfileQuery) => {
        this.doctorProfile.next(returnedProfile);
      }),
    );
  }

  updateRegion(region: RegionCode): Observable<SaveDoctorProfileMutation> {
    return from(this.api.SaveDoctorProfile({ region: { code: region } })).pipe(
      tap((profile: DoctorProfileQuery) => {
        this.doctorProfile.next(profile);
      }),
    );
  }

  getDoctorFiles(): Observable<GetDoctorFilesQuery[]> {
    return from(this.api.GetDoctorFiles());
  }

  createUploadFileUrl(): Observable<string> {
    return from(this.api.CreateDoctorFileUploadUrl());
  }

  deleteDoctorFile(fileId: string): Observable<DeleteDoctorFileMutation> {
    return from(this.api.DeleteDoctorFile(fileId));
  }

  getFileDownloadUrl(
    fileId: string,
  ): Observable<GetDoctorFileDownloadUrlQuery> {
    return from(this.api.GetDoctorFileDownloadUrl(fileId));
  }

  getMedicalSpecialties(): Observable<{ value: string; label: any }[]> {
    const medicalSpecialtiesLabels$ = this.translate
      .get('MEDICAL_SPECIALTIES')
      .pipe(take(1));
    const medicalSpecialties$ = from(this.api.MedicalSpecialties()).pipe(
      take(1),
    );

    return combineLatest([medicalSpecialties$, medicalSpecialtiesLabels$]).pipe(
      take(1),
      map(([medicalSpecialties, medicalSpecialtiesLabels]) => {
        return medicalSpecialties.medicalSpecialties.map((specialty) => ({
          value: specialty,
          label: medicalSpecialtiesLabels[specialty],
        }));
      }),
    );
  }

  addHourOfAvailability(availability: HoursOfAvailabilityInput[]) {
    return from(
      this.api.SaveDoctorProfile({ hoursOfAvailability: availability }),
    ).pipe(
      tap((profile: DoctorProfileQuery) => {
        this.doctorProfile.next(profile);
      }),
    );
  }

  public uploadProfilePicture(file) {
    return from(this.api.CreateDoctorProfilePictureUploadUrl()).pipe(
      switchMap((url) => {
        let headers = new HttpHeaders();
        headers = headers.set('x-amz-server-side-encryption', 'AES256');
        headers = headers.set(
          'Content-Disposition',
          `attachment; filename=\"${file.name}"`,
        );
        return this.http.put(url, file, { headers });
      }),
    );
  }

  public getImage(url: string): Observable<SafeUrl> {
    let headers = new HttpHeaders();
    headers = headers.set('Pragma-directive', 'no-cache');
    headers = headers.set('Cache-directive', 'no-cache');
    headers = headers.set('Cache-control', 'no-cache');
    headers = headers.set('Pragma', 'no-cache');
    headers = headers.set('Expires', '0');
    return this.http
      .get(url, { headers, responseType: 'blob' })
      .pipe(
        map((v) =>
          this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(v)),
        ),
      );
  }

  public deleteProfilePicture() {
    return from(this.api.DeleteDoctorProfilePicture());
  }

  public clearProfile() {
    this.doctorProfile = new BehaviorSubject<DoctorProfileQuery>(undefined);
    this.doctorProfile$ = this.doctorProfile
      .asObservable()
      .pipe(filter((profile) => profile !== undefined));
  }

  private createDoctorProfileInput(input: any) {
    return {
      firstName: input?.firstName || null,
      lastName: input?.lastName || null,
      doctorIdentifierNumber: input.doctorIdentifierNumber || null,
      doctorStampCode: input.doctorStampCode || null,
      clinicId: input.clinicId || null,
      address:
        {
          street: input?.address?.street || null,
          address: input?.address?.address || null,
          postCode: input?.address?.postCode || null,
          city: input?.address?.city || null,
          country:
            {
              name: input?.address?.country?.name || null,
              code: input?.address?.country?.code || null,
            } || null,
        } || null,
      phoneNumber: input?.phoneNumber || null,
      biography: input?.biography || null,
      expertise: input?.expertise || null,
      region:
        {
          name: input?.region?.name || null,
          code: input?.region?.code || null,
        } || null,
      medicalSpecialist: !!input?.medicalSpecialist ? 'specialist' : null,
      medicalSpecialties: input?.medicalSpecialties || null,
      medicalSubspecialties: input?.medicalSubspecialties || null,
      languages: input?.languages || null,
      appLanguage: input?.appLanguage || null,
    };
  }
}
