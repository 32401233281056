import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { AppRoutes } from 'src/app/shared/routes';

@Component({
  selector: 'tdoc-app-confirm-code',
  templateUrl: './confirm-code.component.html',
  styleUrls: ['./confirm-code.component.scss'],
})
export class ConfirmCodeComponent implements OnInit {
  email = environment.confirm.email;
  confirmForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl({ value: this.email }),
    code: new UntypedFormControl('', [Validators.required, Validators.min(3)]),
  });

  get codeInput() {
    return this.confirmForm.get('code');
  }

  get emailInput() {
    return this.confirmForm.get('email');
  }

  constructor(
    private _router: Router,
    private _notification: NotificationService,
  ) {}

  ngOnInit() {
    this.confirmForm.get('email').setValue(this.email);
  }

  sendAgain() {
    Auth.resendSignUp(this.emailInput.value)
      .then(() => this._notification.show('A code has been emailed to you'))
      .catch(() => this._notification.show('An error occurred'));
  }

  confirmCode() {
    Auth.confirmSignUp(this.emailInput.value, this.codeInput.value)
      .then((data: any) => {
        if (
          data === 'SUCCESS' &&
          environment.confirm.email &&
          environment.confirm.password
        ) {
          Auth.signIn(this.email, environment.confirm.password)
            .then(() => {
              this._router.navigate([AppRoutes.stepper]);
            })
            .catch((error: any) => {
              this._router.navigate([AppRoutes.signin]);
            });
        }
      })
      .catch((error: any) => {
        console.log(error);
        this._notification.show(error.message);
      });
  }
}
