import { Component, OnInit } from '@angular/core';
import { ProfileService } from './profile.service';

@Component({
  selector: 'tdoc-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  profile$;

  constructor(public profileService: ProfileService) {}

  ngOnInit(): void {
    this.profile$ = this.profileService.doctorProfile$;
    this.profileService.getProfile();
  }
}
