import { Component } from '@angular/core';

@Component({
  selector: 'tdoc-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
})
export class AppointmentsComponent {
  constructor() {}
}
