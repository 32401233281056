import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppointmentPanelModule } from '../appointment-panel/appointment-panel.module';
import { TodaysScheduleComponent } from './todays-schedule.component';

@NgModule({
  declarations: [TodaysScheduleComponent],
  imports: [CommonModule, AppointmentPanelModule],
  exports: [TodaysScheduleComponent],
})
export class TodaysScheduleModule {}
