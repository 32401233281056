import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { Appointment, PatientFileAttachment } from 'src/app/API.service';
import { AppointmentsService } from 'src/app/appointments/appointments.service';
import { PatientsService } from 'src/app/patients/patients.service';
import { ChimeService } from 'src/app/shared/chime/chime.service';
import { DoctorStatusService } from 'src/app/shared/doctor-status/doctor-status.service';
import { AppRoutes } from 'src/app/shared/routes';
import {
  AppointmentPanelConfig,
  AppointmentType,
} from './appointment-panel-config';
import { DevicesService } from '../../shared/chime/devices.service';
import {
  ActionDialogComponent,
  DialogActions,
} from '../../shared/action-dialog/action-dialog.component';
import { SettingsDialogComponent } from '../../patients/patient-call/call-controls/settings-dialog/settings-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'tdoc-appointment-panel',
  templateUrl: './appointment-panel.component.html',
  styleUrls: ['./appointment-panel.component.scss'],
})
export class AppointmentPanelComponent {
  @Input() config: AppointmentPanelConfig;
  @Input() appointments: Appointment[];
  step = 0;
  appointmentType: AppointmentType;

  constructor(
    private appointmentsService: AppointmentsService,
    private chime: ChimeService,
    private router: Router,
    private patientsService: PatientsService,
    private doctorStatusService: DoctorStatusService,
    private deviceService: DevicesService,
    private dialog: MatDialog,
  ) {}

  setStep(index: number): void {
    this.step = index;
  }

  openAttachment(
    appointment: Appointment,
    attachment: PatientFileAttachment,
  ): void {
    const { appointmentId } = appointment;
    const { fileAttachmentId } = attachment;
    this.appointmentsService
      .getAppointmentFileUrl({ appointmentId, fileAttachmentId })
      .pipe(
        take(1),
        tap((response) => window.open(response.url)),
      )
      .subscribe();
  }

  onConfirm(appointmentId: string): void {
    this.appointmentsService
      .confirmAppointment(appointmentId)
      .pipe(take(1))
      .subscribe(() => {
        // reloading only after confirmation happened
        this.appointmentsService.reloadAppointments();
      });
  }

  onCancel(appointmentId: string): void {
    this.appointmentsService
      .cancelAppointmentRequest(appointmentId)
      .pipe(take(1))
      .subscribe();
  }

  onStartMeeting(appointmentId: string): void {
    this.deviceService.deviceList$.pipe(take(1)).subscribe(async (next) => {
      if (next.currentAudioInputDevice && next.currentVideoInputDevice) {
        await this.router.navigate([AppRoutes.device_check, { appointmentId }]);
      } else {
        // no camera or mic
        const dialog = this.dialog.open(ActionDialogComponent, {
          data: {
            title: 'DEVICE_CHECK.TITLE',
            message: 'DEVICE_CHECK.MESSAGE',
            cancelTitle: 'DEVICE_CHECK.CANCEL',
            okTitle: 'DEVICE_CHECK.OK',
          },
          width: '500px',
        });
        dialog.afterClosed().subscribe((result) => {
          if (result === DialogActions.confirm) {
            this.dialog.open(SettingsDialogComponent, {
              width: '500px',
            });
          }
        });
      }
    });
  }

  checkMeetingStartTime(appointment: Appointment): boolean {
    const rightNow = Date.now();
    const fiveMinBefore = rightNow + 300000; // 5 min before meeting start time
    const from = new Date(appointment.from).valueOf();
    const to = new Date(appointment.to).valueOf();
    return from < fiveMinBefore && rightNow < to;
  }
}
