import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppRoutes } from 'src/app/shared/routes';

@Component({
  selector: 'tdoc-appointment-request-dialog',
  templateUrl: './appointment-request-dialog-component.html',
  styleUrls: ['./appointment-request-dialog-component.scss'],
})
export class AppointmentRequestDialogComponent implements OnInit, OnDestroy {
  @ViewChild('ringtone', { static: true })
  private ringtone: ElementRef<HTMLAudioElement>;
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<AppointmentRequestDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async playSound(): Promise<void> {
    this.ringtone.nativeElement.crossOrigin = 'anonymous';
    console.log('loading sound');
    this.ringtone.nativeElement.load();
    console.log('sound is loaded');
    try {
      await this.ringtone.nativeElement.play();
      console.log('sound is played');
    } catch (e) {
      console.log('could not play sound ', e);
    }
  }

  onConfirm(): void {
    this.dialogRef.close();
    this.router.navigate([AppRoutes.appointments]);
  }

  onDecline(): void {}
}
