import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Weekday } from 'src/app/API.service';
import { ProfileService } from 'src/app/profile/profile.service';
import { AvailabilityHours } from '../../types/availability-hours';
import {
  Availability,
  HoursOfAvailabilityService,
} from '../hours-of-availability.service';

@Component({
  selector: 'tdoc-add-availability-dialog',
  templateUrl: './add-availability-dialog.component.html',
  styleUrls: ['./add-availability-dialog.component.scss'],
})
export class AddAvailabilityDialogComponent implements OnInit {
  translations$: Observable<any>;
  translations: any;
  formGroup = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];

  private Workdays = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { availabilities: Availability[] },
    private translate: TranslateService,
    public dialogRef: MatDialogRef<AddAvailabilityDialogComponent>,
    private profileService: ProfileService,
    private hoursOfAvailabilityService: HoursOfAvailabilityService,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
  }

  private initTranslations() {
    this.translations$ = this.translate.get('AVAILABILITY').pipe(
      tap((translations) => {
        this.translations = translations;
        this.setupFormFields();
      }),
    );
  }

  onAdd() {
    const availabilities = [...this.data.availabilities, this.model];
    const hoursOfAvailability = availabilities.map((availability) => {
      if (this.hoursOfAvailabilityService.isValidAvailability(availability)) {
        return this.hoursOfAvailabilityService.mapToHoursOfAvailability(
          availability,
        );
      }
    });
    this.profileService
      .addHourOfAvailability(hoursOfAvailability)
      .pipe(
        tap(() => {
          this.dialogRef.close();
        }),
      )
      .subscribe();
  }

  private setupFormFields() {
    this.translateWeekdayOptions();
    this.fields = [
      {
        fieldGroupClassName: 'form-group display-flex',
        fieldGroup: [
          {
            className: 'flex-3',
            key: `weekday`,
            type: 'select',
            templateOptions: {
              label: this.translations.DAY,
              options: this.Workdays,
              required: true,
            },
          },
          {
            className: 'flex-3',
            key: `from`,
            type: 'select',
            templateOptions: {
              label: this.translations.FROM,
              options: AvailabilityHours,
              required: true,
            },
          },
          {
            className: 'flex-3',
            key: `to`,
            type: 'select',
            templateOptions: {
              label: this.translations.TO,
              options: AvailabilityHours,
              required: true,
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                const timeFromControl = field.parent.formControl.get(`from`);
                const timeFromControl$ = timeFromControl.valueChanges;
                timeFromControl$
                  .pipe(
                    tap((mondayFrom) => {
                      field.templateOptions.options = AvailabilityHours.filter(
                        (timeslot) => timeslot.value > mondayFrom,
                      );
                    }),
                  )
                  .subscribe();
              },
            },
          },
        ],
      },
    ];
  }

  private translateWeekdayOptions() {
    this.Workdays = [
      { label: this.translations.MONDAY, value: Weekday.MONDAY },
      { label: this.translations.TUESDAY, value: Weekday.TUESDAY },
      { label: this.translations.WEDNESDAY, value: Weekday.WEDNESDAY },
      { label: this.translations.THURSDAY, value: Weekday.THURSDAY },
      { label: this.translations.FRIDAY, value: Weekday.FRIDAY },
      { label: this.translations.SATURDAY, value: Weekday.SATURDAY },
      { label: this.translations.SUNDAY, value: Weekday.SUNDAY },
    ];
  }
}
