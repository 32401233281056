import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ActionDialogModule } from 'src/app/shared/action-dialog/action-dialog.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { TdocFormlyModule } from 'src/app/shared/tdoc-formly/tdoc-formly.module';
import { PatientPrescriptionModule } from '../patient-prescription/patient-prescription.module';
import { PatientControlsComponent } from './patient-controls.component';

@NgModule({
  declarations: [PatientControlsComponent],
  imports: [
    CommonModule,
    TdocFormlyModule,
    MaterialModule,
    TranslateModule,
    PatientPrescriptionModule,
    ActionDialogModule,
  ],
  exports: [PatientControlsComponent],
})
export class PatientControlsModule {}
