import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppointmentsModule } from './appointments/appointments.module';
import { AuthModule } from './auth/auth.module';
import { AuthService } from './auth/auth.service';
import { DashboardModule } from './dashboard/dashboard.module';
import { NavigationModule } from './navigation/navigation.module';
import { PatientsModule } from './patients/patients.module';
import { ProfileStepperModule } from './profile/profile-stepper/profile-stepper.module';
import { ProfileModule } from './profile/profile.module';
import { SettingsModule } from './settings/settings.module';
import { MaterialModule } from './shared/material/material.module';
import { TdocFormlyModule } from './shared/tdoc-formly/tdoc-formly.module';
import { PatientDetailsModule } from './patients/patient-details/patient-details.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProfileWaitForActivationModule } from './profile/profile-wait-for-activation/profile-wait-for-activation.module';
import { CookieBannerModule } from './shared/cookie-banner/cookie-banner.module';
import { FooterModule } from './shared/footer/footer.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  // Use the commented version to get translation files from assets
  // return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(
    http,
    `${environment.assetsEndpoint}/lokalise/`,
    '/translation.json',
  );
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TdocFormlyModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    DashboardModule,
    ProfileStepperModule,
    ProfileWaitForActivationModule,
    NavigationModule,
    PatientsModule,
    ProfileModule,
    SettingsModule,
    AppointmentsModule,
    PatientDetailsModule,
    ScrollingModule,
    MatProgressSpinnerModule,
    CookieBannerModule,
    FooterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
  ],
  providers: [
    AuthService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
