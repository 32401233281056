import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { AppointmentPanelComponent } from './appointment-panel.component';
import { DeviceSetupModule } from '../../shared/device-setup/device-setup.module';

@NgModule({
  declarations: [AppointmentPanelComponent],
  imports: [CommonModule, TranslateModule, MaterialModule, DeviceSetupModule],
  exports: [AppointmentPanelComponent],
})
export class AppointmentPanelModule {}
