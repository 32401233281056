import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import {
  ActionDialogComponent,
  DialogActions,
} from 'src/app/shared/action-dialog/action-dialog.component';
import { ChimeService } from 'src/app/shared/chime/chime.service';
import { DoctorStatusService } from 'src/app/shared/doctor-status/doctor-status.service';
import { PatientPrescriptionComponent } from '../patient-prescription/patient-prescription.component';
import { PatientsService } from '../patients.service';
import { MeetingStatus } from '../../shared/types/meeting-status';
import { AppRoutes } from '../../shared/routes';
import { Router } from '@angular/router';

@Component({
  selector: 'tdoc-patient-controls',
  templateUrl: './patient-controls.component.html',
  styleUrls: ['./patient-controls.component.scss'],
})
export class PatientControlsComponent {
  constructor(
    private dialog: MatDialog,
    private patientsService: PatientsService,
    private chimeService: ChimeService,
    private doctorStatus: DoctorStatusService,
    private router: Router,
  ) {}

  openPrescription() {
    this.dialog.open(PatientPrescriptionComponent, { width: '800px' });
  }

  endVisit() {
    const dialog = this.dialog.open(ActionDialogComponent, {
      data: { title: 'PATIENTS.CONTROLS.END_VISIT_HEADER' },
      width: '500px',
    });

    dialog
      .afterClosed()
      .pipe(
        tap((result) => {
          if (result === DialogActions.confirm) {
            this.stopWorkOnPatient();
            this.router.navigate([AppRoutes.dashboard]);
          }
        }),
      )
      .subscribe();
  }

  private stopWorkOnPatient(): void {
    this.patientsService.terminateVisit();
    this.chimeService.stop();
    this.doctorStatus.hangUpCall();
    this.chimeService.setMeetingStatus(MeetingStatus.Ended);
    this.doctorStatus.setAvailable();
  }
}
