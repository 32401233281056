import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, share, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export type AppLanguage =
  | 'en'
  | 'bg'
  | 'al'
  | 'hu'
  | 'ro'
  | 'hr'
  | 'de'
  | 'mk'
  | 'ru'
  | 'uk'
  | 'sk'
  | 'fr'
  | 'es'
  | 'it';

@Injectable({
  providedIn: 'root',
})
export class AuthLanguageSelectionService {
  appLanguanges$;
  selectedLanguage: AppLanguage;
  private supportedAppLanguages: AppLanguage[] = [
    'al',
    'bg',
    'de',
    'en',
    'hr',
    'hu',
    'mk',
    'ro',
    'ru',
    'sk',
    'uk',
    'es',
    'fr',
    'it',
  ];
  private defaultLanguage: AppLanguage = 'en';

  constructor(private translate: TranslateService, private http: HttpClient) {
    const browserLang = this.translate.getBrowserLang();
    const storedLang = localStorage.getItem('teledocAppLanguage');
    if (storedLang) {
      this.selectedLanguage =
        this.supportedAppLanguages.find((lang) => lang === storedLang) ||
        this.defaultLanguage;
    } else {
      this.selectedLanguage =
        this.supportedAppLanguages.find((lang) => lang === browserLang) ||
        this.defaultLanguage;
    }
    this.translate.use(this.selectedLanguage);
  }

  public initLanguages(): void {
    this.appLanguanges$ = this.http
      .get(`${environment.assetsEndpoint}/settings.json`)
      .pipe(
        take(1),
        map((result: any) => result.languages),
        share(),
      );
  }

  selectLanguage(selectedLanguage) {
    this.selectedLanguage = selectedLanguage;
    this.translate.use(selectedLanguage);
    localStorage.setItem('teledocAppLanguage', selectedLanguage);
  }
}
