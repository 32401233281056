import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TdocFormlyModule } from '../../shared/tdoc-formly/tdoc-formly.module';
import { MaterialModule } from '../../shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { PatientsListComponent } from './patients-list.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [PatientsListComponent],
  imports: [
    CommonModule,
    TdocFormlyModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forChild(),
    TranslateModule,
    ScrollingModule,
    MatProgressSpinnerModule,
    InfiniteScrollModule,
  ],
  exports: [PatientsListComponent],
})
export class PatientsListModule {}
