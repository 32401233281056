import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppointmentsComponent } from './appointments/appointments.component';
import { ConfirmCodeComponent } from './auth/confirm-code/confirm-code.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidenavComponent } from './navigation/sidenav/sidenav.component';
import { PatientsComponent } from './patients/patients.component';
import { ProfileStepperComponent } from './profile/profile-stepper/profile-stepper.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { ActivatedGuard } from './shared/guards/activated.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { LeaveMeetingGuard } from './shared/guards/leaveMeeting.guard';
import { UnauthGuard } from './shared/guards/unauth.guard';
import { AppRoutes } from './shared/routes';
import { PatientDetailsComponent } from './patients/patient-details/patient-details.component';
import { ProfileWaitForActivationComponent } from './profile/profile-wait-for-activation/profile-wait-for-activation.component';
import { RequestedActivationGuard } from './shared/guards/requested-activation.guard';
import { CallOnlyComponent } from './patients/call-only/call-only.component';
import { DeviceSetupComponent } from './shared/device-setup/device-setup.component';
import { ConnectionLostDialogComponent } from './shared/connection-lost-dialog/connection-lost-dialog.component';
import { NewPasswordComponent } from './auth/new-password/new-password.component';

const routes: Routes = [
  {
    path: AppRoutes.signin,
    component: SignInComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: AppRoutes.signup,
    component: SignUpComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: AppRoutes.confirm,
    component: ConfirmCodeComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: AppRoutes.forgot,
    component: ForgotPasswordComponent,
    canActivate: [],
  },
  {
    path: AppRoutes.new_password,
    component: NewPasswordComponent,
    canActivate: [],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: AppRoutes.dashboard,
  },
  {
    path: '',
    component: SidenavComponent,
    canActivate: [AuthGuard, ActivatedGuard],
    children: [
      {
        path: AppRoutes.dashboard,
        canActivate: [AuthGuard, ActivatedGuard],
        component: DashboardComponent,
      },
      {
        path: AppRoutes.profile,
        canActivate: [AuthGuard, ActivatedGuard],
        component: ProfileComponent,
      },
      {
        path: AppRoutes.patients,
        canActivate: [AuthGuard, ActivatedGuard],
        component: PatientsComponent,
      },
      {
        path: AppRoutes.patient_details,
        canActivate: [AuthGuard, ActivatedGuard],
        component: PatientDetailsComponent,
      },
      {
        path: AppRoutes.appointments,
        canActivate: [AuthGuard, ActivatedGuard],
        component: AppointmentsComponent,
      },
      {
        path: AppRoutes.settings,
        canActivate: [AuthGuard, ActivatedGuard],
        component: SettingsComponent,
      },
      {
        path: AppRoutes.device_check,
        canActivate: [AuthGuard, ActivatedGuard],
        component: DeviceSetupComponent,
      },
    ],
  },
  {
    path: AppRoutes.call,
    canActivate: [AuthGuard, ActivatedGuard],
    canDeactivate: [LeaveMeetingGuard],
    component: CallOnlyComponent,
  },

  {
    path: AppRoutes.stepper,
    canActivate: [AuthGuard, RequestedActivationGuard],
    component: ProfileStepperComponent,
  },
  {
    path: AppRoutes.wait_for_activation,
    canActivate: [AuthGuard],
    component: ProfileWaitForActivationComponent,
  },
  {
    path: AppRoutes.connection_lost,
    canActivate: [AuthGuard],
    component: ConnectionLostDialogComponent,
  },
  { path: '**', redirectTo: 'signin' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
