import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { DoctorStatusModule } from '../shared/doctor-status/doctor-status.module';
import { IncomingMeetingRequestDialogModule } from '../shared/incoming-meeting-request-dialog/incoming-meeting-request-dialog.module';
import { AppointmentRequestDialogModule } from '../shared/appointment-request-dialog/appointment-request-dialog.module';
import { LogoModule } from '../shared/logo/logo.module';
import { MaterialModule } from '../shared/material/material.module';
import { NavbarComponent } from './navbar/navbar.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ConnectionLostDialogModule } from '../shared/connection-lost-dialog/connection-lost-dialog.module';
import { FooterModule } from '../shared/footer/footer.module';

@NgModule({
  declarations: [NavbarComponent, SidenavComponent],
  imports: [
    CommonModule,
    MaterialModule,
    AppRoutingModule,
    DoctorStatusModule,
    IncomingMeetingRequestDialogModule,
    AppointmentRequestDialogModule,
    ConnectionLostDialogModule,
    LogoModule,
    ScrollingModule,
    FooterModule,
  ],
  exports: [NavbarComponent, SidenavComponent],
})
export class NavigationModule {}
