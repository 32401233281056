import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { from, Observable, Subject } from 'rxjs';
import { NotificationService } from '../shared/notification/notification.service';
import { AmplitudeTrackingService } from '../tracking/amplitude.service';
import { ProfileService } from '../profile/profile.service';
import { DoctorStatusService } from '../shared/doctor-status/doctor-status.service';
import { AppointmentsService } from '../appointments/appointments.service';

export interface NewUser {
  email: string;
  password: string;
  'custom:language_code': string;
  'custom:has_agreed_to_terms': boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedIn: boolean;
  private authState: Subject<CognitoUser | any> = new Subject<
    CognitoUser | any
  >();
  authState$: Observable<CognitoUser | any> = this.authState.asObservable();
  private currentUser: CognitoUser;
  constructor(
    private notification: NotificationService,
    private track: AmplitudeTrackingService,
    private profile: ProfileService,
    public doctorStatusService: DoctorStatusService,
    private appointmentsService: AppointmentsService,
  ) {
    Hub.listen('auth', (data) => {
      const { channel, payload } = data;
      if (channel === 'auth') {
        this.authState.next(payload.event);
      }
    });
  }

  signUp(user: NewUser): Promise<CognitoUser | any> {
    return Auth.signUp({
      username: user.email,
      password: user.password,
      attributes: {
        email: user.email,
        'custom:language_code': user['custom:language_code'],
        'custom:has_agreed_to_terms': `${user['custom:has_agreed_to_terms']}`,
      },
    });
  }

  signIn(username: string, password: string): Promise<CognitoUser | any> {
    return new Promise((resolve, reject) => {
      Auth.signIn(username, password)
        .then((user: CognitoUser | any) => {
          this.loggedIn = true;
          this.currentUser = user;
          this.profile.getProfile();
          this.appointmentsService.reloadAppointments();
          this.profile.doctorProfile$.pipe().subscribe((value) => {
            this.track.setSessionProps({
              userId: value.doctorId,
              region: value.region.code,
              language: value.languages.toString(),
              userRole:
                value.medicalSpecialist === 'specialist'
                  ? 'Specialist'
                  : 'Doctor',
              productArea: 'DocUI',
            });
          });
          resolve(user);
        })
        .catch((error: any) => reject(error));
    });
  }

  signOut(): Promise<any> {
    const result = Auth.signOut().then(() => (this.loggedIn = false));
    this.doctorStatusService.stopPolling();
    this.profile.clearProfile();
    this.appointmentsService.clearCache();
    return result;
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return from(
      Auth.currentAuthenticatedUser().then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword)
          .then((data) => data)
          .catch((err) => {
            console.log(err);
            this.notification.show(err.message);
          });
      }),
    );
  }

  forgotPassword(username: string) {
    return Auth.forgotPassword(username)
      .then()
      .catch((err) => {
        this.notification.show(err.message);
        console.log(err);
        return Promise.reject(err);
      });
  }

  forgotPasswordSubmit(username, code, new_password) {
    return Auth.forgotPasswordSubmit(username, code, new_password)
      .then()
      .catch((err) => {
        this.notification.show(err.message);
        console.log(err);
        return Promise.reject(err);
      });
  }

  completeNewPassword(password) {
    return Auth.completeNewPassword(this.currentUser, password)
      .then((value) => {
        this.profile.getProfile();
        return value;
      })
      .catch((err) => {
        this.notification.show(err.message);
        console.log(err);
      });
  }
}
