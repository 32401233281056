import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { PatientMedicalFileQuery } from 'src/app/API.service';
import { PatientsService } from '../patients.service';
import { DoctorStatusService } from '../../shared/doctor-status/doctor-status.service';
import { PastVisitDialogComponent } from './past-visit-dialog/past-visit-dialog.component';

@Component({
  selector: 'tdoc-patient-past-visits',
  templateUrl: './patient-past-visits.component.html',
  styleUrls: ['./patient-past-visits.component.scss'],
})
export class PatientPastVisitsComponent implements OnInit {
  @Input() medicalFile: PatientMedicalFileQuery;

  translations$: Observable<any>;
  translations: any;

  pastVisits: any[] = [];
  private doctorIsInCall$ = this.doctorStatus.isInCall$;

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private patientService: PatientsService,
    private doctorStatus: DoctorStatusService,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.pastVisits = this.medicalFile.visits;
    this.doctorIsInCall$.subscribe((isInCall) => {
      if (isInCall) {
        this.pastVisits.shift();
      }
    });
  }

  openVisit(visit: any): void {
    this.dialog.open(PastVisitDialogComponent, {
      width: '800px',
      data: { visit: visit },
    });
  }

  private initTranslations() {
    this.translations$ = this.translate.get('PATIENTS.PASTVISITS').pipe(
      tap((translations) => {
        this.translations = translations;
      }),
    );
  }
}
