<div class="confirm">
  <tdoc-logo></tdoc-logo>
  <form [formGroup]="confirmForm" class="confirm-form">
    <h2>{{ 'AUTH.CONFIRM_CODE.HEADER' | translate }}</h2>
    <mat-form-field>
      <input
        id="emailAuth"
        matInput
        placeholder="{{ 'AUTH.CONFIRM_CODE.EMAIL' | translate }}"
        type="email"
        formControlName="email"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        id="confirmationCode"
        matInput
        placeholder="{{ 'AUTH.CONFIRM_CODE.CODE' | translate }}"
        type="text"
        formControlName="code"
        required
      />
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="confirmCode()">
      {{ 'AUTH.CONFIRM_CODE.CONFIRM' | translate }}
    </button>
    <p>
      {{ 'AUTH.CONFIRM_CODE.NO_CODE' | translate }}
      <a href="javascript:void(null)" (click)="sendAgain()">
        {{ 'AUTH.CONFIRM_CODE.SEND_AGAIN' | translate }}
      </a>
    </p>
  </form>
  <div class="container">
    <tdoc-auth-language-selection></tdoc-auth-language-selection>
  </div>
</div>
<tdoc-footer></tdoc-footer>
