import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { TdocFormlyModule } from 'src/app/shared/tdoc-formly/tdoc-formly.module';
import { ProfileWaitForActivationComponent } from './profile-wait-for-activation.component';
import { LogoModule } from '../../shared/logo/logo.module';

@NgModule({
  declarations: [ProfileWaitForActivationComponent],
  imports: [
    CommonModule,
    TdocFormlyModule,
    MaterialModule,
    TranslateModule,
    LogoModule,
  ],
  exports: [ProfileWaitForActivationComponent],
})
export class ProfileWaitForActivationModule {}
