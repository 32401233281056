import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { of, Subject, timer } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PatientsService } from 'src/app/patients/patients.service';
import { ChimeService } from '../chime/chime.service';
import { DoctorStatusService } from '../doctor-status/doctor-status.service';
import { AppRoutes } from '../routes';
import { AmplitudeTrackingService } from '../../tracking/amplitude.service';

@Component({
  selector: 'tdoc-incoming-meeting-request-dialog',
  templateUrl: './incoming-meeting-request-dialog.component.html',
  styleUrls: ['./incoming-meeting-request-dialog.component.scss'],
})
export class IncomingMeetingRequestDialogComponent
  implements OnInit, OnDestroy
{
  @ViewChild('ringtone', { static: true })
  ringtone: ElementRef<HTMLAudioElement>;
  destroy$ = new Subject<void>();
  private interval: NodeJS.Timer;

  constructor(
    private chimeService: ChimeService,
    private patientsService: PatientsService,
    private doctorStatus: DoctorStatusService,
    private router: Router,
    public dialogRef: MatDialogRef<IncomingMeetingRequestDialogComponent>,
  ) {}
  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  onConfirm(): void {
    this.router.navigate([AppRoutes.device_check]);
  }

  onDecline(): void {}

  private async playRingtone(): Promise<void> {
    this.ringtone.nativeElement.crossOrigin = 'anonymous';
    console.log('loading sound');
    this.ringtone.nativeElement.load();
    console.log('sound is loaded');
    try {
      await this.ringtone.nativeElement.play();
      console.log('sound is played');
    } catch (e) {
      console.log('could not play sound ', e);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
