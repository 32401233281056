import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { TdocFormlyModule } from 'src/app/shared/tdoc-formly/tdoc-formly.module';
import { NewPasswordDialogComponent } from './new-password-dialog.component';

@NgModule({
  declarations: [NewPasswordDialogComponent],
  imports: [
    CommonModule,
    TdocFormlyModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forChild(),
    TranslateModule,
  ],
  exports: [NewPasswordDialogComponent],
})
export class NewPasswordDialogModule {}
