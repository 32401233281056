<div class="footer">
  <span style="margin-left: 30px">
    {{ 'FOOTER.BY_TELEDOC' | translate }} |
    {{ 'FOOTER.ALL_RIGHTS' | translate }}
  </span>
  <span>
    {{ 'FOOTER.NEED_HELP' | translate }}
    <a href="mailto:service@myteledoc.app">{{
      'FOOTER.CONTACT_SUPPORT' | translate
    }}</a>
  </span>
  <span style="margin-right: 30px">
    <a href="https://myteledoc.app/terms-of-use-doctors">{{
      'FOOTER.TERMS_AND_COND' | translate
    }}</a>
    |
    <a href="https://myteledoc.app/imprint-2/">{{
      'FOOTER.IMPRINT' | translate
    }}</a>
    |
    <a href="https://myteledoc.app/privacy-policy-doctors">{{
      'FOOTER.PRIVACY_POLICY' | translate
    }}</a>
  </span>
</div>
