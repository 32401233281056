<ng-container *ngIf="translations$ | async">
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav class="sidenav" #sidenav opened mode="side">
      <div class="sidenav-icons">
        <span class="icon-container" (click)="open(AppRoutes.dashboard)">
          <mat-icon class="icon">home</mat-icon>
        </span>
        <span class="icon-container" (click)="open(AppRoutes.profile)">
          <mat-icon class="icon">person</mat-icon>
        </span>
        <span class="icon-container" (click)="open(AppRoutes.appointments)">
          <mat-icon
            matBadge="{{ pendingAppointments }}"
            matBadgeColor="accent"
            matBadgeSize="small"
            class="icon"
            >calendar_today</mat-icon
          >
        </span>
        <span class="icon-container" (click)="open(AppRoutes.patients)">
          <mat-icon class="icon">people</mat-icon>
        </span>
        <!--         <span class="icon-container">
          <mat-icon class="icon">medication</mat-icon>
        </span> -->
        <!--         <span class="icon-container">
          <mat-icon class="icon">info</mat-icon>
        </span> -->
        <span class="icon-container" (click)="open(AppRoutes.settings)">
          <mat-icon class="icon">settings</mat-icon>
        </span>
        <span class="icon-container" aria-label="Logout" (click)="onSignOut()">
          <mat-icon class="icon">logout</mat-icon>
        </span>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="main-panel">
      <tdoc-navbar [title]="title"></tdoc-navbar>
      <div class="main-container">
        <router-outlet></router-outlet>
      </div>
      <tdoc-footer></tdoc-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
