<mat-card>
  <mat-card-content>
    <h1>{{ 'PATIENTS.CONTROLS.SETTINGS_DIALOG_HEADER' | translate }}</h1>
    <form [formGroup]="form">
      <formly-form
        [model]="model"
        [fields]="fields"
        [options]="options"
        [form]="form"
      ></formly-form>
    </form>

    <mat-dialog-actions class="button-container">
      <button mat-raised-button [mat-dialog-close]="true">
        {{ 'PATIENTS.CONTROLS.CANCEL' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="onConfirm()"
        [mat-dialog-close]="true"
      >
        {{ 'PATIENTS.CONTROLS.CONFIRM' | translate }}
      </button>
    </mat-dialog-actions>
  </mat-card-content>
</mat-card>
