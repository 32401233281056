import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIService, DoctorProfileQuery } from 'src/app/API.service';
import { AppRoutes } from 'src/app/shared/routes';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ActivatedGuard implements CanActivate {
  constructor(private router: Router, private api: APIService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (environment.enableActivation) {
      return from(this.api.DoctorProfile()).pipe(
        map((profile: DoctorProfileQuery) => {
          if (profile.isActive === true) {
            return true;
          } else if (profile.activationRequested === true) {
            this.router.navigate([AppRoutes.wait_for_activation]);
          } else {
            this.router.navigate([AppRoutes.stepper]);
          }
        }),
      );
    } else {
      return true;
    }
  }
}
