import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ConnectionLostDialogComponent } from './connection-lost-dialog.component';

@NgModule({
  declarations: [ConnectionLostDialogComponent],
  imports: [CommonModule, MaterialModule, TranslateModule],
  exports: [ConnectionLostDialogComponent],
})
export class ConnectionLostDialogModule {}
