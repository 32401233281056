import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, from, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { APIService } from 'src/app/API.service';
import { Region } from './region.model';

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  constructor(private api: APIService, private translate: TranslateService) {}

  public getRegions(): Observable<Region[]> {
    const regionLabels$ = this.translate.get('REGIONS').pipe(take(1));
    const regionCodes$ = from(this.api.TeledocRegions());
    return combineLatest([regionCodes$, regionLabels$]).pipe(
      take(1),
      map(([regionCodes, regionLabels]) => {
        return regionCodes.map(({ code }) => ({
          id: `regionSelectionDropdown${code}`,
          value: code,
          label: regionLabels[code],
        }));
      }),
    );
  }
}
