<ng-container *ngIf="medicalFile$ | async as medicalFile">
  <div class="patient-details-layout">
    <tdoc-patient-personal
      class="personal-card"
      [medicalFile]="medicalFile"
    ></tdoc-patient-personal>
    <tdoc-patient-medical
      class="medical-card"
      disabled="true"
      [medicalFile]="medicalFile"
    ></tdoc-patient-medical>
    <tdoc-patient-past-visits
      class="past-visits-card"
      [medicalFile]="medicalFile"
    ></tdoc-patient-past-visits>
    <tdoc-patient-files
      class="files-card"
      [medicalFile]="medicalFile"
    ></tdoc-patient-files>
  </div>
</ng-container>
