import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MeetingStatus } from '../../shared/types/meeting-status';
import { PatientMedicalFileQuery } from '../../API.service';
import { ChimeService } from '../../shared/chime/chime.service';
import { PatientsService } from '../patients.service';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppRoutes } from '../../shared/routes';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tdoc-call-only',
  templateUrl: './call-only.component.html',
  styleUrls: ['./call-only.component.scss'],
})
export class CallOnlyComponent implements AfterViewInit {
  @ViewChild('audioElementId') audioElement: ElementRef<HTMLAudioElement>;

  translations$: Observable<any>;
  translations: any;
  meetingStatus$: Observable<MeetingStatus>;
  medicalFile$: Observable<PatientMedicalFileQuery>;
  isInCall: boolean;
  isLoading: boolean;

  constructor(
    private router: Router,
    private chimeService: ChimeService,
    private patientService: PatientsService,
    private translate: TranslateService,
  ) {}

  ngAfterViewInit(): void {
    this.initTranslations();
    this.patientService.isLoading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.medicalFile$ = this.patientService.patientMedicalFile$;
    this.meetingStatus$ = this.chimeService.meetingStatus$.pipe(
      tap(async (status: MeetingStatus) => {
        if (status === MeetingStatus.Succeeded) {
          await this.chimeService.joinRoom(this.audioElement.nativeElement);
        }
      }),
    );
    this.chimeService.meetingStatus$
      .pipe(
        map((status) => {
          if (status === MeetingStatus.Succeeded) {
            return false;
          } else {
            return true;
          }
        }),
      )
      .subscribe((next) => {
        this.isInCall = !next;
      });
  }

  private initTranslations(): void {
    this.translations$ = this.translate.get('PATIENTS.NO_CALL').pipe(
      tap((translations) => {
        this.translations = translations;
      }),
    );
  }

  navigateToDashboard(): void {
    this.router.navigate([AppRoutes.dashboard]);
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.chimeService.meetingStatus$.pipe(
      map((status) => {
        if (status === MeetingStatus.Succeeded) {
          return false;
        } else {
          return true;
        }
      }),
    );
  }
}
