import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { DevicesService } from '../chime/devices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FullDeviceInfo } from '../types/full-device-info';
import { AppRoutes } from '../routes';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ChimeService } from '../chime/chime.service';
import { PatientsService } from '../../patients/patients.service';
import { DoctorStatusService } from '../doctor-status/doctor-status.service';
import { AmplitudeTrackingService } from '../../tracking/amplitude.service';

@Component({
  selector: 'tdoc-device-setup',
  templateUrl: './device-setup.component.html',
  styleUrls: ['./device-setup.component.scss'],
})
export class DeviceSetupComponent implements OnInit {
  form = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];

  @ViewChild('setupVideoElementId')
  localVideoElement: ElementRef<HTMLVideoElement>;

  appointmentId: string = null;

  isBlurred: boolean = false;

  blurPercentage: number;

  accessIdEmpty: boolean = false;

  constructor(
    private deviceService: DevicesService,
    private router: Router,
    private chimeService: ChimeService,
    private patientsService: PatientsService,
    private doctorStatus: DoctorStatusService,
    private tracking: AmplitudeTrackingService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.updateVideo();
    this.activatedRoute.params.subscribe((params) => {
      this.appointmentId = params['appointmentId'];
    });
    if (!this.appointmentId) {
      this.activatedRoute.queryParams.subscribe((params) => {
        this.appointmentId = params['appointmentId'];
      });
    }
  }

  onConfirm() {
    this.patientsService.isLoading.next(true);
    if (this.appointmentId) {
      this.deviceService.setBlur(this.isBlurred, this.blurPercentage);
      this.chimeService
        .startAppointedMeeting(this.appointmentId)
        .pipe(
          take(1),
          switchMap((accepted) => {
            if (accepted.accessId === null) {
              this.accessIdEmpty = true;
              console.log('appointment failed');
              return of(null);
            } else {
              this.doctorStatus.pickUpCall();
              this.router.navigate([AppRoutes.call]);
              return this.patientsService.getMedicalFile(accepted.accessId);
            }
          }),
          catchError((err) => {
            this.accessIdEmpty = true;
            console.log('appointment failed in catch', err);
            return of('');
          }),
        )
        .subscribe();
    } else {
      this.doctorStatus.registerAcceptedCall();
      this.chimeService
        .acceptMeetingRequest()
        .pipe(
          tap((accepted) => {
            if (accepted !== null) {
              this.patientsService.pickUpCall();
            }
          }),
          switchMap((accepted) => {
            if (accepted.accessId === null) {
              this.doctorStatus.setAvailable();
              this.doctorStatus.hangUpCall();
              this.router.navigate([AppRoutes.dashboard]);
              return of(null);
            } else {
              let medicalFile = this.patientsService.getMedicalFile(
                accepted.accessId,
              );
              medicalFile.pipe(take(1)).subscribe((value) => {
                this.tracking.trackEvent('In call minute', {
                  patientId: value.patientId,
                });
              });
              this.router.navigate([AppRoutes.call]);
              return medicalFile;
            }
          }),
        )
        .subscribe();
    }
  }
  onCancel() {
    this.router.navigate([AppRoutes.dashboard]);
  }
  updateVideo() {
    this.deviceService.deviceList$
      .pipe(take(1))
      .subscribe(async (deviceInfo: FullDeviceInfo) => {
        this.model = {
          currentAudioInputDevice: deviceInfo.currentAudioInputDevice,
          currentAudioOutputDevice: deviceInfo.currentAudioOutputDevice,
          currentVideoInputDevice: deviceInfo.currentVideoInputDevice,
        };
        this.fields = [
          {
            key: `currentVideoInputDevice.value`,
            type: 'select',
            templateOptions: {
              label: 'Camera',
              options: deviceInfo.videoInputDevices,
            },
          },
          {
            key: `currentAudioInputDevice.value`,
            type: 'select',
            templateOptions: {
              label: 'Microphone',
              options: deviceInfo.audioInputDevices,
            },
          },
          {
            key: `currentAudioOutputDevice.value`,
            type: 'select',
            templateOptions: {
              label: 'Speaker',
              options: deviceInfo.audioOutputDevices,
            },
          },
        ];
        if (deviceInfo.currentVideoInputDevice) {
          this.deviceService
            .chooseVideoInputDevice(deviceInfo.currentVideoInputDevice)
            .then(() => {
              this.deviceService.deviceController
                .acquireVideoInputStream()
                .then((value) => {
                  console.log('the video stream ', JSON.stringify(value));
                  this.localVideoElement.nativeElement.srcObject = value;
                  this.localVideoElement.nativeElement.muted = true;
                  this.localVideoElement.nativeElement
                    .play()
                    .then(() => {
                      console.log('start playing');
                    })
                    .catch((err) => {
                      console.log('cannot play ', err);
                    });
                });
            });
        }
      });
  }
  changeBlur() {
    this.deviceService.setBlur(this.isBlurred, this.blurPercentage);
    this.updateVideo();
  }

  changeBlurIntensity(event: Event) {
    console.log(
      'updating blur intensity ',
      (event.target as HTMLInputElement).value,
    );
    this.blurPercentage = Number((event.target as HTMLInputElement).value);
    this.deviceService.setBlur(this.isBlurred, this.blurPercentage);
    this.updateVideo();
  }
}
