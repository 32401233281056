import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadTableModule } from 'src/app/shared/file-upload-table/file-upload-table.module';
import { LogoModule } from 'src/app/shared/logo/logo.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { ProfileSettingsFormModule } from 'src/app/shared/profile-settings-form/profile-settings-form.module';
import { TdocFormlyModule } from 'src/app/shared/tdoc-formly/tdoc-formly.module';
import { ProfileAddressModule } from '../profile-address/profile-address.module';
import { ProfileBioModule } from '../profile-bio/profile-bio.module';
import { ProfilePersonalModule } from '../profile-personal/profile-personal.module';
import { ProfileStepperComponent } from './profile-stepper.component';

@NgModule({
  declarations: [ProfileStepperComponent],
  imports: [
    CommonModule,
    TdocFormlyModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forChild(),
    TranslateModule,
    ProfilePersonalModule,
    ProfileBioModule,
    ProfileAddressModule,
    FileUploadTableModule,
    ProfileSettingsFormModule,
    LogoModule,
  ],
  exports: [ProfileStepperComponent],
})
export class ProfileStepperModule {}
