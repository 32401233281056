<ng-container *ngIf="translations$ | async">
  <div mat-dialog-content>
    <mat-card appearance="outlined">
      <mat-card-content class="card">
        <mat-card-title>{{
          'PATIENTS.PRESCRIPTION.HEADER' | translate
        }}</mat-card-title>

        <form [formGroup]="formGroup">
          <formly-form
            [model]="model"
            [fields]="fields"
            [options]="options"
            [form]="formGroup"
          ></formly-form>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-dialog-actions class="button-container">
    <button mat-raised-button [mat-dialog-close]="true">
      {{ 'PATIENTS.PRESCRIPTION.CANCEL' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="formGroup.invalid"
      (click)="onConfirm()"
    >
      {{ 'PATIENTS.PRESCRIPTION.SEND' | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
