<mat-card appearance="outlined">
  <mat-card-content class="card">
    <div class="button-container">
      <button mat-raised-button color="primary" (click)="openPrescription()">
        {{ 'PATIENTS.CONTROLS.PRESCRIPTION' | translate }}
      </button>
      <button mat-raised-button color="primary" (click)="endVisit()">
        {{ 'PATIENTS.CONTROLS.END' | translate }}
      </button>
    </div>
  </mat-card-content>
</mat-card>
