import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { AppointmentPanelModule } from '../appointment-panel/appointment-panel.module';
import { WeeklyAppointmentsComponent } from './weekly-appointments.component';

@NgModule({
  declarations: [WeeklyAppointmentsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    AppointmentPanelModule,
  ],
  exports: [WeeklyAppointmentsComponent],
})
export class WeeklyAppointmentsModule {}
