import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ProfileService } from '../profile/profile.service';

@Component({
  selector: 'tdoc-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  profile$;

  constructor(public profileService: ProfileService) {}

  ngOnInit(): void {
    this.profile$ = this.profileService.doctorProfile$;
  }
}
