import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { ProfileService } from 'src/app/profile/profile.service';
import { ChimeService } from '../chime/chime.service';
import { DoctorStatusService } from '../doctor-status/doctor-status.service';
import { RegionCode } from '../region/region-code.model';
import { IncomingMeetingRequestDialogComponent } from './incoming-meeting-request-dialog.component';
import { DevicesService } from '../chime/devices.service';
import {
  ActionDialogComponent,
  DialogActions,
} from '../action-dialog/action-dialog.component';
import { SettingsDialogComponent } from '../../patients/patient-call/call-controls/settings-dialog/settings-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class IncomingMeetingRequestService implements OnDestroy {
  activeRegion;

  destroy$ = new Subject<void>();

  private isDialogOpen = new BehaviorSubject<boolean>(false);
  isDialogOpen$ = this.isDialogOpen.asObservable();

  private doctorStatus$ = this.doctorStatus.isDoctorAvailable$;
  private requestIncoming$ = this.chimeService.requestIncoming$;
  private deviceList$ = this.deviceService.deviceList$;

  meetingRequestDialog$ = combineLatest([
    this.doctorStatus$,
    this.requestIncoming$,
    this.isDialogOpen$,
    this.deviceList$,
  ]).pipe(
    takeUntil(this.destroy$),
    filter(([doctorStatus, requestIncoming, isDialogOpen]) => {
      return (
        requestIncoming === true &&
        doctorStatus === true &&
        isDialogOpen === false
      );
    }),
    tap(() => {
      this.isDialogOpen.next(true);
      this.deviceService.deviceList$.pipe(take(1)).subscribe(async (next) => {
        if (next.currentAudioInputDevice && next.currentVideoInputDevice) {
          const dialog = this.dialog.open(
            IncomingMeetingRequestDialogComponent,
            {
              width: '500px',
            },
          );

          dialog
            .afterOpened()
            .pipe(
              take(1),
              // tap(() => this.doctorStatus.registerDialogOpened())
            )
            .subscribe();

          dialog
            .afterClosed()
            .pipe(
              take(1),
              tap(() => {
                this.doctorStatus.setUnavailable();
                this.waitForUnavailableResponse();
              }),
            )
            .subscribe(() => {});
        } else {
          this.doctorStatus.setUnavailable();
          const dialog = this.dialog.open(ActionDialogComponent, {
            data: {
              title: 'DEVICE_CHECK.TITLE',
              message: 'DEVICE_CHECK.MESSAGE',
              cancelTitle: 'DEVICE_CHECK.CANCEL',
              okTitle: 'DEVICE_CHECK.OK',
            },
            width: '500px',
          });
          dialog.afterClosed().subscribe((result) => {
            if (result === DialogActions.confirm) {
              this.dialog.open(SettingsDialogComponent, {
                width: '500px',
              });
            }
          });
        }
      });
    }),
  );

  constructor(
    private doctorStatus: DoctorStatusService,
    private profileService: ProfileService,
    private chimeService: ChimeService,
    private dialog: MatDialog,
    private deviceService: DevicesService,
  ) {
    this.subscribeToNewRegionMeetings();
  }

  setIsDialogOpen(status: boolean): void {
    this.isDialogOpen.next(status);
  }

  private subscribeToNewRegionMeetings(): void {
    this.profileService.doctorProfile$
      .pipe(
        takeUntil(this.destroy$),
        tap((profile) => {
          if (profile.region.code !== this.activeRegion && this.activeRegion) {
            this.chimeService.unsubscribeFromMeetingRequestListener();
          }
        }),
        tap((profile) => {
          if (
            profile.region.code !== this.activeRegion &&
            !profile.medicalSpecialist
          ) {
            this.activeRegion = profile.region.code as RegionCode;
            this.chimeService.subscribeToMeetingRequestListener(
              this.activeRegion,
            );
          }
        }),
      )
      .subscribe();
  }

  private waitForUnavailableResponse(): void {
    setTimeout(() => {
      this.setIsDialogOpen(false);
    }, 2000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
