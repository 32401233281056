<ng-container *ngIf="translations$ | async as translations">
  <tdoc-logo class="logo"></tdoc-logo>
  <span class="card">
    <div class="content">
      <p>{{ 'PROFILE.ACTIVATION_SUCCESS' | translate }}</p>
      <button mat-raised-button color="primary" (click)="onSignOut()">
        {{ 'PROFILE.LOGOUT' | translate }}
      </button>
    </div>
  </span>
</ng-container>
