import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DialogActions } from '../action-dialog/action-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'tdoc-connection-lost-dialog',
  templateUrl: './connection-lost-dialog.component.html',
  styleUrls: ['./connection-lost-dialog.component.scss'],
})
export class ConnectionLostDialogComponent implements AfterViewInit {
  @ViewChild('ringtone')
  ringtone: ElementRef<HTMLAudioElement>;

  constructor(public dialogRef: MatDialogRef<ConnectionLostDialogComponent>) {}

  ngAfterViewInit(): void {
    this.playRingtone();
  }

  private playRingtone(): void {
    this.ringtone.nativeElement
      .play()
      .then()
      .catch((e) => console.log(e));
  }

  onDecline(): void {}

  onConfirm() {
    this.dialogRef.close(DialogActions.confirm);
  }
}
