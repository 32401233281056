import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DoctorProfileQuery } from 'src/app/API.service';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'tdoc-profile-personal',
  templateUrl: './profile-personal.component.html',
  styleUrls: ['./profile-personal.component.scss'],
})
export class ProfilePersonalComponent implements OnInit {
  @Input() profile: DoctorProfileQuery;

  translations$: Observable<any>;
  translations: any;
  formGroup = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];

  constructor(
    private profileService: ProfileService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.model = this.profile;
  }

  onChange() {
    this.profileService.updateProfile(this.model).pipe(take(1)).subscribe();
  }

  private initTranslations() {
    this.translations$ = this.translate.get('PROFILE.PERSONAL').pipe(
      tap((translations) => {
        this.translations = translations;
        this.setupFormFields();
      }),
    );
  }

  private setupFormFields() {
    this.fields = [
      {
        fieldGroupClassName: 'form-group display-flex',
        fieldGroup: [
          {
            className: 'flex-3',
            id: 'firstNameInput',
            key: 'firstName',
            type: 'input',
            templateOptions: {
              label: this.translations['FIRSTNAME'],
            },
            modelOptions: {
              debounce: { default: 1000 },
            },
          },
          {
            className: 'flex-3',
            id: 'lastNameInput',
            key: 'lastName',
            type: 'input',
            templateOptions: {
              label: this.translations['LASTNAME'],
            },
            modelOptions: {
              debounce: { default: 1000 },
            },
          },
        ],
      },
      {
        key: 'doctorIdentifierNumber',
        id: 'doctorNumberID',
        type: 'input',
        templateOptions: {
          label: this.translations['ID'],
          maxLength: 10,
        },
        modelOptions: {
          debounce: { default: 1000 },
        },
      },
      {
        key: 'phoneNumber',
        id: 'phoneNumber',
        type: 'input',
        templateOptions: {
          label: this.translations['PHONE'],
        },
        modelOptions: {
          debounce: { default: 1000 },
        },
      },
      {
        key: 'expertise',
        id: 'expertise',
        type: 'input',
        templateOptions: {
          label: this.translations['EXPERTISE'],
        },
        modelOptions: {
          debounce: { default: 1000 },
        },
      },
      {
        id: 'stampCode',
        key: 'doctorStampCode',
        type: 'input',
        templateOptions: {
          label: this.translations['STAMPCODE'],
        },
        modelOptions: {
          debounce: { default: 1000 },
        },
      },
      {
        key: 'isActive',
        type: 'checkbox',
        templateOptions: {
          label: this.translations['ACTIVE'],
          disabled: true,
        },
      },
    ];
  }
}
