<ng-container *ngIf="translations$ | async">
  <mat-card appearance="outlined">
    <mat-card-content class="card">
      <mat-card-title>{{
        'PROFILE.PERSONAL.HEADER' | translate
      }}</mat-card-title>
      <form [formGroup]="formGroup">
        <formly-form
          [model]="model"
          [fields]="fields"
          [options]="options"
          [form]="formGroup"
          (modelChange)="onChange()"
        ></formly-form>
      </form>
    </mat-card-content>
  </mat-card>
</ng-container>
