import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { DevicesService } from 'src/app/shared/chime/devices.service';
import { FullDeviceInfo } from 'src/app/shared/types/full-device-info';

@Component({
  selector: 'tdoc-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.scss'],
})
export class SettingsDialogComponent implements OnInit {
  form = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];

  constructor(private deviceService: DevicesService) {}

  ngOnInit(): void {
    this.deviceService.deviceList$.subscribe((deviceInfo: FullDeviceInfo) => {
      this.model = {
        currentAudioInputDevice: deviceInfo.currentAudioInputDevice,
        currentAudioOutputDevice: deviceInfo.currentAudioOutputDevice,
        currentVideoInputDevice: deviceInfo.currentVideoInputDevice,
      };
      this.fields = [
        {
          key: `currentVideoInputDevice.value`,
          type: 'select',
          templateOptions: {
            label: 'Camera',
            options: deviceInfo.videoInputDevices,
          },
        },
        {
          key: `currentAudioInputDevice.value`,
          type: 'select',
          templateOptions: {
            label: 'Microphone',
            options: deviceInfo.audioInputDevices,
          },
        },
        {
          key: `currentAudioOutputDevice.value`,
          type: 'select',
          templateOptions: {
            label: 'Speaker',
            options: deviceInfo.audioOutputDevices,
          },
        },
      ];
    });
  }

  onConfirm() {
    this.deviceService.chooseVideoInputDevice(
      this.model.currentVideoInputDevice,
    );
    this.deviceService.chooseAudioInputDevice(
      this.model.currentAudioInputDevice,
    );
    this.deviceService.chooseAudioOutputDevice(
      this.model.currentAudioOutputDevice,
    );
  }
}
