<h1>{{ 'PATIENTS.CONTROLS.SETTINGS_DIALOG_HEADER' | translate }}</h1>
<div class="settings-container">
  <div class="local-video">
    <video #setupVideoElementId class="video local"></video>
  </div>
  <div class="form-container">
    <form [formGroup]="form">
      <formly-form
        [model]="model"
        [fields]="fields"
        [options]="options"
        [form]="form"
      ></formly-form>
    </form>
    <section>
      <mat-label>{{ 'DEVICE_SETUP.BLUR_TOGGLE.LABEL' | translate }}</mat-label>
      <mat-slide-toggle
        class="toggle-itself"
        ngModel
        label="Blur"
        name="isBlurred"
        [(ngModel)]="isBlurred"
        (change)="changeBlur()"
      />
      <mat-label>{{
        'DEVICE_SETUP.BLUR_INTENSITY.LABEL' | translate
      }}</mat-label>
      <mat-slider min="0" max="100" step="10" value="30" name="blurPercentage">
        <input matSliderThumb (input)="changeBlurIntensity($event)" />
      </mat-slider>
    </section>
  </div>
</div>
<mat-dialog-actions class="button-container">
  <button mat-raised-button [mat-dialog-close]="true" (click)="onCancel()">
    {{ 'PATIENTS.CONTROLS.CANCEL' | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="onConfirm()"
    [mat-dialog-close]="true"
  >
    {{ 'PATIENTS.CONTROLS.CONFIRM' | translate }}
  </button>
</mat-dialog-actions>
<mat-error *ngIf="accessIdEmpty" class="button-container">
  {{ 'DEVICE_SETUP.ACCESS_ID.EMPTY.ERROR' | translate }}
</mat-error>
