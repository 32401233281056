import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum DialogActions {
  confirm,
}

@Component({
  selector: 'tdoc-action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss'],
})
export class ActionDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message?: string;
      cancelTitle?: string;
      okTitle?: string;
    },
    public dialogRef: MatDialogRef<ActionDialogComponent>,
  ) {}

  onConfirm() {
    this.dialogRef.close(DialogActions.confirm);
  }
}
