import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Appointment } from 'src/app/API.service';
import {
  AppointmentPanelConfig,
  AppointmentType,
} from '../appointment-panel/appointment-panel-config';
import { AppointmentsService } from '../appointments.service';

@Component({
  selector: 'tdoc-todays-schedule',
  templateUrl: './todays-schedule.component.html',
  styleUrls: ['./todays-schedule.component.scss'],
})
export class TodaysScheduleComponent {
  panelConfig: AppointmentPanelConfig = {
    type: AppointmentType.call,
    titleTranslateKey: 'APPOINTMENTS.TODAYS_SCHEDULE.HEADER',
  };
  todaysAppointments$: Observable<Appointment[]>;

  constructor(private appointmentsService: AppointmentsService) {
    this.todaysAppointments$ = this.appointmentsService.todaysAppointments$;
  }
}
