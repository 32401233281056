import { Injectable } from '@angular/core';
import {
  DefaultMeetingSession,
  MeetingSessionConfiguration,
  MeetingSessionStatusCode,
} from 'amazon-chime-sdk-js';
import {
  BehaviorSubject,
  from,
  interval,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import { delay, take, tap } from 'rxjs/operators';
import {
  AcceptMeetingRequestMutation,
  APIService,
  JoinAppointmentMeetingMutation,
  OnIncomingMeetingRequestSubscription,
  SubscriptionResponse,
} from 'src/app/API.service';
import { RegionCode } from '../region/region-code.model';
import { FullDeviceInfo } from '../types/full-device-info';
import { MeetingStatus } from '../types/meeting-status';
import { DevicesService } from './devices.service';
import { AmplitudeTrackingService } from '../../tracking/amplitude.service';

const HALF_MINUTE = 30 * 1000;

@Injectable({
  providedIn: 'root',
})
export class ChimeService {
  public readonly meetingRequestSubscriptionErrors: {
    error: string;
    createdAt: string;
  }[] = [];
  public readonly receivedCalls: { regionCode: string; createdAt: string }[] =
    [];
  public hasMeetingRequestSubscriptionCompleted = false;
  private incomingMeetingRequest$ = new Subscription();

  private meetingStatus = new BehaviorSubject<MeetingStatus>(
    MeetingStatus.Loading,
  );
  meetingStatus$ = this.meetingStatus.asObservable();

  private requestIncoming = new BehaviorSubject<boolean>(false);
  requestIncoming$ = this.requestIncoming.asObservable();

  configuration: MeetingSessionConfiguration;
  meetingSession: DefaultMeetingSession;

  private accessId: string;
  private meetingQualityReported: boolean;
  private timeHasCome: boolean;

  constructor(
    private deviceService: DevicesService,
    private api: APIService,
    private track: AmplitudeTrackingService,
  ) {}

  setMeetingStatus(newStatus: MeetingStatus): void {
    this.meetingStatus.next(newStatus);
  }

  setRequestIncoming(newStatus: boolean): void {
    this.requestIncoming.next(newStatus);
  }

  subscribeToMeetingRequestListener(regionCode: RegionCode): void {
    this.incomingMeetingRequest$ = new Observable<
      SubscriptionResponse<OnIncomingMeetingRequestSubscription>
    >((observer: any) =>
      this.api.OnIncomingMeetingRequestListener(regionCode).subscribe(observer),
    ).subscribe(
      (result) => {
        const res = result?.value?.data?.regionCode;
        console.log('[INFO] Websocket subscription result', res);
        this.receivedCalls.push({
          regionCode: res,
          createdAt: Date.now().toString(),
        });
        this.setRequestIncoming(true);
      },
      (error) => {
        of(error)
          .pipe(
            take(1),
            tap((err) => {
              this.meetingRequestSubscriptionErrors.push({
                error: err.message || err,
                createdAt: Date.now().toString(),
              });
              console.log('ERROR', this.meetingRequestSubscriptionErrors);
              console.error('[ERROR] Websocket subscription error', err);
            }),
            delay(10000),
            tap(() => {
              console.log('[ERROR] Websocket subscription error', error);
              console.log('[INFO] Trying to reinitialize websocket');
              this.subscribeToMeetingRequestListener(regionCode);
            }),
          )
          .subscribe();
      },
      () => {
        console.log('[INFO] Websocket connection completed.');
        this.hasMeetingRequestSubscriptionCompleted = true;
      },
    );
  }

  unsubscribeFromMeetingRequestListener(): void {
    this.incomingMeetingRequest$.unsubscribe();
  }

  acceptMeetingRequest(): Observable<AcceptMeetingRequestMutation> {
    return from(this.api.AcceptMeetingRequest()).pipe(
      tap((accepted: AcceptMeetingRequestMutation) => {
        this.setMeetingConfig(accepted);
      }),
    );
  }

  startAppointedMeeting(appointmentId: string) {
    return from(this.api.JoinAppointmentMeeting(appointmentId)).pipe(
      tap((joinedMeeting: JoinAppointmentMeetingMutation) => {
        this.setMeetingConfig(joinedMeeting);
      }),
    );
  }

  private setMeetingConfig(
    joinedMeeting:
      | JoinAppointmentMeetingMutation
      | AcceptMeetingRequestMutation,
  ) {
    if (joinedMeeting.accessId !== null) {
      this.accessId = joinedMeeting.accessId;
      const doctor = joinedMeeting.meetingData.Attendees[0];
      const meeting = joinedMeeting.meetingData.Meeting;

      this.configuration = new MeetingSessionConfiguration(
        {
          ...meeting,
        },
        {
          ...doctor,
        },
      );
      this.meetingSession = new DefaultMeetingSession(
        this.configuration,
        this.deviceService.logger,
        this.deviceService.deviceController,
      );
      this.setMeetingStatus(MeetingStatus.Succeeded);
      this.setRequestIncoming(false);
    } else {
      this.setMeetingStatus(MeetingStatus.Failed);
      this.setRequestIncoming(false);
    }
  }

  stop(): void {
    this.meetingSession.audioVideo.stopVideoInput();
    this.meetingSession.audioVideo.removeLocalVideoTile();
    this.meetingSession.audioVideo.unbindAudioElement();
    this.meetingSession.audioVideo.stop();
  }

  async joinRoom(element: HTMLAudioElement | null): Promise<void> {
    if (!element) {
      console.log('Missing Audio Element');
      return;
    }
    const observer = {
      audioVideoDidStart: () => {
        console.log('Started');
        this.deviceService.deviceList$
          .pipe(take(1))
          .subscribe((devices: FullDeviceInfo) => {
            this.deviceService
              .chooseVideoInputDevice(devices.currentVideoInputDevice)
              .then(() => {
                this.meetingSession.audioVideo.startLocalVideoTile();
              });
            this.deviceService
              .chooseAudioInputDevice(devices.currentAudioInputDevice)
              .then();
            this.deviceService
              .chooseAudioOutputDevice(devices.currentAudioOutputDevice)
              .then();
          });
      },
      audioVideoDidStop: (sessionStatus) => {
        const sessionStatusCode = sessionStatus.statusCode();
        this.meetingQualityReported = false;
        if (sessionStatusCode === MeetingSessionStatusCode.MeetingEnded) {
          //this.meetingStatus.next(MeetingStatus.Ended);
          console.log('Meeting has ended');
        }
        if (sessionStatusCode === MeetingSessionStatusCode.Left) {
          //this.meetingStatus.next(MeetingStatus.Ended);
          console.log('You left the session');
        } else {
          console.log(
            'Stopped with a session status code: ',
            sessionStatusCode,
          );
        }
      },
      audioVideoDidStartConnecting: (reconnecting) => {
        if (reconnecting) {
          console.log('Attempting to reconnect');
        }
      },
      metricsDidReceive: (clientMetricReport) => {
        const metricReport = clientMetricReport.getObservableMetrics();

        if (!this.meetingQualityReported && this.timeHasCome) {
          this.track.trackEvent('Video Quality', { ...metricReport });
          this.meetingQualityReported = true;
          this.timeHasCome = false;
        }
      },
    };

    await this.deviceService.listAndSelectDevices();
    this.meetingSession.audioVideo?.bindAudioElement(element);
    this.meetingSession.audioVideo.addObserver(observer);
    this.meetingSession.audioVideo?.start();
    interval(HALF_MINUTE)
      .pipe(take(1))
      .subscribe(() => {
        // minute has passed since start of the meeting
        this.timeHasCome = true;
      });
  }
}
