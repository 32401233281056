import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppointmentPanelModule } from 'src/app/appointments/appointment-panel/appointment-panel.module';
import { AppointmentRequestsComponent } from './appointment-requests.component';

@NgModule({
  declarations: [AppointmentRequestsComponent],
  imports: [CommonModule, AppointmentPanelModule],
  exports: [AppointmentRequestsComponent],
})
export class AppointmentRequestsModule {}
