import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { AppRoutes } from '../../shared/routes';
import { Router } from '@angular/router';

@Component({
  selector: 'tdoc-profile-wait-for-activation',
  templateUrl: './profile-wait-for-activation.component.html',
  styleUrls: ['./profile-wait-for-activation.component.scss'],
})
export class ProfileWaitForActivationComponent implements OnInit {
  translations$: Observable<any>;
  translations: any;

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
  }

  private initTranslations(): void {
    this.translations$ = this.translate.get('PROFILE').pipe(
      tap((translations) => {
        this.translations = translations;
      }),
    );
  }

  onSignOut() {
    this.authService
      .signOut()
      .then(() => this.router.navigate([AppRoutes.signin]));
  }
}
