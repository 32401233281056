<ng-container *ngIf="translations$ | async">
  <div class="forgot">
    <tdoc-logo></tdoc-logo>
    <form [formGroup]="newPasswordForm" class="forgot-form">
      <h2>{{ 'AUTH.CONFIRM_PASSWORD.HEADER' | translate }}</h2>
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'AUTH.CONFIRM_PASSWORD.PASSWORD' | translate }}"
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
          required
        />
        <mat-icon matSuffix (click)="hide = !hide">{{
          hide ? 'visibility_off' : 'visibility'
        }}</mat-icon>
        <mat-hint *ngIf="!passwordInput.value">{{
          'AUTH.CONFIRM_PASSWORD.PASSWORD_HINT' | translate
        }}</mat-hint>
        <mat-error *ngIf="!passwordInput.valid">
          {{ getPasswordInputError() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="{{
            'AUTH.CONFIRM_PASSWORD.CONFIRM_PASSWORD' | translate
          }}"
          [type]="hide ? 'password' : 'text'"
          formControlName="confirmedPassword"
          required
        />
        <mat-icon matSuffix (click)="hide = !hide">{{
          hide ? 'visibility_off' : 'visibility'
        }}</mat-icon>
        <mat-hint *ngIf="!passwordInput.value">{{
          'AUTH.CONFIRM_PASSWORD.CONFIRM_PASSWORD_HINT' | translate
        }}</mat-hint>
        <mat-error *ngIf="!passwordInput.valid">
          {{ getPasswordInputError() }}
        </mat-error>
        <mat-error *ngIf="!passwordInput.hasError('notSame')">
          {{ 'AUTH.CONFIRM_PASSWORD.NOT_SAME_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!newPasswordForm.valid"
        (click)="submitNewPassword()"
      >
        {{ 'AUTH.CONFIRM_PASSWORD.CONFIRM' | translate }}
      </button>
    </form>
  </div>
</ng-container>
<tdoc-footer></tdoc-footer>
