import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { TdocFormlyModule } from 'src/app/shared/tdoc-formly/tdoc-formly.module';
import { ClinicComponent } from './clinic.component';

@NgModule({
  declarations: [ClinicComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    TdocFormlyModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forChild(),
  ],
  exports: [ClinicComponent],
})
export class ClinicModule {}
