<ng-container class="display-flex" *ngIf="translations$ | async">
  <mat-card appearance="outlined">
    <mat-card-content class="patients-filter">
      <mat-card-title>{{
        'PATIENTS.PATIENTS-LIST.FILTER.HEADER' | translate
      }}</mat-card-title>
      <form [formGroup]="formGroup">
        <formly-form
          [model]="model"
          [fields]="fields"
          [options]="options"
          [form]="formGroup"
        ></formly-form>
        <button mat-raised-button color="primary" (click)="onSubmit()">
          Apply
        </button>
      </form>
    </mat-card-content>
  </mat-card>
  <ng-container
    *ngIf="
      (patientsList && patientsList.length > 0) || loading;
      else noPatients
    "
  >
    <div
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [infiniteScrollContainer]="'.main-panel'"
      [fromRoot]="true"
      (scrolled)="onScroll()"
    >
      <mat-list class="patients-list">
        <mat-list-item
          class="patient-card-container"
          *ngFor="let patient of patientsList"
        >
          <mat-card appearance="outlined">
            <mat-card-content class="patient-card">
              <span class="patient-property"
                >{{ patient.personal.firstName }}
                {{ patient.personal.lastName }}</span
              >
              <span class="patient-property">{{
                patient.personal.dateOfBirth
              }}</span>
              <span class="patient-property">{{ patient.regionCode }}</span>
              <span class="patient-property">{{ patient.partnerName }}</span>
              <span class="patient-detail-button"
                ><button
                  mat-raised-button
                  color="primary"
                  (click)="onPatientDetails(patient.accessId)"
                >
                  Details
                </button></span
              >
            </mat-card-content>
          </mat-card>
        </mat-list-item>
      </mat-list>
      <div class="spinner-item">
        <mat-progress-spinner
          [mode]="'indeterminate'"
          [diameter]="50"
          *ngIf="loading"
        ></mat-progress-spinner>
      </div>
    </div>
  </ng-container>
  <ng-template #noPatients>
    <mat-card appearance="outlined">
      <mat-card-content class="patients-filter">
        <p>{{ 'PATIENTS.PATIENTS_LIST.NO_PATIENTS' | translate }}</p>
      </mat-card-content>
    </mat-card>
  </ng-template>
</ng-container>
