<div mat-dialog-title>
  {{ 'FILE_UPLOAD_TABLE.DELETE_DIALOG.TITLE' | translate }}
</div>
<div mat-dialog-content>
  <p>
    {{ 'FILE_UPLOAD_TABLE.DELETE_DIALOG.MESSAGE' | translate }} {{ data.name }}
  </p>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="confirm()">
    {{ 'FILE_UPLOAD_TABLE.DELETE_DIALOG.BUTTON_DELETE' | translate }}
  </button>
  <button mat-button (click)="cancel()" cdkFocusInitial>
    {{ 'FILE_UPLOAD_TABLE.DELETE_DIALOG.BUTTON_CANCEL' | translate }}
  </button>
</div>
