import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'tdoc-delete-dialog',
  templateUrl: './delete-dialog.html',
})
export class DeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteConfirmationData,
  ) {}

  confirm(): void {
    this.data.confirm();
    this.dialogRef.close();
  }

  cancel(): void {
    this.dialogRef.close();
  }
}

interface DeleteConfirmationData {
  confirm: () => void;
  name: string;
}
