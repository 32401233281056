<mat-card appearance="outlined">
  <mat-card-content>
    <mat-card-title>{{
      'DIALOG.INCOMING_MEETING.HEADER' | translate
    }}</mat-card-title>
    <mat-dialog-actions class="button-container">
      <button mat-raised-button [mat-dialog-close]="true" (click)="onDecline()">
        {{ 'DIALOG.INCOMING_MEETING.DECLINE' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="onConfirm()"
        [mat-dialog-close]="true"
      >
        {{ 'DIALOG.INCOMING_MEETING.ACCEPT' | translate }}
      </button>
    </mat-dialog-actions>
  </mat-card-content>
</mat-card>
<audio #ringtone autoplay loop>
  <source src="assets/ring.ogg" type="audio/ogg" />
  <source src="assets/ring.wav" type="audio/wav" />
</audio>
