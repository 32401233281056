<ng-container *ngIf="translations$ | async">
  <div class="sign-in">
    <tdoc-logo></tdoc-logo>
    <form [formGroup]="signinForm" class="sign-in-form">
      <h2>{{ 'AUTH.SIGN_IN.HEADER' | translate }}</h2>
      <mat-form-field>
        <input
          id="authEmail"
          matInput
          placeholder="{{ 'AUTH.SIGN_IN.EMAIL' | translate }}"
          type="email"
          formControlName="email"
          autocomplete="email"
          required
        />
        <mat-hint *ngIf="!emailInput.value">{{
          'AUTH.SIGN_IN.EMAIL_HINT' | translate
        }}</mat-hint>
        <mat-error *ngIf="!emailInput.valid">
          {{ getEmailInputError() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          id="authPassword"
          matInput
          placeholder="{{ 'AUTH.SIGN_IN.PASSWORD' | translate }}"
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
          required
        />
        <mat-icon matSuffix (click)="hide = !hide">{{
          hide ? 'visibility_off' : 'visibility'
        }}</mat-icon>
        <mat-hint *ngIf="!passwordInput.value">{{
          'AUTH.SIGN_IN.PASSWORD_HINT' | translate
        }}</mat-hint>
        <mat-error *ngIf="!passwordInput.valid">
          {{ getPasswordInputError() }}
        </mat-error>
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        (click)="signIn()"
        [disabled]="!signinForm.valid"
      >
        {{ 'AUTH.SIGN_IN.SIGN_IN' | translate }}
      </button>
      <p>
        {{ 'AUTH.SIGN_IN.NO_ACCOUNT' | translate
        }}<a routerLink="/signup">{{ 'AUTH.SIGN_IN.SIGN_UP' | translate }}</a>
      </p>
      <p>
        {{ 'AUTH.SIGN_IN.FORGORT_PASSWORD' | translate
        }}<a routerLink="/forgot">{{ 'AUTH.SIGN_IN.SEND_CODE' | translate }}</a>
      </p>
    </form>
  </div>
  <div class="container">
    <tdoc-auth-language-selection></tdoc-auth-language-selection>
  </div>
  <div class="version">v{{ version }}</div>
</ng-container>
<tdoc-footer></tdoc-footer>
