import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import {
  ClinicalReportInput,
  PatientMedicalFileQuery,
} from 'src/app/API.service';
import { PatientsService } from '../patients.service';

@Component({
  selector: 'tdoc-patient-clinical-report',
  templateUrl: './patient-clinical-report.component.html',
  styleUrls: ['./patient-clinical-report.component.scss'],
})
export class PatientClinicalReportComponent implements OnInit {
  @Input() medicalFile: PatientMedicalFileQuery;
  @Input() disabled: boolean;

  translations$: Observable<any>;
  translations: any;
  formGroup = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];

  test: ClinicalReportInput;

  constructor(
    private translate: TranslateService,
    private patientService: PatientsService,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.model = {
      accessId: this.medicalFile.accessId,
    };
  }

  onSend(): void {
    this.patientService
      .updateClinicalReport(this.model)
      .pipe(take(1))
      .subscribe();
  }

  private initTranslations(): void {
    this.translations$ = this.translate.get('PATIENTS.REPORT').pipe(
      tap((translations) => {
        this.translations = translations;
        this.setupFormFields();
      }),
    );
  }

  private setupFormFields(): void {
    this.fields = [
      {
        key: 'anamnese',
        type: 'textarea',
        templateOptions: {
          label: this.translations['ANAMNESE'],
          rows: 4,
          resize: 'none',
          readonly: this.disabled,
        },
        modelOptions: {
          debounce: { default: 1000 },
        },
      },
      {
        key: 'diagnosis',
        type: 'textarea',
        templateOptions: {
          label: this.translations['DIAGNOSIS'],
          rows: 4,
          resize: 'none',
          readonly: this.disabled,
        },
        modelOptions: {
          debounce: { default: 1000 },
        },
      },
      {
        key: 'treatment',
        type: 'textarea',
        templateOptions: {
          label: this.translations['TREATMENT'],
          rows: 4,
          resize: 'none',
          readonly: this.disabled,
        },
        modelOptions: {
          debounce: { default: 1000 },
        },
      },
    ];
  }
}
