/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onIncomingMeetingRequest: OnIncomingMeetingRequestSubscription;
  onAppointmentUpdated: OnAppointmentUpdatedSubscription;
};

export type ClinicalReportInput = {
  accessId: string;
  anamnese?: string | null;
  diagnosis?: string | null;
  treatment?: string | null;
};

export type UpdateClinicalReportResponse = {
  __typename: "UpdateClinicalReportResponse";
  success?: boolean | null;
};

export type PrescriptionInput = {
  accessId: string;
  patientPersonalData?: PatientPersonalDataInput | null;
  diagnosis?: string | null;
  drug?: string | null;
  doctorName?: string | null;
  doctorIdentifierNumber?: string | null;
  doctorStampCode?: string | null;
  doctorAddress?: AddressInput | null;
  language?: string | null;
};

export type PatientPersonalDataInput = {
  firstName: string;
  lastName: string;
  dateOfBirth?: string | null;
  socialSecurityNumber?: string | null;
  address?: AddressInput | null;
};

export type AddressInput = {
  street?: string | null;
  address?: string | null;
  postCode?: string | null;
  city?: string | null;
  country?: CountryInput | null;
};

export type CountryInput = {
  name?: string | null;
  code?: string | null;
};

export type CreatePrescriptionResponse = {
  __typename: "CreatePrescriptionResponse";
  success?: boolean | null;
  errorCode?: string | null;
  errorMessage?: string | null;
};

export type AcceptMeetingResponse = {
  __typename: "AcceptMeetingResponse";
  accessId?: string | null;
  meetingData?: CreateMeetingWithAttendeesResponse | null;
};

export type CreateMeetingWithAttendeesResponse = {
  __typename: "CreateMeetingWithAttendeesResponse";
  Attendees?: Array<Attendee | null> | null;
  Meeting?: Meeting | null;
  Errors?: Array<CreateAttendeeError | null> | null;
};

export type Attendee = {
  __typename: "Attendee";
  AttendeeId?: string | null;
  ExternalUserId?: string | null;
  JoinToken?: string | null;
};

export type Meeting = {
  __typename: "Meeting";
  MeetingId?: string | null;
  MediaRegion?: string | null;
  ExternalMeetingId?: string | null;
  MediaPlacement?: MediaPlacement | null;
};

export type MediaPlacement = {
  __typename: "MediaPlacement";
  AudioFallbackUrl?: string | null;
  AudioHostUrl?: string | null;
  ScreenDataUrl?: string | null;
  ScreenSharingUrl?: string | null;
  ScreenViewingUrl?: string | null;
  SignalingUrl?: string | null;
  TurnControlUrl?: string | null;
};

export type CreateAttendeeError = {
  __typename: "CreateAttendeeError";
  ExternalUserId?: string | null;
  ErrorCode?: string | null;
  ErrorMessage?: string | null;
};

export type SuccessfulOrNotRespone = {
  __typename: "SuccessfulOrNotRespone";
  success?: boolean | null;
};

export type NotifyMeetingRequestReponse = {
  __typename: "NotifyMeetingRequestReponse";
  regionCode: string;
};

export type DoctorProfileInput = {
  firstName?: string | null;
  lastName?: string | null;
  doctorIdentifierNumber?: string | null;
  doctorStampCode?: string | null;
  address?: AddressInput | null;
  phoneNumber?: string | null;
  biography?: string | null;
  expertise?: string | null;
  region?: CountryInput | null;
  medicalSpecialist?: string | null;
  languages?: Array<string> | null;
  medicalSpecialties?: Array<string> | null;
  medicalSubspecialties?: string | null;
  hoursOfAvailability?: Array<HoursOfAvailabilityInput | null> | null;
  clinicId?: string | null;
  appLanguage?: string | null;
};

export type HoursOfAvailabilityInput = {
  weekday?: Weekday | null;
  from?: string | null;
  to?: string | null;
  timezone: string;
};

export enum Weekday {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY"
}

export type DoctorProfile = {
  __typename: "DoctorProfile";
  doctorId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  doctorIdentifierNumber?: string | null;
  doctorStampCode?: string | null;
  isActive?: boolean | null;
  activationRequested?: boolean | null;
  isAvailable?: boolean | null;
  address?: Address | null;
  phoneNumber?: string | null;
  biography?: string | null;
  expertise?: string | null;
  region?: Country | null;
  medicalSpecialist?: string | null;
  languages?: Array<string | null> | null;
  medicalSpecialties?: Array<string | null> | null;
  medicalSubspecialties?: string | null;
  hoursOfAvailability?: Array<HoursOfAvailability | null> | null;
  imageUrl?: string | null;
  clinicId?: string | null;
  clinic?: Clinic | null;
  appLanguage?: string | null;
};

export type Address = {
  __typename: "Address";
  street?: string | null;
  address?: string | null;
  postCode?: string | null;
  city?: string | null;
  country?: Country | null;
};

export type Country = {
  __typename: "Country";
  name?: string | null;
  code?: string | null;
};

export type HoursOfAvailability = {
  __typename: "HoursOfAvailability";
  weekday: Weekday;
  from: string;
  to: string;
  timezone: string;
};

export type Clinic = {
  __typename: "Clinic";
  id: string;
  name?: string | null;
  address?: string | null;
  logoUrl?: string | null;
  description?: string | null;
  appointmentPrice?: ProductPrice | null;
  regionCode?: string | null;
};

export type ProductPrice = {
  __typename: "ProductPrice";
  total: number;
  currency: Currency;
  valueAddedTaxPercent?: number | null;
  base?: number | null;
};

export enum Currency {
  EUR = "EUR",
  USD = "USD",
  RON = "RON",
  HRK = "HRK",
  ALL = "ALL",
  BGN = "BGN",
  HUF = "HUF"
}

export type SetDoctorAvailableResponse = {
  __typename: "SetDoctorAvailableResponse";
  hasPatientsInQueue?: boolean | null;
};

export type AvailabilityInput = {
  status: AvailabilityStatus;
  meetingsStatus?: number | null;
  doctorStatus?: boolean | null;
  hasMeetingRequestSubscriptionCompleted?: boolean | null;
  receivedCalls?: Array<ReceivedCallsInput | null> | null;
  acceptedCalls?: Array<string | null> | null;
  declinedCalls?: Array<string | null> | null;
  requestDialogsOpened?: Array<string | null> | null;
  meetingRequestSubscriptionErrors?: Array<MeetingRequestSubscriptionErrorInput | null> | null;
};

export enum AvailabilityStatus {
  AVAILABLE = "AVAILABLE",
  UNAVAILABLE = "UNAVAILABLE",
  IN_CALL = "IN_CALL"
}

export type ReceivedCallsInput = {
  regionCode?: string | null;
  createdAt?: string | null;
};

export type MeetingRequestSubscriptionErrorInput = {
  error?: string | null;
  createdAt?: string | null;
};

export type PatientProfileInput = {
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
  gender?: Gender | null;
  socialSecurityNumber?: string | null;
  address?: AddressInput | null;
  medicalConditions?: MedicalConditionsInput | null;
  other?: string | null;
};

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  NON_BINARY = "NON_BINARY",
  UNKNOWN = "UNKNOWN"
}

export type MedicalConditionsInput = {
  allergies?: string | null;
  medicationAllergies?: string | null;
  hasGlasses?: boolean | null;
  isSmoking?: boolean | null;
  hasKids?: boolean | null;
  hasDiabetes?: boolean | null;
  surgeries?: boolean | null;
  preConditions?: string | null;
};

export type PatientPersonalData = {
  __typename: "PatientPersonalData";
  firstName: string;
  lastName: string;
  dateOfBirth?: string | null;
  gender?: Gender | null;
  medicalConditions?: MedicalConditions | null;
  socialSecurityNumber?: string | null;
  address?: Address | null;
};

export type MedicalConditions = {
  __typename: "MedicalConditions";
  allergies?: string | null;
  medicationAllergies?: string | null;
  hasGlasses?: boolean | null;
  isSmoking?: boolean | null;
  hasKids?: boolean | null;
  hasDiabetes?: boolean | null;
  surgeries?: boolean | null;
  preConditions?: string | null;
};

export type DeleteDoctorFileResponse = {
  __typename: "DeleteDoctorFileResponse";
  success?: boolean | null;
};

export type SpecialistProductInput = {
  price: PriceInput;
  billingName: string;
};

export type PriceInput = {
  total: number;
  currency: Currency;
  valueAddedTaxPercent?: number | null;
  base?: number | null;
};

export type SpecialistProduct = {
  __typename: "SpecialistProduct";
  productId: string;
  price: ProductPrice;
  billingName: string;
};

export type Appointment = {
  __typename: "Appointment";
  appointmentId: string;
  from: string;
  to: string;
  requestedAt: string;
  doctorId: string;
  appointmentStatus: AppointmentStatus;
  cancellation?: AppointmentCancellationInfo | null;
  note?: string | null;
  patient?: AppointmentPatientInfo | null;
  attachments: Array<PatientFileAttachment>;
};

export enum AppointmentStatus {
  PATIENT_PAYMENT_REQUIRED = "PATIENT_PAYMENT_REQUIRED",
  DOCTOR_CONFIRMATION_PENDING = "DOCTOR_CONFIRMATION_PENDING",
  DOCTOR_CONFIRMED = "DOCTOR_CONFIRMED",
  CANCELLED = "CANCELLED",
  INITIALISING = "INITIALISING",
  STARTED = "STARTED",
  FINISHED = "FINISHED"
}

export type AppointmentCancellationInfo = {
  __typename: "AppointmentCancellationInfo";
  cancelledAt: string;
  cancelledBy: AppointmentCancellationPrincipal;
  reason?: string | null;
};

export enum AppointmentCancellationPrincipal {
  DOCTOR = "DOCTOR",
  PATIENT = "PATIENT",
  SYSTEM = "SYSTEM"
}

export type AppointmentPatientInfo = {
  __typename: "AppointmentPatientInfo";
  firstName?: string | null;
  lastName?: string | null;
};

export type PatientFileAttachment = {
  __typename: "PatientFileAttachment";
  fileAttachmentId: string;
  fileName: string;
  fileDateTime: string;
};

export type AppointmentCancellationInfoInput = {
  reason?: string | null;
};

export type JoinAppointmentResponse = {
  __typename: "JoinAppointmentResponse";
  accessId?: string | null;
  meetingData?: CreateMeetingWithAttendeesResponse | null;
};

export type NotifyAppointmentUpdatedInput = {
  appointmentId: string;
  doctorId: string;
  patientId: string;
  from: string;
  to: string;
  requestedAt: string;
  appointmentStatus: AppointmentStatus;
  appliedProduct?: AppointmentItemAppliedProductInput | null;
  accessId?: string | null;
  pendingDoctorConfirmation?: string | null;
  cancellation?: AppointmentCancellationInfoDataInput | null;
  note?: string | null;
  patient?: AppointmentPatientInfoInput | null;
  paymentId?: string | null;
  visitId?: string | null;
  pendingPaymentId?: string | null;
  meetingInfo?: CreateMeetingWithAttendeesResponseInput | null;
  linkedAttachments?: Array<string | null> | null;
  nodeId?: string | null;
  dayOfEvent?: string | null;
};

export type AppointmentItemAppliedProductInput = {
  productId?: string | null;
  productType?: ProductType | null;
  productName?: string | null;
  partnerId?: string | null;
  partnerName?: string | null;
  feature?: Feature | null;
  regionCode?: string | null;
};

export enum ProductType {
  subscription = "subscription",
  credit = "credit",
  duration = "duration",
  call = "call",
  specialistCall = "specialistCall"
}

export enum Feature {
  BASIC_CALL = "BASIC_CALL",
  SPECIALIST_CALL = "SPECIALIST_CALL"
}

export type AppointmentCancellationInfoDataInput = {
  cancelledAt: string;
  cancelledBy: AppointmentCancellationPrincipal;
  reason?: string | null;
  refunded?: boolean | null;
};

export type AppointmentPatientInfoInput = {
  firstName?: string | null;
  lastName?: string | null;
};

export type CreateMeetingWithAttendeesResponseInput = {
  Attendees?: Array<AttendeeInput | null> | null;
  Meeting?: MeetingInput | null;
  Errors?: Array<CreateAttendeeErrorInput | null> | null;
};

export type AttendeeInput = {
  AttendeeId?: string | null;
  ExternalUserId?: string | null;
  JoinToken?: string | null;
};

export type MeetingInput = {
  MeetingId?: string | null;
  MediaRegion?: string | null;
  ExternalMeetingId?: string | null;
  MediaPlacement?: MediaPlacementInput | null;
};

export type MediaPlacementInput = {
  AudioFallbackUrl?: string | null;
  AudioHostUrl?: string | null;
  ScreenDataUrl?: string | null;
  ScreenSharingUrl?: string | null;
  ScreenViewingUrl?: string | null;
  SignalingUrl?: string | null;
  TurnControlUrl?: string | null;
};

export type CreateAttendeeErrorInput = {
  ExternalUserId?: string | null;
  ErrorCode?: string | null;
  ErrorMessage?: string | null;
};

export type DeleteDoctorProfilePictureResponse = {
  __typename: "DeleteDoctorProfilePictureResponse";
  success?: boolean | null;
};

export type PatientMedicalFile = {
  __typename: "PatientMedicalFile";
  accessId?: string | null;
  patientId?: string | null;
  personalData?: PatientPersonalData | null;
  visits: Array<Visit>;
  patientFiles: Array<PatientFileAttachment>;
};

export type Visit = {
  __typename: "Visit";
  visitId: string;
  visitDateTime: string;
  clinicalReport: ClinicalReport;
  prescriptions: Array<Prescription>;
};

export type ClinicalReport = {
  __typename: "ClinicalReport";
  anamnese?: string | null;
  diagnosis?: string | null;
  treatment?: string | null;
};

export type Prescription = {
  __typename: "Prescription";
  visitId: string;
  prescriptionId: string;
  prescriptionName: string;
  prescriptionDateTime: string;
};

export type PatientFilter = {
  firstName?: string | null;
  lastName?: string | null;
  partnerName?: string | null;
};

export type PageInput = {
  cursor?: string | null;
  size?: number | null;
};

export type PatientSearchPageResponse = {
  __typename: "PatientSearchPageResponse";
  results: Array<PatientMedicalPreview | null>;
  pageInfo: PageInfo;
};

export type PatientMedicalPreview = {
  __typename: "PatientMedicalPreview";
  accessId?: string | null;
  patientId?: string | null;
  personal?: PatientPersonalData | null;
  regionCode?: string | null;
  partnerName?: string | null;
};

export type PageInfo = {
  __typename: "PageInfo";
  nextCursor?: string | null;
  previousCursor?: string | null;
};

export type PatientFileAttachmentDownloadUrlInput = {
  accessId: string;
  fileAttachmentId: string;
};

export type PatientFileAttachmentDownloadUrlResponse = {
  __typename: "PatientFileAttachmentDownloadUrlResponse";
  url: string;
};

export type AppointmentAttachmentDownloadUrlInput = {
  appointmentId: string;
  fileAttachmentId: string;
};

export type DoctorFileDownloadUrlResponse = {
  __typename: "DoctorFileDownloadUrlResponse";
  url: string;
};

export type DoctorFile = {
  __typename: "DoctorFile";
  fileId?: string | null;
  fileName?: string | null;
  fileUploadDateTime?: string | null;
};

export type TeledocRegion = {
  __typename: "TeledocRegion";
  code: string;
};

export type MedicalSpecialtiesResponse = {
  __typename: "MedicalSpecialtiesResponse";
  medicalSpecialties?: Array<string> | null;
};

export type DoctorLangaugesResponse = {
  __typename: "DoctorLangaugesResponse";
  results: Array<string>;
};

export type AppointmentsFilter = {
  from?: string | null;
  to?: string | null;
  status?: AppointmentStatus | null;
};

export type AppointmentPageResponse = {
  __typename: "AppointmentPageResponse";
  results: Array<Appointment>;
  pageInfo: PageInfo;
};

export type QueryClinicsResponse = {
  __typename: "QueryClinicsResponse";
  entries: Array<Clinic>;
};

export type PrescriptionDownloadUrlResponse = {
  __typename: "PrescriptionDownloadUrlResponse";
  url: string;
};

export type UpdateClinicalReportMutation = {
  __typename: "UpdateClinicalReportResponse";
  success?: boolean | null;
};

export type CreatePrescriptionMutation = {
  __typename: "CreatePrescriptionResponse";
  success?: boolean | null;
  errorCode?: string | null;
  errorMessage?: string | null;
};

export type AcceptMeetingRequestMutation = {
  __typename: "AcceptMeetingResponse";
  accessId?: string | null;
  meetingData?: {
    __typename: "CreateMeetingWithAttendeesResponse";
    Attendees?: Array<{
      __typename: "Attendee";
      AttendeeId?: string | null;
      ExternalUserId?: string | null;
      JoinToken?: string | null;
    } | null> | null;
    Meeting?: {
      __typename: "Meeting";
      MeetingId?: string | null;
      MediaRegion?: string | null;
      ExternalMeetingId?: string | null;
      MediaPlacement?: {
        __typename: "MediaPlacement";
        AudioFallbackUrl?: string | null;
        AudioHostUrl?: string | null;
        ScreenDataUrl?: string | null;
        ScreenSharingUrl?: string | null;
        ScreenViewingUrl?: string | null;
        SignalingUrl?: string | null;
        TurnControlUrl?: string | null;
      } | null;
    } | null;
    Errors?: Array<{
      __typename: "CreateAttendeeError";
      ExternalUserId?: string | null;
      ErrorCode?: string | null;
      ErrorMessage?: string | null;
    } | null> | null;
  } | null;
};

export type TerminateVisitMutation = {
  __typename: "SuccessfulOrNotRespone";
  success?: boolean | null;
};

export type NotifyMeetingRequestMutation = {
  __typename: "NotifyMeetingRequestReponse";
  regionCode: string;
};

export type SaveDoctorProfileMutation = {
  __typename: "DoctorProfile";
  doctorId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  doctorIdentifierNumber?: string | null;
  doctorStampCode?: string | null;
  isActive?: boolean | null;
  activationRequested?: boolean | null;
  isAvailable?: boolean | null;
  address?: {
    __typename: "Address";
    street?: string | null;
    address?: string | null;
    postCode?: string | null;
    city?: string | null;
    country?: {
      __typename: "Country";
      name?: string | null;
      code?: string | null;
    } | null;
  } | null;
  phoneNumber?: string | null;
  biography?: string | null;
  expertise?: string | null;
  region?: {
    __typename: "Country";
    name?: string | null;
    code?: string | null;
  } | null;
  medicalSpecialist?: string | null;
  languages?: Array<string | null> | null;
  medicalSpecialties?: Array<string | null> | null;
  medicalSubspecialties?: string | null;
  hoursOfAvailability?: Array<{
    __typename: "HoursOfAvailability";
    weekday: Weekday;
    from: string;
    to: string;
    timezone: string;
  } | null> | null;
  imageUrl?: string | null;
  clinicId?: string | null;
  clinic?: {
    __typename: "Clinic";
    id: string;
    name?: string | null;
    address?: string | null;
    logoUrl?: string | null;
    description?: string | null;
    appointmentPrice?: {
      __typename: "ProductPrice";
      total: number;
      currency: Currency;
      valueAddedTaxPercent?: number | null;
      base?: number | null;
    } | null;
    regionCode?: string | null;
  } | null;
  appLanguage?: string | null;
};

export type RequestActivationMutation = {
  __typename: "SuccessfulOrNotRespone";
  success?: boolean | null;
};

export type SetAvailableMutation = {
  __typename: "SetDoctorAvailableResponse";
  hasPatientsInQueue?: boolean | null;
};

export type SetAwayMutation = {
  __typename: "SuccessfulOrNotRespone";
  success?: boolean | null;
};

export type SetUnavailableMutation = {
  __typename: "DoctorProfile";
  doctorId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  doctorIdentifierNumber?: string | null;
  doctorStampCode?: string | null;
  isActive?: boolean | null;
  activationRequested?: boolean | null;
  isAvailable?: boolean | null;
  address?: {
    __typename: "Address";
    street?: string | null;
    address?: string | null;
    postCode?: string | null;
    city?: string | null;
    country?: {
      __typename: "Country";
      name?: string | null;
      code?: string | null;
    } | null;
  } | null;
  phoneNumber?: string | null;
  biography?: string | null;
  expertise?: string | null;
  region?: {
    __typename: "Country";
    name?: string | null;
    code?: string | null;
  } | null;
  medicalSpecialist?: string | null;
  languages?: Array<string | null> | null;
  medicalSpecialties?: Array<string | null> | null;
  medicalSubspecialties?: string | null;
  hoursOfAvailability?: Array<{
    __typename: "HoursOfAvailability";
    weekday: Weekday;
    from: string;
    to: string;
    timezone: string;
  } | null> | null;
  imageUrl?: string | null;
  clinicId?: string | null;
  clinic?: {
    __typename: "Clinic";
    id: string;
    name?: string | null;
    address?: string | null;
    logoUrl?: string | null;
    description?: string | null;
    appointmentPrice?: {
      __typename: "ProductPrice";
      total: number;
      currency: Currency;
      valueAddedTaxPercent?: number | null;
      base?: number | null;
    } | null;
    regionCode?: string | null;
  } | null;
  appLanguage?: string | null;
};

export type PingAvailabilityMutation = {
  __typename: "SuccessfulOrNotRespone";
  success?: boolean | null;
};

export type SavePatientProfileMutation = {
  __typename: "PatientPersonalData";
  firstName: string;
  lastName: string;
  dateOfBirth?: string | null;
  gender?: Gender | null;
  medicalConditions?: {
    __typename: "MedicalConditions";
    allergies?: string | null;
    medicationAllergies?: string | null;
    hasGlasses?: boolean | null;
    isSmoking?: boolean | null;
    hasKids?: boolean | null;
    hasDiabetes?: boolean | null;
    surgeries?: boolean | null;
    preConditions?: string | null;
  } | null;
  socialSecurityNumber?: string | null;
  address?: {
    __typename: "Address";
    street?: string | null;
    address?: string | null;
    postCode?: string | null;
    city?: string | null;
    country?: {
      __typename: "Country";
      name?: string | null;
      code?: string | null;
    } | null;
  } | null;
};

export type DeleteDoctorFileMutation = {
  __typename: "DeleteDoctorFileResponse";
  success?: boolean | null;
};

export type SetSpecialistProductMutation = {
  __typename: "SpecialistProduct";
  productId: string;
  price: {
    __typename: "ProductPrice";
    total: number;
    currency: Currency;
    valueAddedTaxPercent?: number | null;
    base?: number | null;
  };
  billingName: string;
};

export type ConfirmAppointmentMutation = {
  __typename: "Appointment";
  appointmentId: string;
  from: string;
  to: string;
  requestedAt: string;
  doctorId: string;
  appointmentStatus: AppointmentStatus;
  cancellation?: {
    __typename: "AppointmentCancellationInfo";
    cancelledAt: string;
    cancelledBy: AppointmentCancellationPrincipal;
    reason?: string | null;
  } | null;
  note?: string | null;
  patient?: {
    __typename: "AppointmentPatientInfo";
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  attachments: Array<{
    __typename: "PatientFileAttachment";
    fileAttachmentId: string;
    fileName: string;
    fileDateTime: string;
  }>;
};

export type CancelAppointmentMutation = {
  __typename: "Appointment";
  appointmentId: string;
  from: string;
  to: string;
  requestedAt: string;
  doctorId: string;
  appointmentStatus: AppointmentStatus;
  cancellation?: {
    __typename: "AppointmentCancellationInfo";
    cancelledAt: string;
    cancelledBy: AppointmentCancellationPrincipal;
    reason?: string | null;
  } | null;
  note?: string | null;
  patient?: {
    __typename: "AppointmentPatientInfo";
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  attachments: Array<{
    __typename: "PatientFileAttachment";
    fileAttachmentId: string;
    fileName: string;
    fileDateTime: string;
  }>;
};

export type JoinAppointmentMeetingMutation = {
  __typename: "JoinAppointmentResponse";
  accessId?: string | null;
  meetingData?: {
    __typename: "CreateMeetingWithAttendeesResponse";
    Attendees?: Array<{
      __typename: "Attendee";
      AttendeeId?: string | null;
      ExternalUserId?: string | null;
      JoinToken?: string | null;
    } | null> | null;
    Meeting?: {
      __typename: "Meeting";
      MeetingId?: string | null;
      MediaRegion?: string | null;
      ExternalMeetingId?: string | null;
      MediaPlacement?: {
        __typename: "MediaPlacement";
        AudioFallbackUrl?: string | null;
        AudioHostUrl?: string | null;
        ScreenDataUrl?: string | null;
        ScreenSharingUrl?: string | null;
        ScreenViewingUrl?: string | null;
        SignalingUrl?: string | null;
        TurnControlUrl?: string | null;
      } | null;
    } | null;
    Errors?: Array<{
      __typename: "CreateAttendeeError";
      ExternalUserId?: string | null;
      ErrorCode?: string | null;
      ErrorMessage?: string | null;
    } | null> | null;
  } | null;
};

export type NotifyAppointmentUpdatedMutation = {
  __typename: "Appointment";
  appointmentId: string;
  from: string;
  to: string;
  requestedAt: string;
  doctorId: string;
  appointmentStatus: AppointmentStatus;
  cancellation?: {
    __typename: "AppointmentCancellationInfo";
    cancelledAt: string;
    cancelledBy: AppointmentCancellationPrincipal;
    reason?: string | null;
  } | null;
  note?: string | null;
  patient?: {
    __typename: "AppointmentPatientInfo";
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  attachments: Array<{
    __typename: "PatientFileAttachment";
    fileAttachmentId: string;
    fileName: string;
    fileDateTime: string;
  }>;
};

export type DeleteDoctorProfilePictureMutation = {
  __typename: "DeleteDoctorProfilePictureResponse";
  success?: boolean | null;
};

export type PatientMedicalFileQuery = {
  __typename: "PatientMedicalFile";
  accessId?: string | null;
  patientId?: string | null;
  personalData?: {
    __typename: "PatientPersonalData";
    firstName: string;
    lastName: string;
    dateOfBirth?: string | null;
    gender?: Gender | null;
    medicalConditions?: {
      __typename: "MedicalConditions";
      allergies?: string | null;
      medicationAllergies?: string | null;
      hasGlasses?: boolean | null;
      isSmoking?: boolean | null;
      hasKids?: boolean | null;
      hasDiabetes?: boolean | null;
      surgeries?: boolean | null;
      preConditions?: string | null;
    } | null;
    socialSecurityNumber?: string | null;
    address?: {
      __typename: "Address";
      street?: string | null;
      address?: string | null;
      postCode?: string | null;
      city?: string | null;
      country?: {
        __typename: "Country";
        name?: string | null;
        code?: string | null;
      } | null;
    } | null;
  } | null;
  visits: Array<{
    __typename: "Visit";
    visitId: string;
    visitDateTime: string;
    clinicalReport: {
      __typename: "ClinicalReport";
      anamnese?: string | null;
      diagnosis?: string | null;
      treatment?: string | null;
    };
    prescriptions: Array<{
      __typename: "Prescription";
      visitId: string;
      prescriptionId: string;
      prescriptionName: string;
      prescriptionDateTime: string;
    }>;
  }>;
  patientFiles: Array<{
    __typename: "PatientFileAttachment";
    fileAttachmentId: string;
    fileName: string;
    fileDateTime: string;
  }>;
};

export type PatientListQuery = {
  __typename: "PatientSearchPageResponse";
  results: Array<{
    __typename: "PatientMedicalPreview";
    accessId?: string | null;
    patientId?: string | null;
    personal?: {
      __typename: "PatientPersonalData";
      firstName: string;
      lastName: string;
      dateOfBirth?: string | null;
      gender?: Gender | null;
      medicalConditions?: {
        __typename: "MedicalConditions";
        allergies?: string | null;
        medicationAllergies?: string | null;
        hasGlasses?: boolean | null;
        isSmoking?: boolean | null;
        hasKids?: boolean | null;
        hasDiabetes?: boolean | null;
        surgeries?: boolean | null;
        preConditions?: string | null;
      } | null;
      socialSecurityNumber?: string | null;
      address?: {
        __typename: "Address";
        street?: string | null;
        address?: string | null;
        postCode?: string | null;
        city?: string | null;
        country?: {
          __typename: "Country";
          name?: string | null;
          code?: string | null;
        } | null;
      } | null;
    } | null;
    regionCode?: string | null;
    partnerName?: string | null;
  } | null>;
  pageInfo: {
    __typename: "PageInfo";
    nextCursor?: string | null;
    previousCursor?: string | null;
  };
};

export type PatientFileAttachmentDownloadUrlQuery = {
  __typename: "PatientFileAttachmentDownloadUrlResponse";
  url: string;
};

export type AppointmentAttachmentDownloadUrlQuery = {
  __typename: "PatientFileAttachmentDownloadUrlResponse";
  url: string;
};

export type DoctorProfileQuery = {
  __typename: "DoctorProfile";
  doctorId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  doctorIdentifierNumber?: string | null;
  doctorStampCode?: string | null;
  isActive?: boolean | null;
  activationRequested?: boolean | null;
  isAvailable?: boolean | null;
  address?: {
    __typename: "Address";
    street?: string | null;
    address?: string | null;
    postCode?: string | null;
    city?: string | null;
    country?: {
      __typename: "Country";
      name?: string | null;
      code?: string | null;
    } | null;
  } | null;
  phoneNumber?: string | null;
  biography?: string | null;
  expertise?: string | null;
  region?: {
    __typename: "Country";
    name?: string | null;
    code?: string | null;
  } | null;
  medicalSpecialist?: string | null;
  languages?: Array<string | null> | null;
  medicalSpecialties?: Array<string | null> | null;
  medicalSubspecialties?: string | null;
  hoursOfAvailability?: Array<{
    __typename: "HoursOfAvailability";
    weekday: Weekday;
    from: string;
    to: string;
    timezone: string;
  } | null> | null;
  imageUrl?: string | null;
  clinicId?: string | null;
  clinic?: {
    __typename: "Clinic";
    id: string;
    name?: string | null;
    address?: string | null;
    logoUrl?: string | null;
    description?: string | null;
    appointmentPrice?: {
      __typename: "ProductPrice";
      total: number;
      currency: Currency;
      valueAddedTaxPercent?: number | null;
      base?: number | null;
    } | null;
    regionCode?: string | null;
  } | null;
  appLanguage?: string | null;
};

export type GetDoctorFileDownloadUrlQuery = {
  __typename: "DoctorFileDownloadUrlResponse";
  url: string;
};

export type GetDoctorFilesQuery = {
  __typename: "DoctorFile";
  fileId?: string | null;
  fileName?: string | null;
  fileUploadDateTime?: string | null;
};

export type TeledocRegionsQuery = {
  __typename: "TeledocRegion";
  code: string;
};

export type MedicalSpecialtiesQuery = {
  __typename: "MedicalSpecialtiesResponse";
  medicalSpecialties?: Array<string> | null;
};

export type SpecialistProductQuery = {
  __typename: "SpecialistProduct";
  productId: string;
  price: {
    __typename: "ProductPrice";
    total: number;
    currency: Currency;
    valueAddedTaxPercent?: number | null;
    base?: number | null;
  };
  billingName: string;
};

export type DoctorLanguagesQuery = {
  __typename: "DoctorLangaugesResponse";
  results: Array<string>;
};

export type AppointmentsQuery = {
  __typename: "AppointmentPageResponse";
  results: Array<{
    __typename: "Appointment";
    appointmentId: string;
    from: string;
    to: string;
    requestedAt: string;
    doctorId: string;
    appointmentStatus: AppointmentStatus;
    cancellation?: {
      __typename: "AppointmentCancellationInfo";
      cancelledAt: string;
      cancelledBy: AppointmentCancellationPrincipal;
      reason?: string | null;
    } | null;
    note?: string | null;
    patient?: {
      __typename: "AppointmentPatientInfo";
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    attachments: Array<{
      __typename: "PatientFileAttachment";
      fileAttachmentId: string;
      fileName: string;
      fileDateTime: string;
    }>;
  }>;
  pageInfo: {
    __typename: "PageInfo";
    nextCursor?: string | null;
    previousCursor?: string | null;
  };
};

export type AppointmentQuery = {
  __typename: "Appointment";
  appointmentId: string;
  from: string;
  to: string;
  requestedAt: string;
  doctorId: string;
  appointmentStatus: AppointmentStatus;
  cancellation?: {
    __typename: "AppointmentCancellationInfo";
    cancelledAt: string;
    cancelledBy: AppointmentCancellationPrincipal;
    reason?: string | null;
  } | null;
  note?: string | null;
  patient?: {
    __typename: "AppointmentPatientInfo";
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  attachments: Array<{
    __typename: "PatientFileAttachment";
    fileAttachmentId: string;
    fileName: string;
    fileDateTime: string;
  }>;
};

export type ClinicsQuery = {
  __typename: "QueryClinicsResponse";
  entries: Array<{
    __typename: "Clinic";
    id: string;
    name?: string | null;
    address?: string | null;
    logoUrl?: string | null;
    description?: string | null;
    appointmentPrice?: {
      __typename: "ProductPrice";
      total: number;
      currency: Currency;
      valueAddedTaxPercent?: number | null;
      base?: number | null;
    } | null;
    regionCode?: string | null;
  }>;
};

export type GetPrescriptionDownloadUrlQuery = {
  __typename: "PrescriptionDownloadUrlResponse";
  url: string;
};

export type OnIncomingMeetingRequestSubscription = {
  __typename: "NotifyMeetingRequestReponse";
  regionCode: string;
};

export type OnAppointmentUpdatedSubscription = {
  __typename: "Appointment";
  appointmentId: string;
  from: string;
  to: string;
  requestedAt: string;
  doctorId: string;
  appointmentStatus: AppointmentStatus;
  cancellation?: {
    __typename: "AppointmentCancellationInfo";
    cancelledAt: string;
    cancelledBy: AppointmentCancellationPrincipal;
    reason?: string | null;
  } | null;
  note?: string | null;
  patient?: {
    __typename: "AppointmentPatientInfo";
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  attachments: Array<{
    __typename: "PatientFileAttachment";
    fileAttachmentId: string;
    fileName: string;
    fileDateTime: string;
  }>;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async UpdateClinicalReport(
    input: ClinicalReportInput
  ): Promise<UpdateClinicalReportMutation> {
    const statement = `mutation UpdateClinicalReport($input: ClinicalReportInput!) {
        updateClinicalReport(input: $input) {
          __typename
          success
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateClinicalReportMutation>response.data.updateClinicalReport;
  }
  async CreatePrescription(
    input: PrescriptionInput
  ): Promise<CreatePrescriptionMutation> {
    const statement = `mutation CreatePrescription($input: PrescriptionInput!) {
        createPrescription(input: $input) {
          __typename
          success
          errorCode
          errorMessage
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePrescriptionMutation>response.data.createPrescription;
  }
  async AcceptMeetingRequest(): Promise<AcceptMeetingRequestMutation> {
    const statement = `mutation AcceptMeetingRequest {
        acceptMeetingRequest {
          __typename
          accessId
          meetingData {
            __typename
            Attendees {
              __typename
              AttendeeId
              ExternalUserId
              JoinToken
            }
            Meeting {
              __typename
              MeetingId
              MediaRegion
              ExternalMeetingId
              MediaPlacement {
                __typename
                AudioFallbackUrl
                AudioHostUrl
                ScreenDataUrl
                ScreenSharingUrl
                ScreenViewingUrl
                SignalingUrl
                TurnControlUrl
              }
            }
            Errors {
              __typename
              ExternalUserId
              ErrorCode
              ErrorMessage
            }
          }
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <AcceptMeetingRequestMutation>response.data.acceptMeetingRequest;
  }
  async TerminateMeeting(accessId: string): Promise<boolean | null> {
    const statement = `mutation TerminateMeeting($accessId: ID!) {
        terminateMeeting(accessId: $accessId)
      }`;
    const gqlAPIServiceArguments: any = {
      accessId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <boolean | null>response.data.terminateMeeting;
  }
  async TerminateVisit(accessId: string): Promise<TerminateVisitMutation> {
    const statement = `mutation TerminateVisit($accessId: ID!) {
        terminateVisit(accessId: $accessId) {
          __typename
          success
        }
      }`;
    const gqlAPIServiceArguments: any = {
      accessId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TerminateVisitMutation>response.data.terminateVisit;
  }
  async NotifyMeetingRequest(
    regionCode: string
  ): Promise<NotifyMeetingRequestMutation> {
    const statement = `mutation NotifyMeetingRequest($regionCode: String!) {
        notifyMeetingRequest(regionCode: $regionCode) {
          __typename
          regionCode
        }
      }`;
    const gqlAPIServiceArguments: any = {
      regionCode
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <NotifyMeetingRequestMutation>response.data.notifyMeetingRequest;
  }
  async SaveDoctorProfile(
    input?: DoctorProfileInput
  ): Promise<SaveDoctorProfileMutation> {
    const statement = `mutation SaveDoctorProfile($input: DoctorProfileInput) {
        saveDoctorProfile(input: $input) {
          __typename
          doctorId
          firstName
          lastName
          doctorIdentifierNumber
          doctorStampCode
          isActive
          activationRequested
          isAvailable
          address {
            __typename
            street
            address
            postCode
            city
            country {
              __typename
              name
              code
            }
          }
          phoneNumber
          biography
          expertise
          region {
            __typename
            name
            code
          }
          medicalSpecialist
          languages
          medicalSpecialties
          medicalSubspecialties
          hoursOfAvailability {
            __typename
            weekday
            from
            to
            timezone
          }
          imageUrl
          clinicId
          clinic {
            __typename
            id
            name
            address
            logoUrl
            description
            appointmentPrice {
              __typename
              total
              currency
              valueAddedTaxPercent
              base
            }
            regionCode
          }
          appLanguage
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SaveDoctorProfileMutation>response.data.saveDoctorProfile;
  }
  async RequestActivation(): Promise<RequestActivationMutation> {
    const statement = `mutation RequestActivation {
        requestActivation {
          __typename
          success
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <RequestActivationMutation>response.data.requestActivation;
  }
  async SetAvailable(): Promise<SetAvailableMutation> {
    const statement = `mutation SetAvailable {
        setAvailable {
          __typename
          hasPatientsInQueue
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <SetAvailableMutation>response.data.setAvailable;
  }
  async SetAway(): Promise<SetAwayMutation> {
    const statement = `mutation SetAway {
        setAway {
          __typename
          success
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <SetAwayMutation>response.data.setAway;
  }
  async SetUnavailable(): Promise<SetUnavailableMutation> {
    const statement = `mutation SetUnavailable {
        setUnavailable {
          __typename
          doctorId
          firstName
          lastName
          doctorIdentifierNumber
          doctorStampCode
          isActive
          activationRequested
          isAvailable
          address {
            __typename
            street
            address
            postCode
            city
            country {
              __typename
              name
              code
            }
          }
          phoneNumber
          biography
          expertise
          region {
            __typename
            name
            code
          }
          medicalSpecialist
          languages
          medicalSpecialties
          medicalSubspecialties
          hoursOfAvailability {
            __typename
            weekday
            from
            to
            timezone
          }
          imageUrl
          clinicId
          clinic {
            __typename
            id
            name
            address
            logoUrl
            description
            appointmentPrice {
              __typename
              total
              currency
              valueAddedTaxPercent
              base
            }
            regionCode
          }
          appLanguage
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <SetUnavailableMutation>response.data.setUnavailable;
  }
  async PingAvailability(
    input: AvailabilityInput
  ): Promise<PingAvailabilityMutation> {
    const statement = `mutation PingAvailability($input: AvailabilityInput!) {
        pingAvailability(input: $input) {
          __typename
          success
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PingAvailabilityMutation>response.data.pingAvailability;
  }
  async SavePatientProfile(
    accessId: string,
    data: PatientProfileInput
  ): Promise<SavePatientProfileMutation> {
    const statement = `mutation SavePatientProfile($accessId: ID!, $data: PatientProfileInput!) {
        savePatientProfile(accessId: $accessId, data: $data) {
          __typename
          firstName
          lastName
          dateOfBirth
          gender
          medicalConditions {
            __typename
            allergies
            medicationAllergies
            hasGlasses
            isSmoking
            hasKids
            hasDiabetes
            surgeries
            preConditions
          }
          socialSecurityNumber
          address {
            __typename
            street
            address
            postCode
            city
            country {
              __typename
              name
              code
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      accessId,
      data
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SavePatientProfileMutation>response.data.savePatientProfile;
  }
  async CreateDoctorFileUploadUrl(): Promise<string | null> {
    const statement = `mutation CreateDoctorFileUploadUrl {
        createDoctorFileUploadUrl
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <string | null>response.data.createDoctorFileUploadUrl;
  }
  async DeleteDoctorFile(
    doctorFileId?: string
  ): Promise<DeleteDoctorFileMutation> {
    const statement = `mutation DeleteDoctorFile($doctorFileId: String) {
        deleteDoctorFile(doctorFileId: $doctorFileId) {
          __typename
          success
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (doctorFileId) {
      gqlAPIServiceArguments.doctorFileId = doctorFileId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDoctorFileMutation>response.data.deleteDoctorFile;
  }
  async SetSpecialistProduct(
    product: SpecialistProductInput
  ): Promise<SetSpecialistProductMutation> {
    const statement = `mutation SetSpecialistProduct($product: SpecialistProductInput!) {
        setSpecialistProduct(product: $product) {
          __typename
          productId
          price {
            __typename
            total
            currency
            valueAddedTaxPercent
            base
          }
          billingName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      product
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SetSpecialistProductMutation>response.data.setSpecialistProduct;
  }
  async ConfirmAppointment(
    appointmentId: string
  ): Promise<ConfirmAppointmentMutation> {
    const statement = `mutation ConfirmAppointment($appointmentId: ID!) {
        confirmAppointment(appointmentId: $appointmentId) {
          __typename
          appointmentId
          from
          to
          requestedAt
          doctorId
          appointmentStatus
          cancellation {
            __typename
            cancelledAt
            cancelledBy
            reason
          }
          note
          patient {
            __typename
            firstName
            lastName
          }
          attachments {
            __typename
            fileAttachmentId
            fileName
            fileDateTime
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      appointmentId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfirmAppointmentMutation>response.data.confirmAppointment;
  }
  async CancelAppointment(
    appointmentId: string,
    info?: AppointmentCancellationInfoInput
  ): Promise<CancelAppointmentMutation> {
    const statement = `mutation CancelAppointment($appointmentId: ID!, $info: AppointmentCancellationInfoInput) {
        cancelAppointment(appointmentId: $appointmentId, info: $info) {
          __typename
          appointmentId
          from
          to
          requestedAt
          doctorId
          appointmentStatus
          cancellation {
            __typename
            cancelledAt
            cancelledBy
            reason
          }
          note
          patient {
            __typename
            firstName
            lastName
          }
          attachments {
            __typename
            fileAttachmentId
            fileName
            fileDateTime
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      appointmentId
    };
    if (info) {
      gqlAPIServiceArguments.info = info;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CancelAppointmentMutation>response.data.cancelAppointment;
  }
  async JoinAppointmentMeeting(
    appointmentId: string
  ): Promise<JoinAppointmentMeetingMutation> {
    const statement = `mutation JoinAppointmentMeeting($appointmentId: ID!) {
        joinAppointmentMeeting(appointmentId: $appointmentId) {
          __typename
          accessId
          meetingData {
            __typename
            Attendees {
              __typename
              AttendeeId
              ExternalUserId
              JoinToken
            }
            Meeting {
              __typename
              MeetingId
              MediaRegion
              ExternalMeetingId
              MediaPlacement {
                __typename
                AudioFallbackUrl
                AudioHostUrl
                ScreenDataUrl
                ScreenSharingUrl
                ScreenViewingUrl
                SignalingUrl
                TurnControlUrl
              }
            }
            Errors {
              __typename
              ExternalUserId
              ErrorCode
              ErrorMessage
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      appointmentId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <JoinAppointmentMeetingMutation>response.data.joinAppointmentMeeting;
  }
  async NotifyAppointmentUpdated(
    appointment: NotifyAppointmentUpdatedInput
  ): Promise<NotifyAppointmentUpdatedMutation> {
    const statement = `mutation NotifyAppointmentUpdated($appointment: NotifyAppointmentUpdatedInput!) {
        notifyAppointmentUpdated(appointment: $appointment) {
          __typename
          appointmentId
          from
          to
          requestedAt
          doctorId
          appointmentStatus
          cancellation {
            __typename
            cancelledAt
            cancelledBy
            reason
          }
          note
          patient {
            __typename
            firstName
            lastName
          }
          attachments {
            __typename
            fileAttachmentId
            fileName
            fileDateTime
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      appointment
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <NotifyAppointmentUpdatedMutation>(
      response.data.notifyAppointmentUpdated
    );
  }
  async CreateDoctorProfilePictureUploadUrl(): Promise<string | null> {
    const statement = `mutation CreateDoctorProfilePictureUploadUrl {
        createDoctorProfilePictureUploadUrl
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <string | null>response.data.createDoctorProfilePictureUploadUrl;
  }
  async DeleteDoctorProfilePicture(): Promise<
    DeleteDoctorProfilePictureMutation
  > {
    const statement = `mutation DeleteDoctorProfilePicture {
        deleteDoctorProfilePicture {
          __typename
          success
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <DeleteDoctorProfilePictureMutation>(
      response.data.deleteDoctorProfilePicture
    );
  }
  async PatientMedicalFile(accessId: string): Promise<PatientMedicalFileQuery> {
    const statement = `query PatientMedicalFile($accessId: ID!) {
        patientMedicalFile(accessId: $accessId) {
          __typename
          accessId
          patientId
          personalData {
            __typename
            firstName
            lastName
            dateOfBirth
            gender
            medicalConditions {
              __typename
              allergies
              medicationAllergies
              hasGlasses
              isSmoking
              hasKids
              hasDiabetes
              surgeries
              preConditions
            }
            socialSecurityNumber
            address {
              __typename
              street
              address
              postCode
              city
              country {
                __typename
                name
                code
              }
            }
          }
          visits {
            __typename
            visitId
            visitDateTime
            clinicalReport {
              __typename
              anamnese
              diagnosis
              treatment
            }
            prescriptions {
              __typename
              visitId
              prescriptionId
              prescriptionName
              prescriptionDateTime
            }
          }
          patientFiles {
            __typename
            fileAttachmentId
            fileName
            fileDateTime
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      accessId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PatientMedicalFileQuery>response.data.patientMedicalFile;
  }
  async PatientList(
    filter?: PatientFilter,
    page?: PageInput
  ): Promise<PatientListQuery> {
    const statement = `query PatientList($filter: PatientFilter, $page: PageInput) {
        patientList(filter: $filter, page: $page) {
          __typename
          results {
            __typename
            accessId
            patientId
            personal {
              __typename
              firstName
              lastName
              dateOfBirth
              gender
              medicalConditions {
                __typename
                allergies
                medicationAllergies
                hasGlasses
                isSmoking
                hasKids
                hasDiabetes
                surgeries
                preConditions
              }
              socialSecurityNumber
              address {
                __typename
                street
                address
                postCode
                city
                country {
                  __typename
                  name
                  code
                }
              }
            }
            regionCode
            partnerName
          }
          pageInfo {
            __typename
            nextCursor
            previousCursor
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (page) {
      gqlAPIServiceArguments.page = page;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PatientListQuery>response.data.patientList;
  }
  async PatientFileAttachmentDownloadUrl(
    input: PatientFileAttachmentDownloadUrlInput
  ): Promise<PatientFileAttachmentDownloadUrlQuery> {
    const statement = `query PatientFileAttachmentDownloadUrl($input: PatientFileAttachmentDownloadUrlInput!) {
        patientFileAttachmentDownloadUrl(input: $input) {
          __typename
          url
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PatientFileAttachmentDownloadUrlQuery>(
      response.data.patientFileAttachmentDownloadUrl
    );
  }
  async AppointmentAttachmentDownloadUrl(
    input: AppointmentAttachmentDownloadUrlInput
  ): Promise<AppointmentAttachmentDownloadUrlQuery> {
    const statement = `query AppointmentAttachmentDownloadUrl($input: AppointmentAttachmentDownloadUrlInput!) {
        appointmentAttachmentDownloadUrl(input: $input) {
          __typename
          url
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AppointmentAttachmentDownloadUrlQuery>(
      response.data.appointmentAttachmentDownloadUrl
    );
  }
  async DoctorProfile(): Promise<DoctorProfileQuery> {
    const statement = `query DoctorProfile {
        doctorProfile {
          __typename
          doctorId
          firstName
          lastName
          doctorIdentifierNumber
          doctorStampCode
          isActive
          activationRequested
          isAvailable
          address {
            __typename
            street
            address
            postCode
            city
            country {
              __typename
              name
              code
            }
          }
          phoneNumber
          biography
          expertise
          region {
            __typename
            name
            code
          }
          medicalSpecialist
          languages
          medicalSpecialties
          medicalSubspecialties
          hoursOfAvailability {
            __typename
            weekday
            from
            to
            timezone
          }
          imageUrl
          clinicId
          clinic {
            __typename
            id
            name
            address
            logoUrl
            description
            appointmentPrice {
              __typename
              total
              currency
              valueAddedTaxPercent
              base
            }
            regionCode
          }
          appLanguage
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <DoctorProfileQuery>response.data.doctorProfile;
  }
  async GetDoctorFileDownloadUrl(
    doctorFileId: string
  ): Promise<GetDoctorFileDownloadUrlQuery> {
    const statement = `query GetDoctorFileDownloadUrl($doctorFileId: ID!) {
        getDoctorFileDownloadUrl(doctorFileId: $doctorFileId) {
          __typename
          url
        }
      }`;
    const gqlAPIServiceArguments: any = {
      doctorFileId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDoctorFileDownloadUrlQuery>(
      response.data.getDoctorFileDownloadUrl
    );
  }
  async GetDoctorFiles(): Promise<Array<GetDoctorFilesQuery>> {
    const statement = `query GetDoctorFiles {
        getDoctorFiles {
          __typename
          fileId
          fileName
          fileUploadDateTime
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<GetDoctorFilesQuery>>response.data.getDoctorFiles;
  }
  async TeledocRegions(): Promise<Array<TeledocRegionsQuery>> {
    const statement = `query TeledocRegions {
        teledocRegions {
          __typename
          code
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<TeledocRegionsQuery>>response.data.teledocRegions;
  }
  async MedicalSpecialties(): Promise<MedicalSpecialtiesQuery> {
    const statement = `query MedicalSpecialties {
        medicalSpecialties {
          __typename
          medicalSpecialties
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <MedicalSpecialtiesQuery>response.data.medicalSpecialties;
  }
  async SpecialistProduct(): Promise<SpecialistProductQuery> {
    const statement = `query SpecialistProduct {
        specialistProduct {
          __typename
          productId
          price {
            __typename
            total
            currency
            valueAddedTaxPercent
            base
          }
          billingName
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <SpecialistProductQuery>response.data.specialistProduct;
  }
  async DoctorLanguages(): Promise<DoctorLanguagesQuery> {
    const statement = `query DoctorLanguages {
        doctorLanguages {
          __typename
          results
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <DoctorLanguagesQuery>response.data.doctorLanguages;
  }
  async Appointments(
    filter?: AppointmentsFilter,
    page?: PageInput
  ): Promise<AppointmentsQuery> {
    const statement = `query Appointments($filter: AppointmentsFilter, $page: PageInput) {
        appointments(filter: $filter, page: $page) {
          __typename
          results {
            __typename
            appointmentId
            from
            to
            requestedAt
            doctorId
            appointmentStatus
            cancellation {
              __typename
              cancelledAt
              cancelledBy
              reason
            }
            note
            patient {
              __typename
              firstName
              lastName
            }
            attachments {
              __typename
              fileAttachmentId
              fileName
              fileDateTime
            }
          }
          pageInfo {
            __typename
            nextCursor
            previousCursor
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (page) {
      gqlAPIServiceArguments.page = page;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AppointmentsQuery>response.data.appointments;
  }
  async Appointment(appointmentId: string): Promise<AppointmentQuery> {
    const statement = `query Appointment($appointmentId: ID!) {
        appointment(appointmentId: $appointmentId) {
          __typename
          appointmentId
          from
          to
          requestedAt
          doctorId
          appointmentStatus
          cancellation {
            __typename
            cancelledAt
            cancelledBy
            reason
          }
          note
          patient {
            __typename
            firstName
            lastName
          }
          attachments {
            __typename
            fileAttachmentId
            fileName
            fileDateTime
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      appointmentId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AppointmentQuery>response.data.appointment;
  }
  async Clinics(): Promise<ClinicsQuery> {
    const statement = `query Clinics {
        clinics {
          __typename
          entries {
            __typename
            id
            name
            address
            logoUrl
            description
            appointmentPrice {
              __typename
              total
              currency
              valueAddedTaxPercent
              base
            }
            regionCode
          }
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <ClinicsQuery>response.data.clinics;
  }
  async GetPrescriptionDownloadUrl(
    visitId: string,
    prescriptionID: string
  ): Promise<GetPrescriptionDownloadUrlQuery> {
    const statement = `query GetPrescriptionDownloadUrl($visitId: ID!, $prescriptionID: ID!) {
        getPrescriptionDownloadUrl(visitId: $visitId, prescriptionID: $prescriptionID) {
          __typename
          url
        }
      }`;
    const gqlAPIServiceArguments: any = {
      visitId,
      prescriptionID
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPrescriptionDownloadUrlQuery>(
      response.data.getPrescriptionDownloadUrl
    );
  }
  OnIncomingMeetingRequestListener(
    regionCode: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onIncomingMeetingRequest">
    >
  > {
    const statement = `subscription OnIncomingMeetingRequest($regionCode: String!) {
        onIncomingMeetingRequest(regionCode: $regionCode) {
          __typename
          regionCode
        }
      }`;
    const gqlAPIServiceArguments: any = {
      regionCode
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onIncomingMeetingRequest">
      >
    >;
  }

  OnAppointmentUpdatedListener(
    doctorId: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onAppointmentUpdated">>
  > {
    const statement = `subscription OnAppointmentUpdated($doctorId: ID!) {
        onAppointmentUpdated(doctorId: $doctorId) {
          __typename
          appointmentId
          from
          to
          requestedAt
          doctorId
          appointmentStatus
          cancellation {
            __typename
            cancelledAt
            cancelledBy
            reason
          }
          note
          patient {
            __typename
            firstName
            lastName
          }
          attachments {
            __typename
            fileAttachmentId
            fileName
            fileDateTime
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      doctorId
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onAppointmentUpdated">
      >
    >;
  }
}
