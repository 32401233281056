import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { PrescriptionInput } from 'src/app/API.service';
import { AuthLanguageSelectionService } from 'src/app/auth/auth-language-selection/auth-language-selection.service';
import { ProfileService } from 'src/app/profile/profile.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { RegionService } from 'src/app/shared/region/region.service';
import { PatientsService } from '../patients.service';

@Component({
  selector: 'tdoc-patient-prescription',
  templateUrl: './patient-prescription.component.html',
  styleUrls: ['./patient-prescription.component.scss'],
})
export class PatientPrescriptionComponent implements OnInit {
  translations$: Observable<any>;
  translations: any;
  formGroup = new UntypedFormGroup({});
  model: PrescriptionInput = this.createPrescriptionInput({}, {});
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];

  constructor(
    public dialogRef: MatDialogRef<PatientPrescriptionComponent>,
    private translate: TranslateService,
    private patientService: PatientsService,
    private notification: NotificationService,
    private profileService: ProfileService,
    private authLanguageSelectionService: AuthLanguageSelectionService,
    private regionService: RegionService,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.initPrescriptionData();
  }

  onConfirm(): void {
    const formattedDate = formatDate(
      this.model.patientPersonalData.dateOfBirth,
      'yyyy-MM-dd',
      'en',
    );
    this.model.patientPersonalData.dateOfBirth = formattedDate;
    this.patientService
      .createPrescription(this.model)
      .pipe(
        take(1),
        catchError((error) => {
          const errorMsg = error.errors[0].message;
          console.log(errorMsg);
          this.notification.show(errorMsg);
          return of(error);
        }),
        tap((prescription) => {
          if (prescription.success) {
            this.dialogRef.close();
          }
          if (!prescription.success) {
            let messages = prescription.errorMessage;
            if (typeof prescription.errorMessage !== 'string') {
              messages = JSON.parse(prescription.errorMessage);
              messages.forEach((message) =>
                this.notification.show(this.translations[message]),
              );
            } else {
              this.notification.show(messages);
            }
          }
        }),
      )
      .subscribe();
  }

  private initTranslations(): void {
    this.translations$ = this.translate.get('PATIENTS.PRESCRIPTION').pipe(
      tap((translations) => {
        this.translations = translations;
        this.setupFormFields();
      }),
    );
  }

  private initPrescriptionData(): void {
    const doctorProfile$ = this.profileService.doctorProfile$;

    const medicalFile$ = this.patientService.patientMedicalFile$;

    combineLatest([doctorProfile$, medicalFile$])
      .pipe(
        tap(([doctorProfile, medicalFile]) => {
          this.model = this.createPrescriptionInput(medicalFile, doctorProfile);
        }),
      )
      .subscribe();
  }

  private setupFormFields(): void {
    this.fields = [
      {
        fieldGroupClassName: 'form-group display-flex',
        fieldGroup: [
          {
            className: 'flex-3',
            key: 'patientPersonalData.firstName',
            type: 'input',
            templateOptions: {
              label: this.translations.FIRSTNAME,
              required: true,
            },
          },
          {
            className: 'flex-3',
            key: 'patientPersonalData.lastName',
            type: 'input',
            templateOptions: {
              label: this.translations.LASTNAME,
              required: true,
            },
          },
          {
            className: 'flex-1',
            key: 'patientPersonalData.dateOfBirth',
            type: 'datepicker',
            templateOptions: {
              label: this.translations.DATEOFBIRTH,
              required: true,
              datepickerOptions: {
                max: formatDate(Date.now(), 'yyyy-MM-dd', 'en'),
              },
            },
          },
        ],
      },
      {
        key: 'patientPersonalData.socialSecurityNumber',
        type: 'input',
        templateOptions: {
          label: this.translations.SOCIAL,
        },
      },
      {
        key: 'patientPersonalData.address.street',
        type: 'input',
        templateOptions: {
          label: this.translations.STREET,
        },
      },
      {
        fieldGroupClassName: 'form-group display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'patientPersonalData.address.postCode',
            type: 'input',
            templateOptions: {
              label: this.translations.POSTCODE,
            },
          },
          {
            className: 'flex-2',
            key: 'patientPersonalData.address.city',
            type: 'input',
            templateOptions: {
              label: this.translations.CITY,
            },
          },
        ],
      },
      {
        key: 'patientPersonalData.address.country.code',
        type: 'select',
        templateOptions: {
          label: this.translations.COUNTRY,
          options: this.regionService.getRegions(),
        },
      },
      {
        key: 'diagnosis',
        type: 'textarea',
        templateOptions: {
          label: this.translations.DIAGNOSIS,
          rows: 3,
          resize: 'none',
          required: true,
        },
      },
      {
        key: 'drug',
        type: 'textarea',
        templateOptions: {
          label: this.translations.DRUG,
          rows: 4,
          resize: 'none',
          required: true,
        },
      },
      {
        key: 'doctorStampCode',
        type: 'input',
        templateOptions: {
          label: this.translations.STAMPCODE,
        },
      },
    ];
  }

  private createPrescriptionInput(
    medicalFile: any,
    doctorProfile: any,
  ): PrescriptionInput {
    const prescriptionInput = {
      accessId: medicalFile.accessId,
      diagnosis: medicalFile?.diagnosis || '',
      drug: medicalFile?.drug || '',
      doctorIdentifierNumber: doctorProfile.doctorIdentifierNumber || '',
      doctorStampCode: doctorProfile.doctorStampCode || '',
      doctorName:
        `${doctorProfile?.firstName} ${doctorProfile?.lastName}` || 'missing',
      doctorAddress:
        {
          city: doctorProfile?.address?.city || '',
          address: doctorProfile?.address?.address || '',
          postCode: doctorProfile?.address?.postCode || '',
          street: doctorProfile?.address?.street || '',
          country:
            {
              code: doctorProfile?.address?.country?.code || '',
              name: doctorProfile?.address?.country?.name || '',
            } || null,
        } || null,
      language: this.authLanguageSelectionService.selectedLanguage,
      patientPersonalData:
        {
          dateOfBirth: medicalFile?.personalData?.dateOfBirth || '',
          firstName: medicalFile?.personalData?.firstName || '',
          lastName: medicalFile?.personalData?.lastName || '',
          socialSecurityNumber:
            medicalFile?.personalData?.socialSecurityNumber || '',
          address:
            {
              city: medicalFile?.personalData?.address?.city || '',
              address: medicalFile?.personalData?.address?.address || '',
              postCode: medicalFile?.personalData?.address?.postCode || '',
              street: medicalFile?.personalData?.address?.street || '',
              country:
                {
                  code: medicalFile?.personalData?.address?.country?.code || '',
                  name: medicalFile?.personalData?.address?.country?.name || '',
                } || null,
            } || null,
        } || null,
    };
    return prescriptionInput;
  }
}
