<ng-container *ngIf="translations$ | async">
  <div class="forgot">
    <tdoc-logo></tdoc-logo>
    <ng-container *ngIf="!hasRequested; else requested">
      <form [formGroup]="forgotForm" class="forgot-form">
        <h2>{{ 'AUTH.FORGOT_PASSWORD.HEADER' | translate }}</h2>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'AUTH.FORGOT_PASSWORD.EMAIL' | translate }}"
            type="email"
            formControlName="email"
          />
          <mat-hint *ngIf="!emailForgotInput.value">{{
            'AUTH.FORGOT_PASSWORD.EMAIL_HINT' | translate
          }}</mat-hint>
          <mat-error *ngIf="!emailForgotInput.valid">
            {{ getEmailInputError() }}
          </mat-error>
        </mat-form-field>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!forgotForm.valid"
          (click)="sendCode()"
        >
          {{ 'AUTH.FORGOT_PASSWORD.CONFIRM' | translate }}
        </button>
      </form>
    </ng-container>
    <ng-template #requested>
      <form [formGroup]="newPasswordForm" class="forgot-form">
        <h2>{{ 'AUTH.FORGOT_PASSWORD.HEADER' | translate }}</h2>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'AUTH.FORGOT_PASSWORD.EMAIL' | translate }}"
            type="email"
            formControlName="email"
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'AUTH.FORGOT_PASSWORD.CODE' | translate }}"
            type="text"
            formControlName="code"
            required
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'AUTH.FORGOT_PASSWORD.PASSWORD' | translate }}"
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            required
          />
          <mat-icon matSuffix (click)="hide = !hide">{{
            hide ? 'visibility_off' : 'visibility'
          }}</mat-icon>
          <mat-hint *ngIf="!passwordInput.value">{{
            'AUTH.FORGOT_PASSWORD.PASSWORD_HINT' | translate
          }}</mat-hint>
          <mat-error *ngIf="!passwordInput.valid">
            {{ getPasswordInputError() }}
          </mat-error>
        </mat-form-field>

        <button
          mat-raised-button
          color="primary"
          [disabled]="!newPasswordForm.valid"
          (click)="submitNewPassword()"
        >
          {{ 'AUTH.FORGOT_PASSWORD.CONFIRM' | translate }}
        </button>
      </form>
    </ng-template>
    <div class="container">
      <tdoc-auth-language-selection></tdoc-auth-language-selection>
    </div>
  </div>
</ng-container>
<tdoc-footer></tdoc-footer>
