import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { ActionDialogModule } from 'src/app/shared/action-dialog/action-dialog.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { TdocFormlyModule } from 'src/app/shared/tdoc-formly/tdoc-formly.module';
import { CallControlsComponent } from './call-controls.component';
import { SettingsDialogModule } from './settings-dialog/settings-dialog.module';

@NgModule({
  declarations: [CallControlsComponent],
  imports: [
    CommonModule,
    TdocFormlyModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forChild(),
    TranslateModule,
    SettingsDialogModule,
    ActionDialogModule,
  ],
  exports: [CallControlsComponent],
})
export class CallControlsModule {}
