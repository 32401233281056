import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { CognitoUser } from '@aws-amplify/auth';
import { AppRoutes } from 'src/app/shared/routes';
import { TranslateService } from '@ngx-translate/core';
import { take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import packageInfo from '../../../../package.json';
import { ProfileService } from '../../profile/profile.service';

@Component({
  selector: 'tdoc-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  public version: string = packageInfo.version;
  signinForm: UntypedFormGroup;
  translations: any;
  translations$: Observable<any>;

  hide = true;

  constructor(
    public auth: AuthService,
    private translate: TranslateService,
    private notification: NotificationService,
    private router: Router,
    private profileService: ProfileService,
  ) {
    this.signinForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.email,
        Validators.required,
      ]),
      password: new UntypedFormControl('', [Validators.required]),
    });
    this.translations$ = this.translate.get('AUTH.SIGN_IN').pipe(
      tap((translations) => {
        this.translations = translations;
      }),
    );
  }

  get emailInput() {
    return this.signinForm.get('email');
  }
  get passwordInput() {
    return this.signinForm.get('password');
  }

  getEmailInputError() {
    if (this.emailInput.hasError('email')) {
      return this.translations['EMAIL_INVALID'];
    }
    if (this.emailInput.hasError('required')) {
      return this.translations['EMAIL_REQUIRED'];
    }
  }

  getPasswordInputError() {
    if (this.passwordInput.hasError('required')) {
      return this.translations['PASSWORD_REQUIRED'];
    }
  }

  signIn() {
    this.auth
      .signIn(this.emailInput.value, this.passwordInput.value)
      .then((value) => {
        if (
          value.challengeName &&
          value.challengeName === 'NEW_PASSWORD_REQUIRED'
        ) {
          this.router.navigate([AppRoutes.new_password]);
          return null;
        } else {
          this.profileService.getProfile();
        }
      })
      .then((user: CognitoUser | any) => {
        if (user !== null) {
          let currentLang = this.translate.currentLang;
          this.profileService.doctorProfile$
            .pipe(take(1))
            .subscribe((value) => {
              this.profileService.updateProfile({
                ...value,
                appLanguage: currentLang,
              });
            });
          this.router.navigate(['']);
        }
      })
      .catch((error: any) => {
        this.notification.show(error.message);
        switch (error.code) {
          case 'UserNotConfirmedException':
            environment.confirm.email = this.emailInput.value;
            environment.confirm.password = this.passwordInput.value;
            this.router.navigate([AppRoutes.confirm]);
            break;
          case 'PasswordResetRequiresException':
            this.router.navigate([AppRoutes.new_password]);
            break;
          case 'UsernameExistsException':
            this.router.navigate([AppRoutes.signin]);
            break;
        }
      });
  }
}
