import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take, tap } from 'rxjs/operators';
import {
  ActionDialogComponent,
  DialogActions,
} from 'src/app/shared/action-dialog/action-dialog.component';
import { ChimeService } from 'src/app/shared/chime/chime.service';
import { DevicesService } from 'src/app/shared/chime/devices.service';
import { FullDeviceInfo } from 'src/app/shared/types/full-device-info';
import { SettingsDialogComponent } from './settings-dialog/settings-dialog.component';
import { DoctorStatusService } from '../../../shared/doctor-status/doctor-status.service';

@Component({
  selector: 'tdoc-call-controls',
  templateUrl: './call-controls.component.html',
  styleUrls: ['./call-controls.component.scss'],
})
export class CallControlsComponent implements OnInit {
  public muted = false;
  public hasVideo = true;

  constructor(
    private chimeService: ChimeService,
    private deviceService: DevicesService,
    private doctorStatusService: DoctorStatusService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.deviceService.listAndSelectDevices();
  }

  onVideo(): void {
    const hasVideo =
      this.chimeService.meetingSession.audioVideo.hasStartedLocalVideoTile();
    if (hasVideo) {
      this.chimeService.meetingSession.audioVideo.stopLocalVideoTile();
      this.hasVideo = false;
    } else {
      this.deviceService.deviceList$
        .pipe(take(1))
        .subscribe((devices: FullDeviceInfo) => {
          this.deviceService
            .chooseVideoInputDevice(devices.currentVideoInputDevice)
            .then(() => {
              this.chimeService.meetingSession.audioVideo.startLocalVideoTile();
              this.hasVideo = true;
            });
        });
    }
  }

  onAudio(): void {
    const muted =
      this.chimeService.meetingSession.audioVideo.realtimeIsLocalAudioMuted();
    if (this.muted) {
      this.chimeService.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
    } else {
      this.chimeService.meetingSession.audioVideo.realtimeMuteLocalAudio();
    }
    this.muted = !muted;
  }

  onSettings(): void {
    this.dialog.open(SettingsDialogComponent, {
      width: '500px',
    });
  }

  onEnd(): void {
    const dialog = this.dialog.open(ActionDialogComponent, {
      data: { title: 'PATIENTS.CONTROLS.END_CALL_HEADER' },
      width: '500px',
    });

    dialog
      .afterClosed()
      .pipe(
        tap((result) => {
          if (result === DialogActions.confirm) {
            this.chimeService.stop();
          }
        }),
      )
      .subscribe();
  }
}
