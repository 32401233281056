import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { TdocFormlyModule } from '../tdoc-formly/tdoc-formly.module';
import { AddAvailabilityDialogComponent } from './add-availability-dialog/add-availability-dialog.component';
import { HoursOfAvailabilityComponent } from './hours-of-availability.component';

@NgModule({
  declarations: [HoursOfAvailabilityComponent, AddAvailabilityDialogComponent],
  imports: [
    CommonModule,
    TdocFormlyModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forChild(),
    TranslateModule,
  ],
  exports: [HoursOfAvailabilityComponent],
})
export class HoursOfAvailabilityModule {}
