import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AppLanguage,
  AuthLanguageSelectionService,
} from './auth/auth-language-selection/auth-language-selection.service';
import { ProfileService } from './profile/profile.service';
import { MatDialog } from '@angular/material/dialog';
import { CookieBannerComponent } from './shared/cookie-banner/cookie-banner.component';
import { AmplitudeTrackingService } from './tracking/amplitude.service';
import { NotificationService } from './shared/notification/notification.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'tdoc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public browserName = '';
  public browserVersion = '';
  public unsupportedBrowser: boolean = false;
  translations$: Observable<any>;
  translations: any;

  selectedLanguage: AppLanguage;
  private supportedAppLanguages: AppLanguage[] = [
    'al',
    'bg',
    'de',
    'en',
    'hr',
    'hu',
    'mk',
    'ro',
    'ru',
    'sk',
    'uk',
    'es',
    'fr',
    'it',
  ];
  private defaultLanguage: AppLanguage = 'en';

  constructor(
    private translate: TranslateService,
    private profileService: ProfileService,
    private authLanguageSeceltion: AuthLanguageSelectionService,
    private dialog: MatDialog,
    private track: AmplitudeTrackingService,
    private notification: NotificationService,
  ) {}

  openCookie(): void {
    this.dialog.open(CookieBannerComponent, {
      height: '600px',
      width: '600px',
    });
  }

  ngOnInit(): void {
    this.browserName = this.getBrowserName();
    this.browserVersion = this.getBrowserVersion();
    this.authLanguageSeceltion.initLanguages();
    if (this.browserName !== 'chrome' && this.browserName !== 'firefox') {
      this.unsupportedBrowser = true;
      this.translate
        .get('BROWSER.WRONG.VERSION')
        .subscribe((successMessage: string) => {
          this.notification.show(successMessage);
        });
    }
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.toLowerCase().indexOf('edg') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  getBrowserVersion() {
    const userAgent: any = navigator.userAgent;
    let tempMatch: any;
    let versionMatch: any =
      userAgent.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
      ) || [];
    if (/trident/i.test(versionMatch[1])) {
      tempMatch = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tempMatch[1] || '');
    }
    if (versionMatch[1] === 'Chrome') {
      tempMatch = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tempMatch != null) {
        return tempMatch.slice(1).join(' ').replace('OPR', 'Opera');
      }
    }
    versionMatch = versionMatch[2]
      ? [versionMatch[1], versionMatch[2]]
      : [navigator.appName, navigator.appVersion, '-?'];
    if ((tempMatch = userAgent.match(/version\/(\d+)/i)) != null)
      versionMatch.splice(1, 1, tempMatch[1]);
    return versionMatch;
  }
}
