import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, takeWhile, tap } from 'rxjs/operators';
import { Appointment } from 'src/app/API.service';
import { AppointmentsService } from 'src/app/appointments/appointments.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ProfileService } from 'src/app/profile/profile.service';
import { DoctorStatusService } from 'src/app/shared/doctor-status/doctor-status.service';
import { IncomingMeetingRequestService } from 'src/app/shared/incoming-meeting-request-dialog/incoming-meeting-request.service';
import { AppRoutes } from 'src/app/shared/routes';
import { DevicesService } from '../../shared/chime/devices.service';
import {
  ActionDialogComponent,
  DialogActions,
} from '../../shared/action-dialog/action-dialog.component';
import { SettingsDialogComponent } from '../../patients/patient-call/call-controls/settings-dialog/settings-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentRequestDialogService } from 'src/app/shared/appointment-request-dialog/appointment-request.service';

@Component({
  selector: 'tdoc-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  private devicesChecked = new BehaviorSubject<boolean>(false);
  translations$: Observable<any>;
  translations: any;
  destroy$ = new Subject<void>();

  AppRoutes = AppRoutes;
  title: string;
  pendingAppointments: number;

  constructor(
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private incomingMeetingRequest: IncomingMeetingRequestService,
    private doctorStatus: DoctorStatusService,
    private profileService: ProfileService,
    private appointmentService: AppointmentsService,
    private deviceService: DevicesService,
    private dialog: MatDialog,
    private appointmentDialogService: AppointmentRequestDialogService,
  ) {
    this.appointmentService.pendingAppointments$
      .pipe(
        tap((appointments: Appointment[]) => {
          this.pendingAppointments = appointments.length;
        }),
      )
      .subscribe();

    this.incomingMeetingRequest.meetingRequestDialog$
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.appointmentDialogService.appointmentRequestDialog$
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  ngOnInit(): void {
    this.initTranslations();
    this.listenToLangChange();
    this.checkForHeaderTitleUpdates();
    this.deviceService.deviceList$
      .pipe(takeWhile(() => !this.devicesChecked.value))
      .subscribe(async (next) => {
        if (next) {
          this.devicesChecked.next(true);
          if (next.currentAudioInputDevice && next.currentVideoInputDevice) {
            //TODO put this dialog into separate component
            console.log('devices are set');
            // camera and mic are set
            this.doctorStatus.setAvailable();
          } else {
            // no camera or mic
            const dialog = this.dialog.open(ActionDialogComponent, {
              data: {
                title: 'DEVICE_CHECK.TITLE',
                message: 'DEVICE_CHECK.MESSAGE',
                cancelTitle: 'DEVICE_CHECK.CANCEL',
                okTitle: 'DEVICE_CHECK.OK',
              },
              width: '500px',
            });

            dialog.afterClosed().subscribe((result) => {
              if (result === DialogActions.confirm) {
                this.dialog.open(SettingsDialogComponent, {
                  width: '500px',
                });
              }

              this.doctorStatus.setUnavailable();
            });
          }
        }
      });
    this.profileService.getProfile();
  }

  open(route: AppRoutes) {
    if (route === AppRoutes.profile) {
      this.title = this.translations['PROFILE.HEADER'];
    } else if (route === AppRoutes.patients) {
      this.title = this.translations['PATIENTS.HEADER'];
    } else if (route === AppRoutes.settings) {
      this.title = this.translations['SETTING.HEADER'];
    } else if (route === this.translations.APPOINTMENTS) {
      this.title = this.translations['APPOINTMENTS.HEADER'];
    } else {
      this.title = this.translations['DASHBOARD.HEADER'];
    }
    this.router.navigate([route]);
  }

  onSignOut() {
    this.authService
      .signOut()
      .then(() => this.router.navigate([AppRoutes.signin]));
  }

  private initHeaderTitle() {
    if (this.router.url.includes(AppRoutes.profile)) {
      this.title = this.translations['PROFILE.HEADER'];
    } else if (this.router.url.includes(AppRoutes.patients)) {
      this.title = this.translations['PATIENTS.HEADER'];
    } else if (this.router.url.includes(AppRoutes.settings)) {
      this.title = this.translations['SETTING.HEADER'];
    } else if (this.router.url.includes(AppRoutes.appointments)) {
      this.title = this.translations['APPOINTMENTS.HEADER'];
    } else {
      this.title = this.translations['DASHBOARD.HEADER'];
    }
  }

  private initTranslations() {
    this.translations$ = this.translate.get('NAVIGATION').pipe(
      tap((translations) => {
        this.translations = translations;
        this.initHeaderTitle();
      }),
    );
  }

  private listenToLangChange() {
    this.translate.onLangChange
      .pipe(
        tap(() => {
          this.initTranslations();
        }),
      )
      .subscribe();
  }

  private checkForHeaderTitleUpdates() {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        tap((event: Event) => {
          if (event instanceof NavigationEnd) {
            this.initHeaderTitle();
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
