import { Injectable } from '@angular/core';
import {
  BackgroundBlurVideoFrameProcessor,
  BackgroundFilterPaths,
  BackgroundFilterSpec,
  ConsoleLogger,
  DefaultDeviceController,
  DefaultVideoTransformDevice,
  LogLevel,
  ModelSpecBuilder,
} from 'amazon-chime-sdk-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeviceType } from '../types/device-type';
import { FullDeviceInfo } from '../types/full-device-info';

const search = new URLSearchParams(document.location.search);

const BACKGROUND_BLUR_CDN = search.get('blurCDN') || undefined;
const BACKGROUND_BLUR_ASSET_GROUP = search.get('blurAssetGroup') || undefined;
const BACKGROUND_BLUR_REVISION_ID = search.get('blurRevisionID') || undefined;

const BACKGROUND_BLUR_PATHS: BackgroundFilterPaths = BACKGROUND_BLUR_CDN && {
  worker: `${BACKGROUND_BLUR_CDN}/bgblur/workers/worker.js`,
  wasm: `${BACKGROUND_BLUR_CDN}/bgblur/wasm/_cwt-wasm.wasm`,
  simd: `${BACKGROUND_BLUR_CDN}/bgblur/wasm/_cwt-wasm-simd.wasm`,
};
const BACKGROUND_BLUR_MODEL =
  BACKGROUND_BLUR_CDN &&
  ModelSpecBuilder.builder()
    .withSelfieSegmentationDefaults()
    .withPath(
      `${BACKGROUND_BLUR_CDN}/bgblur/models/selfie_segmentation_landscape.tflite`,
    )
    .build();
const BACKGROUND_BLUR_ASSET_SPEC = (BACKGROUND_BLUR_ASSET_GROUP ||
  BACKGROUND_BLUR_REVISION_ID) && {
  assetGroup: BACKGROUND_BLUR_ASSET_GROUP,
  revisionID: BACKGROUND_BLUR_REVISION_ID,
};

@Injectable({
  providedIn: 'root',
})
export class DevicesService {
  private deviceList = new BehaviorSubject<FullDeviceInfo>(null);

  private blur = false;

  private blurIntensity = 30;

  public readonly deviceList$: Observable<FullDeviceInfo> =
    this.deviceList.asObservable();

  logger = new ConsoleLogger('MyLogger', LogLevel.INFO);
  deviceController = new DefaultDeviceController(this.logger);

  private currentAudioInputDevice: DeviceType | null = null;

  private currentAudioOutputDevice: DeviceType | null = null;

  private currentVideoInputDevice: DeviceType | null = null;

  private audioInputDevices: DeviceType[] = [];

  private audioOutputDevices: DeviceType[] = [];

  private videoInputDevices: DeviceType[] = [];

  constructor() {
    this.listAndSelectDevices();
  }

  async listAndSelectDevices(): Promise<void> {
    const audioInputs =
      (await this.deviceController?.listAudioInputDevices()) || [];
    const audioOutputs =
      (await this.deviceController?.listAudioOutputDevices()) || [];
    const videoInputs =
      (await this.deviceController?.listVideoInputDevices()) || [];

    this.updateDeviceLists(audioInputs, audioOutputs, videoInputs);

    if (
      !this.currentAudioInputDevice &&
      audioInputs.length &&
      audioInputs[0].deviceId
    ) {
      this.currentAudioInputDevice = {
        label: audioInputs[0].label,
        value: audioInputs[0].deviceId,
      };
      await this.deviceController?.startAudioInput(audioInputs[0].deviceId);
    }

    if (
      !this.currentAudioOutputDevice &&
      audioOutputs.length &&
      audioOutputs[0].deviceId
    ) {
      this.currentAudioOutputDevice = {
        label: audioOutputs[0].label,
        value: audioOutputs[0].deviceId,
      };
      await this.deviceController?.startAudioInput(audioOutputs[0].deviceId);
    }

    if (
      !this.currentVideoInputDevice &&
      videoInputs.length &&
      videoInputs[0].deviceId
    ) {
      this.currentVideoInputDevice = {
        label: videoInputs[0].label,
        value: videoInputs[0].deviceId,
      };

      const blurSupported = await BackgroundBlurVideoFrameProcessor.isSupported(
        this.getBackgroundBlurSpec(),
      );
      if (blurSupported) {
        const deviceWithBlur = await this.blurVideoInput(
          videoInputs[0].deviceId,
          5,
        );
        await this.deviceController?.startVideoInput(deviceWithBlur);
      } else {
        console.log('Blur is not supported, starting video with normal device');
        await this.deviceController?.startVideoInput(videoInputs[0].deviceId);
      }
    }

    this.publishDevicesUpdated();
  }

  public async blurVideoInput(deviceId: string, intensity: number) {
    console.log('Blur is supported, starting video with 30% blur');
    const blurObserver = {
      filterFrameDurationHigh: (event) => {
        console.log(
          `background filter duration high: framed dropped - ${event.framesDropped}, avg - ${event.avgFilterDurationMillis} ms, frame rate - ${event.framerate}, period - ${event.periodMillis} ms`,
        );
      },
      filterCPUUtilizationHigh: (event) => {
        console.log(
          `background filter CPU utilization high: ${event.cpuUtilization}%`,
        );
      },
    };

    const blurProcessor = await BackgroundBlurVideoFrameProcessor.create(
      this.getBackgroundBlurSpec(),
      { filterCPUUtilization: 30, blurStrength: intensity },
    );
    blurProcessor.addObserver(blurObserver);

    const deviceWithBlur = new DefaultVideoTransformDevice(
      new ConsoleLogger('SDK', LogLevel.INFO),
      deviceId,
      [blurProcessor],
    );
    return deviceWithBlur;
  }

  updateDeviceLists(
    audioInputs: MediaDeviceInfo[],
    audioOutputs: MediaDeviceInfo[],
    videoInputs: MediaDeviceInfo[],
  ): void {
    this.audioInputDevices = audioInputs.map(
      (mediaDeviceInfo: MediaDeviceInfo) => ({
        label: mediaDeviceInfo.label,
        value: mediaDeviceInfo.deviceId,
      }),
    );

    this.audioOutputDevices = audioOutputs.map(
      (mediaDeviceInfo: MediaDeviceInfo) => ({
        label: mediaDeviceInfo.label,
        value: mediaDeviceInfo.deviceId,
      }),
    );

    this.videoInputDevices = videoInputs.map(
      (mediaDeviceInfo: MediaDeviceInfo) => ({
        label: mediaDeviceInfo.label,
        value: mediaDeviceInfo.deviceId,
      }),
    );
  }

  async chooseAudioInputDevice(device: DeviceType) {
    try {
      await this.deviceController?.startAudioInput(device.value);
      this.currentAudioInputDevice = device;
      this.publishDevicesUpdated();
    } catch (error) {
      this.logError(error);
    }
  }

  async chooseAudioOutputDevice(device: DeviceType) {
    try {
      await this.deviceController?.chooseAudioOutput(device.value);
      this.currentAudioOutputDevice = device;
      this.publishDevicesUpdated();
    } catch (error) {
      this.logError(error);
    }
  }

  async chooseVideoInputDevice(device: DeviceType) {
    try {
      if (this.blur) {
        await this.deviceController?.startVideoInput(
          await this.blurVideoInput(device.value, this.blurIntensity),
        );
      } else {
        await this.deviceController?.startVideoInput(device.value);
      }
      this.currentVideoInputDevice = device;
      this.publishDevicesUpdated();
    } catch (error) {
      this.logError(error);
    }
  }

  private logError(error: Error) {
    console.error(error);
  }

  private publishDevicesUpdated() {
    this.deviceList.next({
      currentAudioInputDevice: this.currentAudioInputDevice,
      currentAudioOutputDevice: this.currentAudioOutputDevice,
      currentVideoInputDevice: this.currentVideoInputDevice,
      audioInputDevices: this.audioInputDevices,
      audioOutputDevices: this.audioOutputDevices,
      videoInputDevices: this.videoInputDevices,
    });
  }

  private getBackgroundBlurSpec(): BackgroundFilterSpec {
    return {
      paths: BACKGROUND_BLUR_PATHS,
      model: BACKGROUND_BLUR_MODEL,
      ...BACKGROUND_BLUR_ASSET_SPEC,
    };
  }

  public setBlur(blurToggle: boolean, blurIntensity: number) {
    this.blur = blurToggle;
    this.blurIntensity = blurIntensity;
  }
}
