<ng-container *ngIf="translations$ | async">
  <mat-card>
    <mat-card-content>
      <mat-card-title>
        {{ 'AVAILABILITY.ADD_Header' | translate }}</mat-card-title
      >
      <form [formGroup]="formGroup">
        <formly-form
          [model]="model"
          [fields]="fields"
          [options]="options"
          [form]="formGroup"
        ></formly-form>
      </form>
      <mat-dialog-actions class="button-container">
        <button mat-raised-button [mat-dialog-close]="true">
          {{ 'AVAILABILITY.CANCEL' | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="formGroup.invalid"
          (click)="onAdd()"
        >
          {{ 'AVAILABILITY.ADD' | translate }}
        </button>
      </mat-dialog-actions>
    </mat-card-content>
  </mat-card>
</ng-container>
