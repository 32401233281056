<div class="call-buttons">
  <span class="icon-container">
    <ng-container *ngIf="muted">
      <mat-icon class="icon" (click)="onAudio()">mic_off</mat-icon>
    </ng-container>
    <ng-container *ngIf="!muted">
      <mat-icon class="icon" (click)="onAudio()">mic</mat-icon>
    </ng-container>
  </span>
  <span class="icon-container">
    <ng-container *ngIf="hasVideo">
      <mat-icon class="icon" (click)="onVideo()">videocam</mat-icon>
    </ng-container>
    <ng-container *ngIf="!hasVideo">
      <mat-icon class="icon" (click)="onVideo()">videocam_off</mat-icon>
    </ng-container>
  </span>
  <span class="icon-container">
    <mat-icon class="icon" (click)="onSettings()">settings</mat-icon>
  </span>
  <span class="icon-container">
    <mat-icon class="icon" (click)="onEnd()"> call_end</mat-icon>
  </span>
</div>
