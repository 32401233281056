<ng-container *ngIf="translations$ | async">
  <mat-card appearance="outlined">
    <mat-card-content>
      <mat-card-title>{{
        'SETTINGS.ACCOUNT.HEADER' | translate
      }}</mat-card-title>
      <tdoc-profile-settings-form
        [profile]="profile"
        [noDoctorTypeChoice]="true"
        [hideNextButton]="true"
        [disableRegionSelection]="true"
      ></tdoc-profile-settings-form>
      <tdoc-auth-language-selection></tdoc-auth-language-selection>
      <button
        mat-raised-button
        color="primary"
        (click)="openNewPasswordDialog()"
      >
        {{ 'SETTINGS.ACCOUNT.RESET_PASSWORD' | translate }}
      </button>
    </mat-card-content>
  </mat-card>
</ng-container>
