<ng-container *ngIf="profileService.doctorProfile$ | async as profile">
  <ng-container *ngIf="!profile.medicalSpecialist">
    <div class="available-toggle">
      <span class="online-label">
        <ng-container
          *ngIf="
            doctorStatusService.isDoctorAvailable$ | async as status;
            else unavailable
          "
        >
          <ng-container>
            <p>
              {{ 'AVAILABILITY.TOGGLE.AVAILABLE' | translate }}
            </p>
          </ng-container>
        </ng-container>
        <ng-template #unavailable>
          <p>
            {{ 'AVAILABILITY.TOGGLE.UNAVAILABLE' | translate }}
          </p>
        </ng-template>
      </span>
      <mat-slide-toggle
        class="toggle-itself"
        ngModel
        name="available"
        [(ngModel)]="status"
        (change)="changeStatus()"
      />
    </div>
  </ng-container>
  <div class="spacer" *ngIf="profile.medicalSpecialist"></div>
</ng-container>
