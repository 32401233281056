import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ChimeService } from 'src/app/shared/chime/chime.service';
import { MeetingStatus } from 'src/app/shared/types/meeting-status';

@Component({
  selector: 'tdoc-doctor-video-tile',
  templateUrl: './doctor-video-tile.component.html',
  styleUrls: ['./doctor-video-tile.component.scss'],
})
export class DoctorVideoTileComponent implements AfterViewInit, OnDestroy {
  @ViewChild('localVideoElementId')
  localVideoElement: ElementRef<HTMLVideoElement>;
  private meetingStatus$: Observable<MeetingStatus> =
    this.chimeService.meetingStatus$.pipe(
      tap((status: MeetingStatus) => {
        if (status === MeetingStatus.Succeeded) {
          this.chimeService.meetingSession.audioVideo.addObserver(
            this.observerLocal,
          );
        }
      }),
    );
  private meetingStatusSubscription: Subscription;

  private observerLocal = {
    videoTileDidUpdate: (tileState) => {
      console.log('videoTile Local Update', tileState);
      if (!tileState.boundAttendeeId || !tileState.localTile) {
        return;
      }

      this.chimeService.meetingSession.audioVideo.bindVideoElement(
        tileState.tileId,
        this.localVideoElement.nativeElement,
      );
    },
  };

  constructor(private chimeService: ChimeService) {}

  ngAfterViewInit(): void {
    this.meetingStatusSubscription = this.meetingStatus$.subscribe();
  }

  ngOnDestroy() {
    this.meetingStatusSubscription.unsubscribe();
    this.chimeService.meetingSession.audioVideo.removeObserver(
      this.observerLocal,
    );
  }
}
