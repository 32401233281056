import { Component, OnInit } from '@angular/core';
import { PatientsService } from '../patients.service';
import { Observable } from 'rxjs';
import { PatientMedicalFileQuery } from '../../API.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'tdoc-patients-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss'],
})
export class PatientDetailsComponent implements OnInit {
  medicalFile$: Observable<PatientMedicalFileQuery>;

  constructor(
    private route: ActivatedRoute,
    private readonly patientService: PatientsService,
  ) {}

  ngOnInit(): void {
    this.medicalFile$ = this.route.paramMap.pipe(
      switchMap((params) => {
        console.log('requesting details for patient ', params.get('id'));
        return this.patientService.getMedicalFile(params.get('id'));
      }),
    );
  }
}
