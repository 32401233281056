<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>
      {{
        config.titleTranslateKey
          ? (config.titleTranslateKey | translate)
          : config.title
      }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="card">
    <mat-accordion>
      <ng-container *ngIf="appointments.length !== 0; else noAppointments">
        <mat-expansion-panel
          *ngFor="let appointment of appointments; let i = index"
          [expanded]="step === i"
          (opened)="setStep(i)"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ appointment.from | date : 'MMM d y, HH:mm' }}
            </mat-panel-title>
            <mat-panel-description>
              {{
                appointment.patient.lastName +
                  ', ' +
                  appointment.patient.firstName
              }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div>
            <p>
              {{ 'APPOINTMENTS.REQUESTS.MESSAGE' | translate }}:
              {{ appointment.note }}
            </p>
          </div>
          <div>
            {{ 'APPOINTMENTS.REQUESTS.DOCUMENTS' | translate }}:
            <mat-chip-listbox>
              <mat-chip-option
                *ngFor="let attachment of appointment.attachments"
                color="primary"
                (click)="openAttachment(appointment, attachment)"
                >{{ attachment.fileName }}</mat-chip-option
              >
            </mat-chip-listbox>
          </div>
          <mat-action-row>
            <ng-container
              [ngTemplateOutlet]="
                config.type === 'confirmation'
                  ? confirmation
                  : config.type === 'call'
                  ? call
                  : ''
              "
            >
            </ng-container>
            <ng-template #confirmation>
              <button
                mat-raised-button
                [disabled]="!appointment.doctorId"
                (click)="onCancel(appointment.appointmentId)"
              >
                {{ 'APPOINTMENTS.REQUESTS.DECLINE' | translate }}
              </button>
              <button
                mat-raised-button
                color="primary"
                (click)="onConfirm(appointment.appointmentId)"
              >
                {{ 'APPOINTMENTS.REQUESTS.CONFIRM' | translate }}
              </button>
            </ng-template>

            <ng-template #call>
              <ng-container *ngIf="checkMeetingStartTime(appointment)">
                <button
                  mat-raised-button
                  color="primary"
                  [disabled]="appointment.appointmentStatus === 'CANCELLED'"
                  (click)="onStartMeeting(appointment.appointmentId)"
                >
                  {{ 'APPOINTMENTS.TODAYS_SCHEDULE.START' | translate }}
                </button>
              </ng-container>
            </ng-template>
          </mat-action-row>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
    <ng-template #noAppointments>
      <p>{{ 'APPOINTMENTS.TODAYS_SCHEDULE.NO_APPOINTMENTS' | translate }}</p>
    </ng-template>
  </mat-card-content>
</mat-card>
