export const createISODateTime = (date: Date): string => {
  setTimeToZero(date);
  return date.toISOString();
};

export const setTimeToZero = (date: Date): Date => {
  date.setUTCHours(0, 0, 0, 0);
  return date;
};

export const setTimeToNight = (date: Date): Date => {
  date.setUTCHours(23, 59, 0, 0);
  return date;
};

export const isSameDay = (dateToday: Date, dateToCheck: Date): boolean => {
  return (
    dateToday.getFullYear() === dateToCheck.getFullYear() &&
    dateToday.getMonth() === dateToCheck.getMonth() &&
    dateToday.getDate() === dateToCheck.getDate()
  );
};

export const getFirstDayOfWeekFromDate = (someDate: Date): string => {
  const date = new Date(someDate);
  const first = date.getUTCDate() - date.getUTCDay();
  const firstDayMorning = new Date(date.setUTCDate(first));
  setTimeToZero(firstDayMorning);
  return firstDayMorning.toISOString();
};

export const getLastDayOfWeekFromDate = (someDate: Date): string => {
  const date = new Date(someDate);
  const last = date.getUTCDate() - date.getUTCDay() + 5;
  const lastDayNight = new Date(date.setUTCDate(last));
  setTimeToNight(lastDayNight);
  return lastDayNight.toISOString();
};
