import { Injectable } from '@angular/core';
import { BehaviorSubject, from, timer } from 'rxjs';
import { switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {
  APIService,
  AvailabilityInput,
  AvailabilityStatus,
  DoctorProfileQuery,
  SetAvailableMutation,
  SetAwayMutation,
  SetUnavailableMutation,
} from 'src/app/API.service';
import { ChimeService } from '../chime/chime.service';
import { MeetingStatus } from '../types/meeting-status';
import { AmplitudeTrackingService } from '../../tracking/amplitude.service';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionLostDialogComponent } from '../connection-lost-dialog/connection-lost-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DoctorStatusService {
  private isDoctorAvailable = new BehaviorSubject<boolean>(false);
  private isInCall = new BehaviorSubject<boolean>(false);
  public isDoctorAvailable$ = this.isDoctorAvailable.asObservable();
  public isInCall$ = this.isInCall.asObservable();
  private pollingSubscription;

  private readonly requestDialogsOpened: string[] = [];
  private readonly acceptedCalls: string[] = [];
  private readonly declinedCalls: string[] = [];

  constructor(
    private api: APIService,
    private chime: ChimeService,
    private track: AmplitudeTrackingService,
    private dialog: MatDialog,
  ) {
    from(this.api.DoctorProfile())
      .pipe(
        tap((profile: DoctorProfileQuery) => {
          if (!profile.medicalSpecialist) {
            this.isDoctorAvailable.next(false);
            this.initAvailabilityPing();
          }
        }),
      )
      .subscribe();
  }

  setAvailable(): void {
    from(this.api.SetAvailable())
      .pipe(
        take(1),
        tap((result: SetAvailableMutation) => {
          this.isDoctorAvailable.next(true);
          this.track.trackEvent('Available active minute', {});
          if (result.hasPatientsInQueue) {
            this.chime.setRequestIncoming(true);
          }
        }),
      )
      .subscribe();
  }

  setAway(): void {
    from(this.api.SetAway())
      .pipe(
        take(1),
        tap((result: SetAwayMutation) => {
          result.success && console.log('doctor successfully marked away');
          this.track.trackEvent('Inactive minute', {});
        }),
      )
      .subscribe();
  }

  setUnavailable(): void {
    from(this.api.SetUnavailable())
      .pipe(
        take(1),
        tap((result: SetUnavailableMutation) => {
          this.isDoctorAvailable.next(result.isAvailable);
          this.track.trackEvent('Break Initiated', {});
        }),
      )
      .subscribe();
  }

  initAvailabilityPing(): void {
    this.pollingSubscription = timer(0, 20000)
      .pipe(
        withLatestFrom(this.chime.meetingStatus$),
        tap(([meetingsStatus]) => {
          const doctorStatus = this.isDoctorAvailable.value;
          const callProtocol: AvailabilityInput = {
            status: this.getAvailabilityStatus(doctorStatus, meetingsStatus),
            meetingsStatus,
            doctorStatus,
            hasMeetingRequestSubscriptionCompleted:
              this.chime.hasMeetingRequestSubscriptionCompleted,
            receivedCalls: this.chime.receivedCalls,
            acceptedCalls: this.acceptedCalls,
            declinedCalls: this.declinedCalls,
            requestDialogsOpened: this.requestDialogsOpened,
            meetingRequestSubscriptionErrors:
              this.chime.meetingRequestSubscriptionErrors,
          };
          if (this.isDoctorAvailable.value) {
            from(this.api.PingAvailability(callProtocol))
              .pipe(take(1))
              .subscribe(
                (next) => {
                  if (!next.success) {
                    this.isDoctorAvailable.next(false);
                  }
                },
                (error) => {
                  this.isDoctorAvailable.next(false);
                  this.track.trackEvent('Connection Lost', {});
                  this.dialog.open(ConnectionLostDialogComponent);
                },
              );
          }
        }),
      )
      .subscribe();
  }

  public stopPolling() {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }

  public registerAcceptedCall(): void {
    this.acceptedCalls.push(this.getTimestampNow());
  }

  public registerDeclinedCall(): void {
    this.declinedCalls.push(this.getTimestampNow());
  }

  public registerDialogOpened(): void {
    this.requestDialogsOpened.push(this.getTimestampNow());
  }

  public pickUpCall(): void {
    this.isInCall.next(true);
  }

  public hangUpCall(): void {
    this.isInCall.next(false);
  }

  private getTimestampNow(): string {
    return Date.now().toString();
  }

  private getAvailabilityStatus(
    doctorStatus: boolean,
    meetingsStatus: MeetingStatus,
  ): AvailabilityStatus {
    let availabilityStatus;
    if (doctorStatus) {
      availabilityStatus = AvailabilityStatus.AVAILABLE;
    } else {
      if (meetingsStatus === MeetingStatus.Succeeded) {
        availabilityStatus = AvailabilityStatus.IN_CALL;
      } else {
        availabilityStatus = AvailabilityStatus.UNAVAILABLE;
      }
    }
    return availabilityStatus;
  }
}
