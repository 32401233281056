<ng-container *ngIf="translations$ | async as translations; else loading">
  <ng-container *ngIf="profile$ | async as profile">
    <div class="stepper-container" *ngIf="hasRequested$ | async as requested">
      <mat-horizontal-stepper
        class="stepper"
        [linear]="true"
        [selectedIndex]="requested === 'HAS_REQUESTED' ? 5 : 0"
        #stepper
      >
        <mat-step
          [editable]="!hasRequested"
          [completed]="regionFormGroupStatus"
        >
          <tdoc-logo class="logo"></tdoc-logo>
          <ng-template matStepLabel>{{
            'PROFILE.REGION_STEP' | translate
          }}</ng-template>
          <div class="card">
            <mat-card appearance="outlined">
              <mat-card-content class="content">
                <mat-card-title>{{
                  'PROFILE.REGION.HEADER' | translate
                }}</mat-card-title>
                <tdoc-profile-settings-form
                  [profile]="profile"
                  (regionFormGroupValid)="onRegionFormGroupUpdate($event)"
                ></tdoc-profile-settings-form>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-step>
        <mat-step [editable]="!hasRequested">
          <tdoc-logo class="logo"></tdoc-logo>
          <ng-template matStepLabel>{{
            'PROFILE.PERSONAL_STEP' | translate
          }}</ng-template>
          <div class="card">
            <tdoc-profile-personal
              class="content"
              [profile]="profile"
            ></tdoc-profile-personal>
          </div>

          <div class="next-btn">
            <button mat-raised-button color="primary" matStepperNext>
              {{ 'PROFILE.NEXT_STEP' | translate }}
            </button>
          </div>
        </mat-step>

        <mat-step [editable]="!hasRequested">
          <tdoc-logo class="logo"></tdoc-logo>
          <ng-template matStepLabel>{{
            'PROFILE.ADDRESS_STEP' | translate
          }}</ng-template>
          <div class="card">
            <tdoc-profile-address
              class="content"
              [profile]="profile"
            ></tdoc-profile-address>
          </div>
          <div class="next-btn">
            <button mat-raised-button color="primary" matStepperNext>
              {{ 'PROFILE.NEXT_STEP' | translate }}
            </button>
          </div>
        </mat-step>
        <mat-step [editable]="!hasRequested">
          <tdoc-logo class="logo"></tdoc-logo>
          <ng-template matStepLabel>{{
            'PROFILE.BIO_STEP' | translate
          }}</ng-template>
          <div class="card">
            <tdoc-profile-bio
              class="content"
              [profile]="profile"
            ></tdoc-profile-bio>
          </div>
          <div class="next-btn">
            <button mat-raised-button color="primary" matStepperNext>
              {{ 'PROFILE.NEXT_STEP' | translate }}
            </button>
          </div>
        </mat-step>
        <mat-step [editable]="!hasRequested">
          <tdoc-logo class="logo"></tdoc-logo>
          <ng-template matStepLabel>{{
            'PROFILE.DOCUMENTS' | translate
          }}</ng-template>
          <div class="card">
            <tdoc-file-upload-table class="content"></tdoc-file-upload-table>
          </div>
          <div class="next-btn">
            <button mat-raised-button color="primary" matStepperNext>
              {{ 'PROFILE.NEXT_STEP' | translate }}
            </button>
          </div>
        </mat-step>
        <mat-step>
          <tdoc-logo class="logo"></tdoc-logo>
          <ng-template matStepLabel>{{
            'PROFILE.DONE' | translate
          }}</ng-template>
          <span class="card">
            <div class="content">
              <ng-container *ngIf="hasRequested">
                <p>{{ 'PROFILE.ACTIVATION_SUCCESS' | translate }}</p>
                <button mat-raised-button color="primary" (click)="onSignOut()">
                  {{ 'PROFILE.LOGOUT' | translate }}
                </button>
              </ng-container>
              <ng-container *ngIf="!hasRequested">
                <p>{{ 'PROFILE.DONE.MESSAGE' | translate }}</p>
                <button
                  mat-raised-button
                  color="primary"
                  (click)="requestActivation()"
                >
                  {{ 'PROFILE.SEND' | translate }}
                </button>
              </ng-container>
            </div>
          </span>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </ng-container>
</ng-container>

<ng-template #loading> Loading...</ng-template>
