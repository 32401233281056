import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { Clinic, ClinicsQuery, DoctorProfileQuery } from 'src/app/API.service';
import { ProfileService } from 'src/app/profile/profile.service';
import { currencyOptions } from '../price/price.component';
import { PriceService } from '../price/price.service';
import { ClinicService } from './clinic.service';

@Component({
  selector: 'tdoc-clinic',
  templateUrl: './clinic.component.html',
  styleUrls: ['./clinic.component.scss'],
})
export class ClinicComponent implements OnInit {
  @Input() profile: DoctorProfileQuery;
  translations$: Observable<any>;
  translations: any;
  formGroup = new UntypedFormGroup({});
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  clinicList: ClinicsQuery;
  model: Clinic;
  clinicLogoUrl: string | null = null;

  constructor(
    private translate: TranslateService,
    private clinicService: ClinicService,
    private profileService: ProfileService,
    private priceService: PriceService,
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  public onModelChange() {
    this.profileService
      .updateProfile({ ...this.profile, clinicId: this.model.id })
      .pipe(take(1))
      .subscribe();
  }

  private initData(): void {
    this.clinicLogoUrl = this.profile.clinic?.logoUrl;
    this.model = {
      ...this.profile.clinic,
      appointmentPrice: {
        ...this.profile.clinic?.appointmentPrice,
        total: this.priceService.addDecimalSeparator(
          this.profile.clinic?.appointmentPrice?.total || 0,
        ),
      },
    };
    this.clinicService
      .getClinicList()
      .pipe(
        take(1),
        tap((clinicList) => (this.clinicList = clinicList)),
      )
      .subscribe(() => {
        this.initTranslations();
      });
  }

  private initTranslations(): void {
    this.translations$ = this.translate.get('SETTINGS.CLINIC').pipe(
      tap((translations) => {
        this.translations = translations;
        this.setupFormFields();
      }),
    );
  }

  private setupFormFields(): void {
    this.fields = [
      {
        fieldGroupClassName: 'form-group display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'id',
            type: 'select',
            templateOptions: {
              label: this.translations.NAME,
              options: this.createClinicOptions(),
            },
            modelOptions: {
              debounce: { default: 1000 },
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                const parentForm = field.parent.formControl;
                const idControl = parentForm.get('id');

                idControl.valueChanges
                  .pipe(
                    filter((clinicId) => !!clinicId),
                    tap((clinicId) => {
                      const selectedClinic = this.clinicList.entries.find(
                        (clinic) => clinic.id === clinicId,
                      );
                      this.clinicLogoUrl = selectedClinic?.logoUrl || null;
                    }),
                  )
                  .subscribe();
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'form-group display-flex',
        fieldGroup: [
          {
            className: 'flex-3',
            key: 'address',
            type: 'input',
            templateOptions: {
              label: this.translations.ADDRESS,
              readonly: true,
            },
            modelOptions: {
              debounce: { default: 1000 },
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                const parentForm = field.parent.formControl;
                const idControl = parentForm.get('id');

                idControl.valueChanges
                  .pipe(
                    filter((clinicId) => !!clinicId),
                    tap((clinicId) => {
                      const selectedClinic = this.clinicList.entries.find(
                        (clinic) => clinic.id === clinicId,
                      );
                      field.formControl.setValue(selectedClinic.address);
                    }),
                  )
                  .subscribe();
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'form-group display-flex',
        fieldGroup: [
          {
            className: 'flex-3',
            key: 'description',
            type: 'textarea',
            templateOptions: {
              label: this.translations.DESCRIPTION,
              readonly: true,
            },
            modelOptions: {
              debounce: { default: 1000 },
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                const parentForm = field.parent.formControl;
                const idControl = parentForm.get('id');

                idControl.valueChanges
                  .pipe(
                    filter((clinicId) => !!clinicId),
                    tap((clinicId) => {
                      const selectedClinic = this.clinicList.entries.find(
                        (clinic) => clinic.id === clinicId,
                      );
                      field.formControl.setValue(selectedClinic.description);
                    }),
                  )
                  .subscribe();
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'form-group display-flex',
        fieldGroup: [
          {
            className: 'flex-3',
            key: 'appointmentPrice.total',
            type: 'input',
            templateOptions: {
              label: this.translations.PRICE.TOTAL,
              readonly: true,
              type: 'number',
            },
            modelOptions: {
              debounce: { default: 1000 },
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                const parentForm = field.parent.formControl;
                const idControl = parentForm.get('id');

                idControl.valueChanges
                  .pipe(
                    filter((clinicId) => !!clinicId),
                    tap((clinicId) => {
                      const selectedClinic = this.clinicList.entries.find(
                        (clinic) => clinic.id === clinicId,
                      );
                      field.formControl.setValue(
                        this.priceService.addDecimalSeparator(
                          selectedClinic.appointmentPrice?.total || 0,
                        ),
                      );
                    }),
                  )
                  .subscribe();
              },
            },
          },
          {
            className: 'flex-1',
            key: 'appointmentPrice.currency',
            type: 'select',
            templateOptions: {
              label: this.translations.PRICE.CURRENCY,
              options: currencyOptions,
              disabled: true,
            },
            modelOptions: {
              debounce: { default: 1000 },
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                const parentForm = field.parent.formControl;
                const idControl = parentForm.get('id');

                idControl.valueChanges
                  .pipe(
                    filter((clinicId) => !!clinicId),
                    tap((clinicId) => {
                      const selectedClinic = this.clinicList.entries.find(
                        (clinic) => clinic.id === clinicId,
                      );
                      field.formControl.setValue(
                        selectedClinic.appointmentPrice?.currency,
                      );
                    }),
                  )
                  .subscribe();
              },
            },
          },
        ],
      },
    ];
  }

  private createClinicOptions() {
    const list = this.clinicList?.entries.map((clinic) => {
      return { value: clinic.id, label: clinic.name };
    });
    list.unshift({ value: null, label: '' });
    return list;
  }
}
