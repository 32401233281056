import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, timer } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { GetDoctorFilesQuery } from 'src/app/API.service';
import { ProfileService } from 'src/app/profile/profile.service';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog';

@Component({
  selector: 'tdoc-file-upload-table',
  templateUrl: './file-upload-table.component.html',
  styleUrls: ['./file-upload-table.component.scss'],
})
export class FileUploadTableComponent implements OnInit {
  displayedColumns: string[] = ['name', 'date', 'actions'];
  initData$: Observable<GetDoctorFilesQuery[]>;
  dataSource: MatTableDataSource<GetDoctorFilesQuery>;

  constructor(
    private profileService: ProfileService,
    private http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  uploadFile(file): void {
    this.profileService
      .createUploadFileUrl()
      .pipe(
        take(1),
        switchMap((url) => {
          let headers = new HttpHeaders();
          headers = headers.set('x-amz-server-side-encryption', 'AES256');
          headers = headers.set(
            'Content-Disposition',
            `attachment; filename=\"${file.name}"`,
          );
          return this.http.put(url, file, { headers });
        }),
        switchMap(() => this.updateListAfterUploadHack()),
      )
      .subscribe();
  }

  refresh(): void {
    this.updateData().pipe(take(1)).subscribe();
  }

  view(row: GetDoctorFilesQuery): void {
    this.profileService
      .getFileDownloadUrl(row.fileId)
      .pipe(
        take(1),
        map((file) => file.url),
        tap((url) => window.open(url)),
      )
      .subscribe();
  }

  delete(row: GetDoctorFilesQuery): void {
    this.dialog.open(DeleteDialogComponent, {
      data: {
        name: row.fileName,
        confirm: () =>
          this.profileService
            .deleteDoctorFile(row.fileId)
            .pipe(
              take(1),
              switchMap(() => this.updateData()),
            )
            .subscribe(),
      },
    });
  }

  private initData(): void {
    this.initData$ = this.getData().pipe(
      tap((files: GetDoctorFilesQuery[]) => {
        this.dataSource = new MatTableDataSource(files);
      }),
    );
  }

  private getData(): Observable<GetDoctorFilesQuery[]> {
    return this.profileService.getDoctorFiles().pipe(
      take(1),
      map((files) => this.sortDataByDate(files)),
    );
  }

  private updateData(): Observable<GetDoctorFilesQuery[]> {
    return this.getData().pipe(
      tap((files) => (this.dataSource.data = files)),
      tap(() => this.changeDetectorRef.detectChanges()),
    );
  }

  private updateListAfterUploadHack(): Observable<GetDoctorFilesQuery[]> {
    const timeToWaitMilliseconds = 2000;
    return timer(timeToWaitMilliseconds).pipe(
      switchMap(() => this.updateData()),
    );
  }

  private sortDataByDate(files: GetDoctorFilesQuery[]): GetDoctorFilesQuery[] {
    return files.sort((a, b) => {
      return a.fileUploadDateTime < b.fileUploadDateTime
        ? 1
        : a.fileUploadDateTime > b.fileUploadDateTime
        ? -1
        : 0;
    });
  }
}
