import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements OnDestroy {
  private _configState: Subscription;

  constructor(private toast: MatSnackBar) {}

  ngOnDestroy() {
    this._configState.unsubscribe();
  }

  show(message: string, buttonLabel: string = 'OK'): MatSnackBarRef<any> {
    const toastTimeout = 5000;
    if (toastTimeout > 0) {
      return this.toast.open(message, buttonLabel, {
        duration: toastTimeout,
      });
    } else {
      return this.toast.open(message, buttonLabel, {});
    }
  }
}
