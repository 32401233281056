import { Component, OnInit } from '@angular/core';
import { AmplitudeTrackingService } from '../../tracking/amplitude.service';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'tdoc-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent implements OnInit {
  showDetails: boolean;

  translations$: Observable<any>;
  translations: any;
  essential: boolean;
  tracking: boolean;

  constructor(
    private track: AmplitudeTrackingService,
    private translate: TranslateService,
  ) {
    this.initTranslations();
    this.essential = true;
  }

  ngOnInit(): void {
    const teledocCookies =
      JSON.parse(localStorage.getItem('teledoc_cookies')) || false;
    this.tracking = teledocCookies.tracking === 'true';
    this.showDetails = false;
  }

  onDecline(): void {
    localStorage.setItem(
      'teledoc_cookies',
      JSON.stringify({ tracking: 'false' }),
    );
  }

  onConfirm() {
    this.track.init();
    localStorage.setItem(
      'teledoc_cookies',
      JSON.stringify({ tracking: 'true' }),
    );
  }

  toDetails() {
    this.showDetails = true;
  }

  toSummary() {
    this.showDetails = false;
  }

  enableCookies() {
    if (this.tracking) {
      this.onConfirm();
      localStorage.setItem(
        'teledoc_cookies',
        JSON.stringify({ tracking: 'true' }),
      );
    } else {
      localStorage.setItem(
        'teledoc_cookies',
        JSON.stringify({ tracking: 'false' }),
      );
    }
  }

  private initTranslations() {
    this.translations$ = this.translate.get('COOKIES_BANNER').pipe(
      tap((translations) => {
        this.translations = translations;
      }),
    );
  }
}
