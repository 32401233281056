<ng-container *ngIf="translations$ | async">
  <div *ngIf="!!!this.noDoctorTypeChoice" class="select-button-group">
    <div
      id="gpDoctor"
      class="select-button"
      [ngClass]="!!this.hideMedicalSpecialistSelection ? 'button-active' : ''"
      (click)="onSelectDoctor()"
    >
      <div class="button-title">
        {{ 'PROFILE.DOCTOR_SELECTION.SELECT_DOCTOR.TITLE' | translate }}
      </div>
      <div class="button-hint">
        {{ 'PROFILE.DOCTOR_SELECTION.SELECT_DOCTOR.HINT' | translate }}
      </div>
      <div class="button-description">
        {{ 'PROFILE.DOCTOR_SELECTION.SELECT_DOCTOR.DESCRIPTION' | translate }}
      </div>
    </div>
    <div
      id="specialistDoctor"
      class="select-button"
      [ngClass]="!!this.hideMedicalSpecialistSelection ? '' : 'button-active'"
      (click)="onSelectMedicalSpecialist()"
    >
      <div class="button-title">
        {{
          'PROFILE.DOCTOR_SELECTION.SELECT_MEDICAL_SPECIALIST.TITLE' | translate
        }}
      </div>
      <div class="button-hint">
        {{
          'PROFILE.DOCTOR_SELECTION.SELECT_MEDICAL_SPECIALIST.HINT' | translate
        }}
      </div>
      <div class="button-description">
        {{
          'PROFILE.DOCTOR_SELECTION.SELECT_MEDICAL_SPECIALIST.DESCRIPTION'
            | translate
        }}
      </div>
    </div>
  </div>
  <ng-container
    *ngIf="!!!this.noDoctorTypeChoice && !!this.hideMedicalSpecialistSelection"
  >
    <p>
      {{ 'PROFILE.DOCTOR_SELECTION.SELECT_DOCTOR.REGION_HINT' | translate }}
    </p>
  </ng-container>
  <form [formGroup]="formGroup">
    <formly-form
      [model]="model"
      [fields]="fields"
      [options]="options"
      [form]="formGroup"
      (modelChange)="onChange()"
    ></formly-form>
  </form>
  <p></p>
  <div *ngIf="!hideNextButton" class="next-btn">
    <button
      mat-raised-button
      [disabled]="formGroup.status !== 'VALID'"
      color="primary"
      matStepperNext
    >
      {{ 'PROFILE.NEXT_STEP' | translate }}
    </button>
  </div>
</ng-container>
