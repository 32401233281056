import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, from, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import {
  APIService,
  ClinicalReportInput,
  CreatePrescriptionMutation,
  GetPrescriptionDownloadUrlQuery,
  PatientFileAttachmentDownloadUrlQuery,
  PatientMedicalFileQuery,
  PatientProfileInput,
  PrescriptionInput,
  SavePatientProfileMutation,
  UpdateClinicalReportMutation,
} from '../API.service';
import { DoctorStatusService } from '../shared/doctor-status/doctor-status.service';
import { AmplitudeTrackingService } from '../tracking/amplitude.service';

@Injectable({
  providedIn: 'root',
})
export class PatientsService {
  private patientMedicalFile = new BehaviorSubject<PatientMedicalFileQuery>(
    undefined,
  );
  public patientMedicalFile$: Observable<PatientMedicalFileQuery> =
    this.patientMedicalFile
      .asObservable()
      .pipe(tap((medicalFile) => (this.accessId = medicalFile?.accessId)));

  private accessId: string;
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  constructor(
    private api: APIService,
    private doctorStatusServie: DoctorStatusService,
    private tracking: AmplitudeTrackingService,
  ) {}

  public pickUpCall(): void {
    this.doctorStatusServie.pickUpCall();
  }

  public hangUpCall(): void {
    this.doctorStatusServie.hangUpCall();
  }

  createPrescription(
    prescription: PrescriptionInput,
  ): Observable<CreatePrescriptionMutation> {
    return from(this.api.CreatePrescription(prescription));
  }

  getPrescriptionUrl(
    visitId: string,
    prescriptionId: string,
  ): Observable<GetPrescriptionDownloadUrlQuery> {
    return from(this.api.GetPrescriptionDownloadUrl(visitId, prescriptionId));
  }

  getMedicalFile(id: string): Observable<PatientMedicalFileQuery> {
    this.isLoading.next(true);
    return from(this.api.PatientMedicalFile(id)).pipe(
      tap((medicalFile: PatientMedicalFileQuery) => {
        this.patientMedicalFile.next(medicalFile);
        if (!medicalFile) {
          this.doctorStatusServie.hangUpCall();
          this.doctorStatusServie.setAvailable();
        }
      }),
      finalize(() => {
        this.isLoading.next(false);
      }),
    );
  }

  updateClinicalReport(
    report: ClinicalReportInput,
  ): Observable<UpdateClinicalReportMutation> {
    return from(this.api.UpdateClinicalReport(report));
  }

  savePatientProfile(
    accessId: string,
    data: any,
  ): Observable<SavePatientProfileMutation> {
    return from(
      this.api.SavePatientProfile(
        accessId,
        this.createPatientProfileInput(data),
      ),
    );
  }

  getPatientFileUrl(
    fileAttachmentId,
    accessId,
  ): Observable<PatientFileAttachmentDownloadUrlQuery> {
    return from(
      this.api.PatientFileAttachmentDownloadUrl({ fileAttachmentId, accessId }),
    );
  }

  terminateVisit(): void {
    from(this.api.TerminateVisit(this.accessId)).pipe(take(1)).subscribe();
    from(this.api.TerminateMeeting(this.accessId)).pipe(take(1)).subscribe();
    this.clearMedicalFile();
  }

  private clearMedicalFile(): void {
    this.patientMedicalFile.next(undefined);
  }

  private createPatientProfileInput(input): PatientProfileInput {
    return {
      medicalConditions: {
        allergies: input.medicalConditions.allergies || null,
        preConditions: input.medicalConditions.preConditions || null,
        medicationAllergies:
          input.medicalConditions.medicationAllergies || null,
      },
    };
  }
}
