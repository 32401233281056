import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AuthLanguageSelectionService } from './auth-language-selection.service';
import { ProfileService } from '../../profile/profile.service';

@Component({
  selector: 'tdoc-auth-language-selection',
  templateUrl: './auth-language-selection.component.html',
  styleUrls: ['./auth-language-selection.component.scss'],
})
export class AuthLanguageSelectionComponent implements OnInit {
  formGroup = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];

  translations$: Observable<any>;
  translations: any;

  constructor(
    private translate: TranslateService,
    private authLanguageSelectionService: AuthLanguageSelectionService,
    private profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.initData();
    this.model.language = this.authLanguageSelectionService.selectedLanguage;
  }

  private initTranslations() {
    this.translations$ = this.translate.get('SETTINGS.ACCOUNT').pipe(
      tap((translations) => {
        this.translations = translations;
        this.setupFormFields();
      }),
    );
  }

  private initData() {
    this.authLanguageSelectionService.appLanguanges$
      .pipe(
        take(1),
        tap(() => this.setupFormFields()),
      )
      .subscribe();
  }

  private setupFormFields() {
    this.fields = [
      {
        id: 'appLanguage',
        key: 'language',
        type: 'select',
        templateOptions: {
          label: this.translations.LANGUAGE,
          options: this.authLanguageSelectionService.appLanguanges$,
        },
      },
    ];
  }

  onLanguageChange() {
    this.authLanguageSelectionService.selectLanguage(this.model.language);
    this.profileService.doctorProfile$.pipe(take(1)).subscribe((value) => {
      this.profileService.updateProfile({
        ...value,
        appLanguage: this.model.language,
      });
    });
  }
}
