<mat-toolbar>
  <div class="title">
    <span>{{ title }}</span>
  </div>
  <div class="logo-container">
    <tdoc-logo [widthRem]="9"></tdoc-logo>
  </div>
  <div class="doctor-status">
    <tdoc-doctor-status></tdoc-doctor-status>
  </div>
</mat-toolbar>
