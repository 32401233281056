<mat-card appearance="outlined">
  <mat-card-content>
    <div class="content">
      <img
        *ngIf="imageUrl$ | async as url"
        mat-card-image
        [src]="url"
        alt="Profile picture"
      />
      <mat-icon
        *ngIf="(imageUrl$ | async) === null"
        class="default-image"
        aria-hidden="false"
        aria-label="Default profile picture"
        >person
      </mat-icon>
    </div>
    <mat-card-actions align="end" class="mat-card-actions-spacing">
      <tdoc-file-upload (uploadFile)="uploadFile($event)"></tdoc-file-upload>
      <button mat-mini-fab color="primary" (click)="refreshPicture()">
        <mat-icon>refresh</mat-icon>
      </button>
      <button mat-mini-fab color="primary" (click)="deletePicture()">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
