<ng-container *ngIf="translations$ | async">
  <mat-card appearance="outlined">
    <mat-card-content class="card">
      <mat-card-title> {{ 'AVAILABILITY.HEADER' | translate }}</mat-card-title>
      <div class="table-actions">
        <button mat-mini-fab color="primary" (click)="addAvailability()">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="weekday">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'AVAILABILITY.WEEKDAY' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ 'AVAILABILITY.' + row.weekday | translate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="from">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'AVAILABILITY.FROM' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.from | date : 'HH:mm' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="to">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'AVAILABILITY.TO' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.to | date : 'HH:mm' }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'AVAILABILITY.TABLE_ACTIONS' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <button
              mat-icon-button
              aria-label="Delete"
              (click)="deleteAvailability(row)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</ng-container>
