<ng-container *ngIf="translations$ | async">
  <div mat-dialog-content>
    <mat-card appearance="outlined">
      <mat-card-content class="card">
        <h1>{{ 'AUTH.RESET_PASSWORD_DIALOG.HEADER' | translate }}</h1>

        <form [formGroup]="formGroup">
          <formly-form
            [model]="model"
            [fields]="fields"
            [options]="options"
            [form]="formGroup"
          ></formly-form>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-dialog-actions class="button-container">
    <button mat-raised-button [mat-dialog-close]="true">
      {{ 'AUTH.RESET_PASSWORD_DIALOG.CANCEL' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="onConfirm()">
      {{ 'AUTH.RESET_PASSWORD_DIALOG.CONFIRM' | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
