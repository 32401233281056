<ng-container *ngIf="translations$ | async">
  <mat-card appearance="outlined">
    <mat-card-content class="card">
      <mat-card-title>{{ 'PATIENTS.FILES.HEADER' | translate }}</mat-card-title>
      <mat-list>
        <mat-list-item *ngFor="let file of patientFiles">
          <mat-icon matListIcon class="icon" (click)="openFile(file)"
            >preview
          </mat-icon>
          <span class="name"> {{ file.fileName | slice : 0 : 30 }}</span>
          <span> {{ file.fileDateTime | date : 'MMM d y, HH:mm' }}</span>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</ng-container>
