import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { CallControlsModule } from './call-controls/call-controls.module';
import { DoctorVideoTileModule } from './doctor-video-tile/doctor-video-tile.module';
import { PatientCallComponent } from './patient-call.component';
import { PatientVideoTileModule } from './patient-video-tile/patient-video-tile.module';

@NgModule({
  declarations: [PatientCallComponent],
  imports: [
    CommonModule,
    MaterialModule,
    DoctorVideoTileModule,
    PatientVideoTileModule,
    CallControlsModule,
    TranslateModule,
  ],
  exports: [PatientCallComponent],
})
export class PatientCallModule {}
