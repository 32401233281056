<ng-container *ngIf="translations$ | async">
  <mat-card appearance="outlined">
    <mat-card-content class="card">
      <mat-card-title>{{
        'PATIENTS.PASTVISITS.HEADER' | translate
      }}</mat-card-title>
      <div class="buttons">
        <ng-container *ngFor="let visit of pastVisits">
          <button
            mat-raised-button
            class="button"
            color="primary"
            (click)="openVisit(visit)"
          >
            {{ visit.visitDateTime | date : 'MMM d y, h:mm a' }}
          </button>
        </ng-container>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
