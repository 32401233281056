import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileUploadTableModule } from 'src/app/shared/file-upload-table/file-upload-table.module';
import { ProfileUploadComponent } from './profile-upload.component';

@NgModule({
  declarations: [ProfileUploadComponent],
  imports: [CommonModule, FileUploadTableModule],
  exports: [ProfileUploadComponent],
})
export class ProfileUploadModule {}
