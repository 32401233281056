import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuthLanguageSelectionModule } from 'src/app/auth/auth-language-selection/auth-language-selection.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { ProfileSettingsFormModule } from 'src/app/shared/profile-settings-form/profile-settings-form.module';
import { TdocFormlyModule } from 'src/app/shared/tdoc-formly/tdoc-formly.module';
import { NewPasswordDialogModule } from '../new-password-dialog/new-password-dialog.module';
import { AccountSettingsComponent } from './account-settings.component';

@NgModule({
  declarations: [AccountSettingsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    TdocFormlyModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forChild(),
    NewPasswordDialogModule,
    ProfileSettingsFormModule,
    AuthLanguageSelectionModule,
  ],
  exports: [AccountSettingsComponent],
})
export class AccountSettingsModule {}
