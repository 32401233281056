import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { AppRoutes } from '../../shared/routes';

@Component({
  selector: 'tdoc-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
  translations: any;
  translations$: Observable<any>;
  hide = true;
  newPasswordForm: FormGroup = new FormGroup(
    {
      password: new FormControl('', [Validators.required, Validators.min(8)]),
      confirmedPassword: new FormControl('', [
        Validators.required,
        Validators.min(8),
      ]),
    },
    {
      validators: (group: AbstractControl): ValidationErrors | null => {
        let pass = group.get('password').value;
        let confirmPass = group.get('confirmedPassword').value;
        return pass === confirmPass ? null : { notSame: true };
      },
    },
  );

  get passwordInput() {
    return this.newPasswordForm.get('password');
  }

  constructor(
    private router: Router,
    private auth: AuthService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.translations$ = this.translate.get('AUTH.FORGOT_PASSWORD').pipe(
      tap((translations) => {
        this.translations = translations;
      }),
    );
  }

  getPasswordInputError() {
    if (this.passwordInput.hasError('required')) {
      return this.translations['PASSWORD_REQUIRED'];
    }
  }

  async submitNewPassword() {
    const newPassword = this.passwordInput.value;
    await this.auth.completeNewPassword(newPassword);
    await this.router.navigate(['']);
  }
}
