import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { PatientMedicalFileQuery } from 'src/app/API.service';
import { PatientsService } from '../patients.service';

@Component({
  selector: 'tdoc-patient-medical',
  templateUrl: './patient-medical.component.html',
  styleUrls: ['./patient-medical.component.scss'],
})
export class PatientMedicalComponent implements OnInit {
  @Input() medicalFile: PatientMedicalFileQuery;
  @Input() disabled: boolean;

  formGroup = new UntypedFormGroup({});

  translations$: Observable<any>;
  translations: any;
  private accessId: string;

  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];

  constructor(
    private translate: TranslateService,
    private patientService: PatientsService,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.model = this.medicalFile.personalData;
    this.accessId = this.medicalFile.accessId;
  }

  onSend(): void {
    this.patientService
      .savePatientProfile(this.accessId, {
        medicalConditions: this.model.medicalConditions,
      })
      .pipe(take(1))
      .subscribe();
  }

  private initTranslations() {
    this.translations$ = this.translate.get('PATIENTS.MEDICAL').pipe(
      tap((translations) => {
        this.translations = translations;
        this.setupFormFields();
      }),
    );
  }

  private setupFormFields() {
    this.fields = [
      {
        key: 'medicalConditions.allergies',
        type: 'textarea',
        templateOptions: {
          label: this.translations['ALLERGIES'],
          rows: 4,
          resize: 'none',
          readonly: this.disabled,
        },
        modelOptions: {
          debounce: { default: 1000 },
        },
      },
      {
        key: 'medicalConditions.preConditions',
        type: 'textarea',
        templateOptions: {
          label: this.translations['PRECONDITIONS'],
          rows: 4,
          resize: 'none',
          readonly: this.disabled,
        },
        modelOptions: {
          debounce: { default: 1000 },
        },
      },
      {
        key: 'medicalConditions.medicationAllergies',
        type: 'textarea',
        templateOptions: {
          label: this.translations['MEDICATION'],
          rows: 4,
          resize: 'none',
          readonly: this.disabled,
        },
        modelOptions: {
          debounce: { default: 1000 },
        },
      },
    ];
  }
}
