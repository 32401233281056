import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ChimeService } from 'src/app/shared/chime/chime.service';
import { MeetingStatus } from 'src/app/shared/types/meeting-status';

@Component({
  selector: 'tdoc-patient-video-tile',
  templateUrl: './patient-video-tile.component.html',
  styleUrls: ['./patient-video-tile.component.scss'],
})
export class PatientVideoTileComponent implements OnInit, OnDestroy {
  @ViewChild('remoteVideoElementId')
  remoteVideoElement: ElementRef<HTMLVideoElement>;

  private meetingStatusSubscription: Subscription;
  private meetingStatus$ = this.chimeService.meetingStatus$.pipe(
    tap((status: MeetingStatus) => {
      if (status === MeetingStatus.Succeeded) {
        this.chimeService.meetingSession.audioVideo.addObserver(
          this.observerRemote,
        );
      }
    }),
  );

  private observerRemote = {
    videoTileDidUpdate: (tileState) => {
      if (
        !tileState.boundAttendeeId ||
        tileState.localTile ||
        tileState.isContent
      ) {
        return;
      }

      this.chimeService.meetingSession.audioVideo.bindVideoElement(
        tileState.tileId,
        this.remoteVideoElement.nativeElement,
      );
    },
  };

  constructor(private chimeService: ChimeService) {}

  ngOnInit(): void {
    this.meetingStatusSubscription = this.meetingStatus$.subscribe();
  }

  ngOnDestroy(): void {
    this.meetingStatusSubscription.unsubscribe();
    this.chimeService.meetingSession.audioVideo.removeObserver(
      this.observerRemote,
    );
  }
}
