import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { APIService } from 'src/app/API.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { ProfileService } from '../profile.service';
import { AppRoutes } from '../../shared/routes';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';

type RequestedActivation = 'NOT_REQUESTED' | 'HAS_REQUESTED';
@Component({
  selector: 'tdoc-profile-stepper',
  templateUrl: './profile-stepper.component.html',
  styleUrls: ['./profile-stepper.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class ProfileStepperComponent implements OnInit {
  translations$: Observable<any>;
  translations: any;
  hasRequested: boolean;
  private requestedSubject = new BehaviorSubject<RequestedActivation>(
    'NOT_REQUESTED',
  );
  hasRequested$ = this.requestedSubject.asObservable();

  regionFormGroupStatus = false;
  profile$;
  private doctorName: string;

  constructor(
    private api: APIService,
    private notification: NotificationService,
    private translate: TranslateService,
    public profileService: ProfileService,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.checkHasRequested();
    this.profile$ = this.profileService.doctorProfile$.pipe(
      tap(
        (profile) =>
          (this.doctorName = `${profile.firstName}, ${profile.lastName}`),
      ),
    );
    this.profileService.getProfile();
  }

  onRegionFormGroupUpdate(isValid): void {
    this.regionFormGroupStatus = isValid;
  }

  requestActivation(): void {
    from(this.api.RequestActivation())
      .pipe(
        take(1),
        catchError((error) => {
          this.notification.show(this.translations.ACTIVATION_ERROR);
          return of(error);
        }),
        tap(() => {
          localStorage.setItem(
            `${this.doctorName}_hasRequestedActivation`,
            'true',
          );
          this.notification.show(this.translations.ACTIVATION_SUCCESS);
          this.hasRequested = true;
        }),
      )
      .subscribe();
  }

  private initTranslations(): void {
    this.translations$ = this.translate.get('PROFILE').pipe(
      tap((translations) => {
        this.translations = translations;
      }),
    );
  }

  private checkHasRequested(): void {
    const value = localStorage.getItem(
      `${this.doctorName}_hasRequestedActivation`,
    )
      ? true
      : false;
    this.hasRequested = value;
    this.requestedSubject.next(value ? 'HAS_REQUESTED' : 'NOT_REQUESTED');
  }

  onSignOut() {
    this.authService
      .signOut()
      .then(() => this.router.navigate([AppRoutes.signin]));
  }
}
