<ng-container *ngIf="translations$ | async">
  <mat-card appearance="outlined">
    <mat-card-content class="card">
      <form [formGroup]="formGroup">
        <formly-form
          [model]="model"
          [fields]="fields"
          [options]="options"
          [form]="formGroup"
        ></formly-form>
      </form>
      <ng-container *ngFor="let prescription of this.model.prescriptions">
        <button
          mat-raised-button
          class="button"
          color="primary"
          (click)="
            openPrescription(this.model.visitId, prescription.prescriptionId)
          "
        >
          {{ prescription.prescriptionName }}
        </button>
      </ng-container>
    </mat-card-content>
  </mat-card>
</ng-container>
