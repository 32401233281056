import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { ActionDialogComponent } from './action-dialog.component';

@NgModule({
  declarations: [ActionDialogComponent],
  imports: [CommonModule, TranslateModule, MaterialModule],
  exports: [ActionDialogComponent],
})
export class ActionDialogModule {}
