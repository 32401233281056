import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Appointment } from 'src/app/API.service';
import {
  AppointmentPanelConfig,
  AppointmentType,
} from 'src/app/appointments/appointment-panel/appointment-panel-config';
import { AppointmentsService } from '../appointments.service';

@Component({
  selector: 'tdoc-appointment-requests',
  templateUrl: './appointment-requests.component.html',
  styleUrls: ['./appointment-requests.component.scss'],
})
export class AppointmentRequestsComponent {
  panelConfig: AppointmentPanelConfig = {
    type: AppointmentType.confirmation,
    titleTranslateKey: 'APPOINTMENTS.REQUESTS.HEADER',
  };

  appointmentRequests$: Observable<Appointment[]>;
  constructor(private appointmentsService: AppointmentsService) {
    this.appointmentRequests$ = this.appointmentsService.pendingAppointments$;
  }
}
