import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { AppRoutes } from 'src/app/shared/routes';
import { environment } from 'src/environments/environment';
import { AuthLanguageSelectionService } from '../auth-language-selection/auth-language-selection.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'tdoc-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  hide = true;
  languages$;

  translations: any;
  translations$: Observable<any>;

  signupForm: UntypedFormGroup;

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private notification: NotificationService,
    private authLangSelectService: AuthLanguageSelectionService,
  ) {
    this.signupForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.email,
        Validators.required,
      ]),
      password: new UntypedFormControl('', [Validators.required]),
      phone: new UntypedFormControl('', [Validators.min(10)]),
      language: new UntypedFormControl('', [Validators.required]),
      has_agreed_to_terms: new UntypedFormControl(false, [Validators.required]),
    });

    this.translations$ = this.translate.get('AUTH.SIGN_UP').pipe(
      tap((translations) => {
        this.translations = translations;
      }),
    );
  }

  ngOnInit() {
    this.languages$ = this.authLangSelectService.appLanguanges$;
  }

  get emailInput() {
    return this.signupForm.get('email');
  }
  get passwordInput() {
    return this.signupForm.get('password');
  }
  get phoneInput() {
    return this.signupForm.get('phone');
  }
  get languageInput() {
    return this.signupForm.get('language');
  }
  get has_agreed_to_termsInput() {
    return this.signupForm.get('has_agreed_to_terms');
  }

  getEmailInputError() {
    if (this.emailInput.hasError('email')) {
      return this.translations.EMAIL_INVALID;
    }
    if (this.emailInput.hasError('required')) {
      return this.translations.EMAIL_REQUIRED;
    }
  }

  getPasswordInputError() {
    if (this.passwordInput.hasError('required')) {
      return this.translations.PASSWORD_REQUIRED;
    }
  }

  shouldEnableSubmit() {
    return (
      !this.emailInput.valid ||
      !this.passwordInput.valid ||
      !this.phoneInput.valid ||
      !this.has_agreed_to_termsInput.valid
    );
  }

  signUp() {
    this.authService
      .signUp({
        email: this.emailInput.value,
        password: this.passwordInput.value,
        'custom:language_code': this.languageInput.value,
        'custom:has_agreed_to_terms': this.has_agreed_to_termsInput.value,
      })
      .then((data) => {
        environment.confirm.email = this.emailInput.value;
        environment.confirm.password = this.passwordInput.value;
        this.router.navigate([AppRoutes.confirm]);
      })
      .catch((error) => {
        console.log(error);
        this.notification.show(error.message);
      });
  }
}
