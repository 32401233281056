import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppointmentRequestsModule } from './appointment-requests/appointment-requests.module';
import { AppointmentsComponent } from './appointments.component';
import { TodaysScheduleModule } from './todays-schedule/todays-schedule.module';
import { WeeklyAppointmentsModule } from './weekly-appointments/weekly-appointments.module';

@NgModule({
  declarations: [AppointmentsComponent],
  imports: [
    CommonModule,
    TodaysScheduleModule,
    AppointmentRequestsModule,
    WeeklyAppointmentsModule,
  ],
  exports: [AppointmentsComponent],
})
export class AppointmentsModule {}
