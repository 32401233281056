import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Appointment } from 'src/app/API.service';
import { PatientsService } from 'src/app/patients/patients.service';
import { ChimeService } from 'src/app/shared/chime/chime.service';
import {
  getFirstDayOfWeekFromDate,
  getLastDayOfWeekFromDate,
} from 'src/app/shared/date-conversions/date-conversions';
import { DoctorStatusService } from 'src/app/shared/doctor-status/doctor-status.service';
import { AppRoutes } from 'src/app/shared/routes';
import { AppointmentsService } from '../appointments.service';

@Component({
  selector: 'tdoc-weekly-appointments',
  templateUrl: './weekly-appointments.component.html',
  styleUrls: ['./weekly-appointments.component.scss'],
})
export class WeeklyAppointmentsComponent implements OnInit {
  weeklyAppointments$: Observable<Array<Appointment[]>>;
  today = Date.now();
  displayedWeekDay = new Date(this.today);
  firstDayOfDisplayedWeek: string;
  lastDayOfDisplayedWeek: string;

  constructor(
    public translateService: TranslateService,
    private appointmentsService: AppointmentsService,
    private chime: ChimeService,
    private router: Router,
    private patientsService: PatientsService,
    private doctorStatus: DoctorStatusService,
  ) {
    let weeklyAppointmentsByDay: Array<Appointment[]>;
    this.weeklyAppointments$ =
      this.appointmentsService.weeklyAppointments$.pipe(
        tap(() => {
          weeklyAppointmentsByDay = this.createEmtpyWeeklyAppointmentsByDay();
        }),
        map((appointments: Appointment[]) => {
          appointments.forEach((appointment: Appointment) => {
            weeklyAppointmentsByDay[new Date(appointment.from).getDay()].push(
              appointment,
            );
          });
          return weeklyAppointmentsByDay;
        }),
      );
  }

  ngOnInit(): void {
    this.updateWeekRange();
    this.updateWeeklyAppointments();
  }

  onStartMeeting(appointmentId) {
    this.router.navigate([AppRoutes.patients]);

    this.chime
      .startAppointedMeeting(appointmentId)
      .pipe(
        take(1),
        tap((v) => console.log(v)),
        switchMap((accepted) => {
          if (accepted.accessId === null) {
            this.doctorStatus.setAvailable();
            return of(null);
          } else {
            return this.patientsService.getMedicalFile(accepted.accessId);
          }
        }),
      )
      .subscribe();
  }

  checkMeetingStartTime(appointment: Appointment): boolean {
    const rightNow = Date.now();
    const from = new Date(appointment.from).valueOf();
    const to = new Date(appointment.to).valueOf();
    return from < rightNow && rightNow < to;
  }

  checkDay(dateString: string, dayToCheck: number): boolean {
    const date = new Date(dateString);
    return date.getDay() === dayToCheck;
  }

  dayOfWeekAsString(dayIndex) {
    return (
      [
        'APPOINTMENTS.WEEKLY.WEEK_DAYS.SUNDAY',
        'APPOINTMENTS.WEEKLY.WEEK_DAYS.MONDAY',
        'APPOINTMENTS.WEEKLY.WEEK_DAYS.TUESDAY',
        'APPOINTMENTS.WEEKLY.WEEK_DAYS.WEDNESDAY',
        'APPOINTMENTS.WEEKLY.WEEK_DAYS.THURSDAY',
        'APPOINTMENTS.WEEKLY.WEEK_DAYS.FRIDAY',
        'APPOINTMENTS.WEEKLY.WEEK_DAYS.SATURDAY',
      ][dayIndex] || ''
    );
  }

  onPreviousWeek() {
    this.updateDisplayedWeek('subtract', 7);
  }

  onNextWeek() {
    this.updateDisplayedWeek('add', 7);
  }

  private createEmtpyWeeklyAppointmentsByDay(): Appointment[][] {
    return [[], [], [], [], [], [], []];
  }

  private updateDisplayedWeek(action: WeekDayAction, days: number) {
    const weekDayTimestamp = this.displayedWeekDay.getTime();
    const actions = {
      subtract: () => weekDayTimestamp - days * 24 * 60 * 60 * 1000,
      add: () => weekDayTimestamp + days * 24 * 60 * 60 * 1000,
    };
    const nextWeekDay = actions[action]();
    this.displayedWeekDay = new Date(nextWeekDay);
    this.updateWeekRange();
    this.updateWeeklyAppointments();
  }

  private updateWeekRange() {
    this.firstDayOfDisplayedWeek = getFirstDayOfWeekFromDate(
      this.displayedWeekDay,
    );
    this.lastDayOfDisplayedWeek = getLastDayOfWeekFromDate(
      this.displayedWeekDay,
    );
  }

  private updateWeeklyAppointments(): void {
    this.appointmentsService
      .getWeeklyAppointments(this.displayedWeekDay)
      .pipe(take(1))
      .subscribe();
  }
}

type WeekDayAction = 'subtract' | 'add';
