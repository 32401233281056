import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { AppointmentRequestDialogComponent } from './appointment-request-dialog-component';

@NgModule({
  declarations: [AppointmentRequestDialogComponent],
  imports: [CommonModule, MaterialModule, TranslateModule],
  exports: [AppointmentRequestDialogComponent],
})
export class AppointmentRequestDialogModule {}
