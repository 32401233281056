<ng-container *ngIf="profile$ | async as profile">
  <div class="layout">
    <tdoc-profile-picture [profile]="profile" class="picture-card">
    </tdoc-profile-picture>
    <tdoc-profile-personal
      [profile]="profile"
      class="personal-card"
    ></tdoc-profile-personal>
    <tdoc-profile-bio [profile]="profile" class="bio-card"></tdoc-profile-bio>
    <tdoc-profile-address
      [profile]="profile"
      class="address-card"
    ></tdoc-profile-address>
    <tdoc-profile-upload
      [profile]="profile"
      class="upload-card"
    ></tdoc-profile-upload>
  </div>
</ng-container>
<tdoc-footer></tdoc-footer>
