import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppRoutes } from 'src/app/shared/routes';
import { AuthService } from '../auth.service';

@Component({
  selector: 'tdoc-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  hasRequested: boolean = false;
  hide = true;
  translations: any;
  translations$: Observable<any>;

  forgotForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  newPasswordForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    code: new UntypedFormControl('', [Validators.required, Validators.min(3)]),
    password: new UntypedFormControl('', [
      Validators.required,
      Validators.min(6),
    ]),
  });

  get emailForgotInput() {
    return this.forgotForm.get('email');
  }

  get emailPasswordInput() {
    return this.newPasswordForm.get('email');
  }

  get codeInput() {
    return this.newPasswordForm.get('code');
  }

  get passwordInput() {
    return this.newPasswordForm.get('password');
  }

  constructor(
    private router: Router,
    private auth: AuthService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.translations$ = this.translate.get('AUTH.FORGOT_PASSWORD').pipe(
      tap((translations) => {
        this.translations = translations;
      }),
    );
  }

  getEmailInputError() {
    if (this.emailForgotInput.hasError('email')) {
      return this.translations['EMAIL_INVALID'];
    }
    if (this.emailForgotInput.hasError('required')) {
      return this.translations['EMAIL_REQUIRED'];
    }
  }

  getPasswordInputError() {
    if (this.passwordInput.hasError('required')) {
      return this.translations['PASSWORD_REQUIRED'];
    }
  }

  sendCode() {
    const email = this.emailForgotInput.value;
    this.auth.forgotPassword(email).then(() => {
      this.newPasswordForm.get('email').setValue(email);
      this.hasRequested = true;
    });
  }

  submitNewPassword() {
    const code = this.codeInput.value;
    const newPassword = this.passwordInput.value;
    const email = this.emailPasswordInput.value;

    this.auth
      .forgotPasswordSubmit(email, code, newPassword)
      .then(() => this.router.navigate([AppRoutes.signin]));
  }
}
