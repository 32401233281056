import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import {
  DoctorProfileQuery,
  SpecialistProductInput,
  SpecialistProductQuery,
} from 'src/app/API.service';
import { PriceService } from './price.service';

export const currencyOptions = [
  { value: 'EUR', label: 'Euro' },
  { value: 'USD', label: 'US Dollar' },
  { value: 'RON', label: 'RON' },
  { value: 'HRK', label: 'HRK' },
  { value: 'ALL', label: 'ALL' },
  { value: 'BGN', label: 'BGN' },
  { value: 'HUF', label: 'HUF' },
];

@Component({
  selector: 'tdoc-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
})
export class PriceComponent implements OnInit {
  @Input() profile: DoctorProfileQuery;
  translations$: Observable<any>;
  translations: any;
  formGroup = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  isMedicalSpecialist: boolean;
  priceFormatDisplay = '00.00';

  constructor(
    private translate: TranslateService,
    private priceService: PriceService,
  ) {}

  ngOnInit(): void {
    this.isMedicalSpecialist = !!this.profile?.medicalSpecialist;
    this.initTranslations();
    this.initData();
    this.listenToLangChange();
  }

  onModelChange(): void {
    const product = this.createProduct(this.model);
    this.priceService.setSpecialistProduct(product).pipe(take(1)).subscribe();
  }

  private initData(): void {
    this.priceService
      .getSpecialistProduct()
      .pipe(
        take(1),
        tap((product) => this.setModel(product)),
      )
      .subscribe();
  }

  private createProduct(model: any): SpecialistProductInput {
    return {
      billingName: model?.billingName || null,
      price: {
        total: this.priceService.removeDecimalSeparator(model?.price?.total),
        currency: model?.price?.currency || null,
      },
    };
  }

  private setModel(product: SpecialistProductQuery) {
    this.model = {
      billingName: product?.billingName || null,
      price: {
        total: this.priceService.addDecimalSeparator(product?.price?.total),
        currency: product?.price?.currency || null,
      },
    };
  }

  private initTranslations(): void {
    this.translations$ = this.translate.get('SETTINGS.PRICE').pipe(
      tap((translations) => {
        this.translations = translations;
        this.setupFormFields();
      }),
    );
  }

  private listenToLangChange() {
    this.translate.onLangChange
      .pipe(
        tap(() => {
          this.initTranslations();
        }),
      )
      .subscribe();
  }

  private setupFormFields(): void {
    this.fields = [
      {
        fieldGroupClassName: 'form-group display-flex',
        fieldGroup: [
          {
            className: 'flex-3',
            key: 'price.total',
            type: 'input',
            templateOptions: {
              label: this.translations.TOTAL,
              type: 'number',
              placeholder: this.priceFormatDisplay,
              description: `${this.translations.PRICE_FORMAT} ${this.priceFormatDisplay}`,
            },
            modelOptions: {
              debounce: { default: 1000 },
            },
            expressionProperties: {
              'templateOptions.disabled': () => !this.isMedicalSpecialist,
            },
          },
          {
            className: 'flex-1',
            key: 'price.currency',
            type: 'select',
            templateOptions: {
              label: this.translations.CURRENCY,
              options: currencyOptions,
            },
            modelOptions: {
              debounce: { default: 1000 },
            },
            expressionProperties: {
              'templateOptions.disabled': () => !this.isMedicalSpecialist,
            },
          },
        ],
      },
    ];
  }
}
