<mat-card appearance="outlined">
  <mat-card-content>
    <h1 class="header">{{ 'AVAILABILITY.LOST.TITLE' | translate }}</h1>
    <span>{{ 'AVAILABILITY.LOST.MESSAGE' | translate }}</span>
    <mat-dialog-actions class="button-container">
      <button mat-raised-button [mat-dialog-close]="true" (click)="onDecline()">
        {{ 'AVAILABILITY.LOST.CANCEL' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="onConfirm()"
        [mat-dialog-close]="true"
      >
        {{ 'AVAILABILITY.LOST.OK' | translate }}
      </button>
    </mat-dialog-actions>
  </mat-card-content>
</mat-card>
<audio #ringtone src="assets/offline-alert.wav"></audio>
