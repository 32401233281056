import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../shared/material/material.module';
import { PatientMedicalModule } from '../patient-medical/patient-medical.module';
import { PatientPersonalModule } from '../patient-personal/patient-personal.module';
import { PatientClinicalReportModule } from '../patient-clinical-report/patient-clinical-report.module';
import { PatientControlsModule } from '../patient-controls/patient-controls.module';
import { PatientPastVisitsModule } from '../patient-past-visits/patient-past-visits.module';
import { PatientFilesModule } from '../patient-files/patient-files.module';
import { TranslateModule } from '@ngx-translate/core';
import { PatientsListModule } from '../patients-list/patients-list.module';
import { PatientDetailsComponent } from './patient-details.component';

@NgModule({
  declarations: [PatientDetailsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    PatientMedicalModule,
    PatientPersonalModule,
    PatientClinicalReportModule,
    PatientControlsModule,
    PatientPastVisitsModule,
    PatientFilesModule,
    TranslateModule,
    PatientsListModule,
  ],
  exports: [PatientDetailsComponent],
})
export class PatientDetailsModule {}
