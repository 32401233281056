import { Injectable } from '@angular/core';
import {
  APIService,
  Appointment,
  PageInput,
  PatientFilter,
  PatientListQuery,
} from '../API.service';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PatientsSearchService {
  constructor(private api: APIService) {}

  listPatients(filter?: PatientFilter, page?: PageInput) {
    console.log('listing patients for ', JSON.stringify({ filter, page }));
    return from(this.api.PatientList(filter, page));
  }
}
