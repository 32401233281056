import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { PatientMedicalFileQuery } from '../API.service';
import { ChimeService } from '../shared/chime/chime.service';
import { MeetingStatus } from '../shared/types/meeting-status';
import { PatientsService } from './patients.service';
import { DoctorStatusService } from '../shared/doctor-status/doctor-status.service';

@Component({
  selector: 'tdoc-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss'],
})
export class PatientsComponent implements OnInit {
  medicalFile$: Observable<PatientMedicalFileQuery>;

  constructor(
    private chimeService: ChimeService,
    private patientService: PatientsService,
  ) {}

  ngOnInit(): void {
    this.medicalFile$ = this.patientService.patientMedicalFile$;
  }
}
