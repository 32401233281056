import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HoursOfAvailabilityModule } from '../shared/hours-of-availability/hours-of-availability.module';
import { AccountSettingsModule } from './account-settings/account-settings.module';
import { ClinicModule } from './clinic/clinic.module';
import { PriceModule } from './price/price.module';
import { SettingsComponent } from './settings.component';

@NgModule({
  declarations: [SettingsComponent],
  imports: [
    CommonModule,
    AccountSettingsModule,
    HoursOfAvailabilityModule,
    PriceModule,
    ClinicModule,
  ],
  exports: [SettingsComponent],
})
export class SettingsModule {}
