import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { APIService, ClinicsQuery } from 'src/app/API.service';

@Injectable({
  providedIn: 'root',
})
export class ClinicService {
  constructor(private api: APIService) {}

  public getClinicList(): Observable<ClinicsQuery> {
    return from(this.api.Clinics()).pipe(filter((clinics) => !!clinics));
  }
}
