import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { IncomingMeetingRequestDialogComponent } from './incoming-meeting-request-dialog.component';
import { DeviceSetupModule } from '../device-setup/device-setup.module';

@NgModule({
  declarations: [IncomingMeetingRequestDialogComponent],
  imports: [CommonModule, MaterialModule, TranslateModule, DeviceSetupModule],
  exports: [IncomingMeetingRequestDialogComponent],
})
export class IncomingMeetingRequestDialogModule {}
