import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog';
import { FileUploadTableComponent } from './file-upload-table.component';

@NgModule({
  declarations: [FileUploadTableComponent, DeleteDialogComponent],
  imports: [CommonModule, MaterialModule, TranslateModule],
  exports: [FileUploadTableComponent],
})
export class FileUploadTableModule {}
