<ng-container *ngIf="translations$ | async">
  <div class="sign-up">
    <tdoc-logo></tdoc-logo>
    <form autocomplete="off" [formGroup]="signupForm" class="sign-up-form">
      <h2>{{ 'AUTH.SIGN_UP.HEADER' | translate }}</h2>
      <mat-form-field>
        <input
          id="emailAuth"
          matInput
          placeholder="{{ 'AUTH.SIGN_UP.EMAIL' | translate }}"
          type="email"
          formControlName="email"
          autocomplete="email"
          required
        />
        <mat-error *ngIf="!emailInput.valid">
          {{ getEmailInputError() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          id="passwordAuth"
          matInput
          placeholder="{{ 'AUTH.SIGN_UP.PASSWORD' | translate }}"
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
          required
        />
        <mat-icon matSuffix class="cursor-pointer" (click)="hide = !hide"
          >{{ hide ? 'visibility_off' : 'visibility' }}
        </mat-icon>
        <mat-error *ngIf="!passwordInput.valid">
          {{ getPasswordInputError() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-select
          id="languageAuth"
          formControlName="language"
          placeholder="{{ 'AUTH.SIGN_UP.LANGUAGE' | translate }}"
          required
        >
          <mat-option
            *ngFor="let language of languages$ | async"
            id="authLanguageDropdown{{ language.value }}"
            [value]="language.value"
          >
            {{ language.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox formControlName="has_agreed_to_terms" required
        >{{ 'AUTH.SIGN_UP.TERMS' | translate }}
        <a href="{{ 'AUTH.SIGN_UP.TERMS_URL' | translate }}">{{
          'AUTH.SIGN_UP.TERMS_LINK' | translate
        }}</a>
      </mat-checkbox>
      <button
        mat-raised-button
        color="primary"
        (click)="signUp()"
        [disabled]="shouldEnableSubmit()"
      >
        {{ 'AUTH.SIGN_UP.SIGN_UP' | translate }}
      </button>
      <p class="center">
        {{ 'AUTH.SIGN_UP.HAS_ACCOUNT' | translate
        }}<a routerLink="/auth/signin">{{
          'AUTH.SIGN_UP.SIGN_IN' | translate
        }}</a>
      </p>
    </form>
  </div>
  <div class="container">
    <tdoc-auth-language-selection></tdoc-auth-language-selection>
  </div>
</ng-container>
<tdoc-footer></tdoc-footer>
