import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProfileAddressModule } from './profile-address/profile-address.module';
import { ProfileBioModule } from './profile-bio/profile-bio.module';
import { ProfilePersonalModule } from './profile-personal/profile-personal.module';
import { ProfilePictureModule } from './profile-picture/profile-picture.module';
import { ProfileUploadModule } from './profile-upload/profile-upload.module';
import { ProfileComponent } from './profile.component';
import { FooterModule } from '../shared/footer/footer.module';

@NgModule({
  declarations: [ProfileComponent],
  imports: [
    CommonModule,
    ProfilePersonalModule,
    ProfileBioModule,
    ProfileAddressModule,
    ProfileUploadModule,
    ProfilePictureModule,
    FooterModule,
  ],
  exports: [ProfileComponent],
})
export class ProfileModule {}
