<mat-card>
  <mat-card-content>
    <h1>{{ data.title | translate }}</h1>
    <ng-container *ngIf="data.message">
      <span>{{ data.message | translate }}</span>
    </ng-container>
    <mat-dialog-actions class="button-container">
      <button mat-raised-button [mat-dialog-close]="true">
        {{ data.cancelTitle || 'DIALOG.ACTION.CANCEL' | translate }}
      </button>
      <button mat-raised-button color="primary" (click)="onConfirm()">
        {{ data.okTitle || 'DIALOG.ACTION.CONFIRM' | translate }}
      </button>
    </mat-dialog-actions>
  </mat-card-content>
</mat-card>
