<ng-container *ngIf="translations$ | async">
  <div class="container">
    <form [formGroup]="formGroup">
      <formly-form
        [model]="model"
        [fields]="fields"
        [options]="options"
        [form]="formGroup"
        (modelChange)="onLanguageChange()"
      ></formly-form>
    </form>
  </div>
</ng-container>
