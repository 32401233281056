import { Component, NgZone, OnInit } from '@angular/core';
import { PatientFilter, PatientMedicalPreview } from '../../API.service';
import { Observable } from 'rxjs';
import { PatientsSearchService } from '../patient-search.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

const PAGE_SIZE = 10;

@Component({
  selector: 'tdoc-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss'],
})
export class PatientsListComponent implements OnInit {
  patientsList: PatientMedicalPreview[];
  nextCursor: string;
  translations$: Observable<any>;
  translations: any;
  formGroup = new UntypedFormGroup({});
  model: PatientFilter = this.createListFilter();
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  loading: boolean = false;

  constructor(
    private router: Router,
    private searchService: PatientsSearchService,
    private translate: TranslateService,
    private ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.initTranslations();
    this.searchService
      .listPatients(this.model, { size: PAGE_SIZE })
      .subscribe((query) => {
        this.patientsList = query.results;
        this.nextCursor = query.pageInfo.nextCursor;
        this.loading = false;
      });
  }

  onPatientDetails(id: string): void {
    this.router.navigate([`/patient-details/${id}`]);
  }

  async onSubmit() {
    if (this.formGroup.valid) {
      this.loading = true;
      this.searchService
        .listPatients(this.model, { size: PAGE_SIZE })
        .subscribe((query) => {
          this.patientsList = query.results;
          this.nextCursor = query.pageInfo.nextCursor;
          this.loading = false;
        });
    }
  }

  private initTranslations(): void {
    this.translations$ = this.translate.get('PATIENTS.PERSONAL').pipe(
      tap((translations) => {
        this.translations = translations;
        this.setupFormFields();
      }),
    );
  }

  private createListFilter() {
    return { firstName: '', lastName: '', partnerName: '' };
  }

  private setupFormFields() {
    this.fields = [
      {
        fieldGroupClassName: 'form-group display-flex',
        fieldGroup: [
          {
            className: 'flex-3',
            key: 'firstName',
            type: 'input',
            templateOptions: {
              label: this.translations.FIRSTNAME,
              required: false,
            },
          },
          {
            className: 'flex-3',
            key: 'lastName',
            type: 'input',
            templateOptions: {
              label: this.translations.LASTNAME,
              required: false,
            },
          },
          {
            className: 'flex-3',
            key: 'partnerName',
            type: 'input',
            templateOptions: {
              label: this.translations.PARTNER_NAME,
              required: false,
            },
          },
        ],
      },
    ];
  }

  onScroll() {
    console.log('current page ', JSON.stringify(this.nextCursor));
    if (this.nextCursor) {
      console.log('fetching more');
      this.loading = true;
      this.searchService
        .listPatients(this.model, { cursor: this.nextCursor, size: PAGE_SIZE })
        .subscribe((query) => {
          this.patientsList.push(...query.results);
          this.nextCursor = query.pageInfo.nextCursor;
          this.loading = false;
        });
    }
  }
}
