import amplitude from 'amplitude-js';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

type TrackingSessionProps = {
  userId: string;
  region?: string;
  language?: string;
  productArea: string;
  userRole: string;
};

export interface TrackingService {
  setSessionProps(props: TrackingSessionProps): void;
  trackEvent(type: string, props: any);
}

@Injectable({
  providedIn: 'root',
})
export class AmplitudeTrackingService implements TrackingService {
  private initialized: boolean;

  constructor() {
    this.initialized = false;
  }

  public init() {
    if (!this.initialized) {
      amplitude.getInstance().init(environment.tracking.amplitude_key);
      this.initialized = true;
    }
  }

  public setSessionProps(props: TrackingSessionProps) {
    amplitude.getInstance().setUserId(props.userId);
    const identifyObj = new amplitude.Identify();
    identifyObj.set('region', props.region);
    identifyObj.set('lang', props.language);
    identifyObj.set('productArea', props.productArea);
    identifyObj.set('userRole', props.userRole);
    amplitude.getInstance().identify(identifyObj);
  }

  public trackEvent(type: string, props: any) {
    if (this.initialized) {
      amplitude.getInstance().logEvent(type, { ...props, date: new Date() });
    }
  }
}
