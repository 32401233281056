import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from 'aws-amplify';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Amplify.configure({
  Auth: {
    region: 'eu-central-1',
    userPoolWebClientId: environment.Auth.userPoolWebClientId,
    userPoolId: environment.Auth.userPoolId,
    mandatorySignIn: true,
    storage: window.sessionStorage,
  },
  aws_appsync_graphqlEndpoint: environment.aws_appsync_gql_doctorEndpoint,
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
