<ng-container *ngIf="weeklyAppointments$ | async as weeklyAppointments">
  <mat-card appearance="outlined">
    <mat-card-content class="layout">
      <mat-card-title>
        {{ 'APPOINTMENTS.WEEKLY.HEADER' | translate }}
      </mat-card-title>
      <div class="week-range-display">
        {{
          firstDayOfDisplayedWeek
            | date : 'MMM d y' : '' : translateService.currentLang
        }}
        - {{ lastDayOfDisplayedWeek | date : 'MMM d y' }}
      </div>
      <div class="appointment-panel-container">
        <ng-container
          *ngFor="let appointmentsByDay of weeklyAppointments; let i = index"
        >
          <tdoc-appointment-panel
            class="panels"
            [config]="{
              type: 'call',
              titleTranslateKey: dayOfWeekAsString(i)
            }"
            [appointments]="appointmentsByDay"
          >
          </tdoc-appointment-panel>
        </ng-container>
      </div>
      <mat-card-actions align="end" class="mat-card-actions-spacing">
        <button mat-raised-button (click)="onPreviousWeek()">
          {{ 'BUTTONS.PREVIOUS' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="onNextWeek()">
          {{ 'BUTTONS.NEXT' | translate }}
        </button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
</ng-container>
