import { Component, Input, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { DoctorProfileQuery } from 'src/app/API.service';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'tdoc-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
})
export class ProfilePictureComponent implements OnInit {
  @Input() profile: DoctorProfileQuery;
  imageUrl$: Observable<SafeUrl>;

  constructor(private profileService: ProfileService) {}

  ngOnInit(): void {
    this.imageUrl$ = this.getImageUrl();
  }

  uploadFile(file): void {
    this.profileService.uploadProfilePicture(file).pipe(take(1)).subscribe();
  }

  refreshPicture(): void {
    this.profileService.getProfile();
  }

  deletePicture(): void {
    this.profileService.deleteProfilePicture().pipe(take(1)).subscribe();
  }

  private getImageUrl(): Observable<SafeUrl | null> {
    return this.profileService.doctorProfile$.pipe(
      switchMap((p) => {
        const url: string = p.imageUrl;
        let safeUrl$: Observable<SafeUrl | null> = of(null);
        if (url) {
          safeUrl$ = this.profileService.getImage(url);
        }
        return safeUrl$;
      }),
    );
  }
}
