import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DoctorProfileQuery } from 'src/app/API.service';
import { ProfileService } from 'src/app/profile/profile.service';
import { NewPasswordDialogComponent } from '../new-password-dialog/new-password-dialog.component';

@Component({
  selector: 'tdoc-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit {
  @Input() profile: DoctorProfileQuery;
  translations$: Observable<any>;
  translations: any;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.profileService.getProfile();
  }

  openNewPasswordDialog(): void {
    this.dialog.open(NewPasswordDialogComponent, { width: '800px' });
  }

  private initTranslations() {
    this.translations$ = this.translate.get('SETTINGS.ACCOUNT').pipe(
      tap((translations) => {
        this.translations = translations;
      }),
    );
  }
}
