export enum AppRoutes {
  signin = 'signin',
  signup = 'signup',
  confirm = 'confirm',
  new_password = 'new-password',
  forgot = 'forgot',
  dashboard = 'dashboard',
  profile = 'profile',
  patients = 'patients',
  patient_details = 'patient-details/:id',
  settings = 'settings',
  stepper = 'stepper',
  appointments = 'appointments',
  wait_for_activation = 'wait-for-activation',
  call = 'call',
  device_check = 'device-check',
  connection_lost = 'connection-lost',
}
