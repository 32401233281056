import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ChimeService } from 'src/app/shared/chime/chime.service';
import { MeetingStatus } from 'src/app/shared/types/meeting-status';

@Component({
  selector: 'tdoc-patient-call',
  templateUrl: './patient-call.component.html',
  styleUrls: ['./patient-call.component.scss'],
})
export class PatientCallComponent implements OnInit {
  meetingStatus$: Observable<MeetingStatus>;
  meetingStatus = MeetingStatus;
  private status: MeetingStatus;

  @HostListener('window:beforeunload')
  checkMeeting(): boolean | void {
    if (this.status === MeetingStatus.Succeeded) {
      return false;
    }
  }

  constructor(private chimeService: ChimeService) {}

  ngOnInit(): void {
    this.meetingStatus$ = this.chimeService.meetingStatus$.pipe(
      tap((status) => (this.status = status)),
    );
  }
}
