import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DoctorProfileQuery } from 'src/app/API.service';
import { AuthLanguageSelectionService } from 'src/app/auth/auth-language-selection/auth-language-selection.service';
import { ProfileService } from 'src/app/profile/profile.service';
import { RegionService } from '../region/region.service';

@Component({
  selector: 'tdoc-profile-settings-form',
  templateUrl: './profile-settings-form.component.html',
  styleUrls: ['./profile-settings-form.component.scss'],
})
export class ProfileSettingsFormComponent implements OnInit {
  @Input() profile: DoctorProfileQuery;
  @Input() noDoctorTypeChoice = false;
  @Input() hideNextButton = false;
  @Input() disableRegionSelection = false;
  @Output() regionFormGroupValid = new EventEmitter();

  hideMedicalSpecialistSelection = false;
  translations$: Observable<any>;
  translations: any;
  formGroup = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  public medicalSpecialistControl = new FormControl();

  constructor(
    private profileService: ProfileService,
    private translate: TranslateService,
    private authLangSelectService: AuthLanguageSelectionService,
    private regionService: RegionService,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.model = this.profile;
    this.hideMedicalSpecialistSelection =
      this.profile.medicalSpecialist !== 'specialist';
    this.formGroup.statusChanges
      .pipe(tap(() => this.regionFormGroupValid.emit(this.formGroup.valid)))
      .subscribe();
    this.listenToLangChange();
  }

  onChange(): void {
    this.model.medicalSpecialist =
      this.formGroup.get('medicalSpecialist').value;
    this.profileService.updateProfile(this.model).pipe(take(1)).subscribe();
  }

  onSelectDoctor(): void {
    console.log('selecting doctor');
    this.resetMedicalSpecialistControls();
    this.hideMedicalSpecialistSelection = true;
    this.fields[0].hide = true;
    this.fields[1].hide = true;
    this.fields[2].hide = false;
  }

  onSelectMedicalSpecialist(): void {
    console.log('selecting specialist');
    this.updateMedicalSpecialistControl('specialist');
    this.hideMedicalSpecialistSelection = false;
    this.fields[0].hide = false;
    this.fields[1].hide = false;
    this.fields[2].hide = true;
  }

  private listenToLangChange() {
    this.translate.onLangChange
      .pipe(
        tap(() => {
          this.initTranslations();
        }),
      )
      .subscribe();
  }

  private initTranslations(): void {
    this.translations$ = this.translate.get('SETTINGS.ACCOUNT').pipe(
      tap((translations) => {
        this.translations = translations;
        this.setupFormFields();
      }),
    );
  }

  private setupFormFields(): void {
    this.addMedicalSpecialistControl();
    this.fields = [
      {
        id: 'medicalSpecialties',
        key: 'medicalSpecialties',
        type: 'select',
        props: {
          label: this.translations.MEDICAL_SPECIALTIES,
          options: this.profileService.getMedicalSpecialties(),
          required: true,
          multiple: true,
        },
        modelOptions: {
          debounce: { default: 1000 },
        },
        hide: this.hideMedicalSpecialistSelection || this.noDoctorTypeChoice,
      },
      {
        key: 'medicalSpecialties',
        type: 'textarea',
        props: {
          label: this.translations.MEDICAL_SUBSPECIALTIES,
          rows: 4,
        },
        modelOptions: {
          debounce: { default: 1000 },
        },
        hide: this.hideMedicalSpecialistSelection || this.noDoctorTypeChoice,
      },
      {
        id: 'regionSelection',
        key: 'region.code',
        type: 'select',
        props: {
          label: this.translations.REGION,
          options: this.regionService.getRegions(),
          required: true,
        },
        modelOptions: {
          debounce: { default: 1000 },
        },
        expressions: {
          'props.disabled': () => this.disableRegionSelection,
        },
        hide: !this.hideMedicalSpecialistSelection,
      },
      {
        id: 'spokenLanguage',
        key: 'languages',
        type: 'select',
        props: {
          label: this.translations.LANGUAGES,
          options: this.authLangSelectService.appLanguanges$,
          required: true,
          multiple: true,
        },
        modelOptions: {
          debounce: { default: 1000 },
        },
      },
    ];
  }

  private addMedicalSpecialistControl(): void {
    this.formGroup.addControl(
      'medicalSpecialist',
      this.medicalSpecialistControl,
    );
    this.updateMedicalSpecialistControl(
      (this.profile.medicalSpecialist as 'specialist') || null,
    );
  }

  private resetMedicalSpecialistControls(): void {
    this.formGroup.patchValue({
      medicalSpecialist: null,
      medicalSpecialties: null,
      medicalSubspecialties: null,
    });
  }

  private updateMedicalSpecialistControl(value: 'specialist' | null): void {
    this.formGroup.patchValue({
      medicalSpecialist: value,
    });
    this.formGroup.updateValueAndValidity();
  }
}
