export const environment = {
  production: true,
  enableActivation: true,
  confirm: {
    email: '',
    password: '',
  },
  Auth: {
    userPoolWebClientId: '3jijvboa4gbljjk8e1461ufk6m',
    userPoolId: 'eu-central-1_sxvKTSfeg',
  },
  aws_appsync_gql_doctorEndpoint:
    'https://mhxcljtrezgd3nfkshvv2juwdy.appsync-api.eu-central-1.amazonaws.com/graphql',
  assetsEndpoint: 'https://d3dlkw74p96uu.cloudfront.net',
  tracking: {
    amplitude_key: '8d0886906ea934b7ee98dd58a68972fa',
  },
};
