import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'tdoc-new-password-dialog',
  templateUrl: './new-password-dialog.component.html',
  styleUrls: ['./new-password-dialog.component.scss'],
})
export class NewPasswordDialogComponent implements OnInit {
  translations$: Observable<any>;
  translations: any;
  formGroup = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];

  constructor(
    private translate: TranslateService,
    private auth: AuthService,
    public dialogRef: MatDialogRef<NewPasswordDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
  }

  onConfirm() {
    const result = this.auth
      .changePassword(this.model.oldPassword, this.model.newPassword)
      .pipe(take(1))
      .subscribe((next) => {
        if (next) {
          this.dialogRef.close();
        }
      });
  }

  private initTranslations() {
    this.translations$ = this.translate.get('AUTH.RESET_PASSWORD_DIALOG').pipe(
      tap((translations) => {
        this.translations = translations;
        this.setupFormFields();
      }),
    );
  }

  private setupFormFields() {
    this.fields = [
      {
        key: 'oldPassword',
        type: 'input',
        templateOptions: {
          type: 'password',
          label: this.translations['OLD_PASSWORD'],
          required: true,
        },
      },
      {
        key: 'newPassword',
        type: 'input',
        templateOptions: {
          type: 'password',
          label: this.translations['PASSWORD'],
          required: true,
          minLength: 6,
        },
      },
      {
        key: 'newPasswordRepeat',
        type: 'input',
        templateOptions: {
          type: 'password',
          label: this.translations['REPEAT_PASSWORD'],
        },
        validators: {
          fieldMatch: {
            expression: (control) => control.value === this.model.newPassword,
            message: this.translations['NOT_MATCHING'],
          },
        },
      },
    ];
  }
}
