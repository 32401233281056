import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { take, tap } from 'rxjs/operators';
import { ProfileService } from 'src/app/profile/profile.service';
import { DoctorStatusService } from './doctor-status.service';
import { interval } from 'rxjs';
import {
  ActionDialogComponent,
  DialogActions,
} from '../action-dialog/action-dialog.component';
import { MatDialog, MatDialogState } from '@angular/material/dialog';
import { UserIdleService } from 'angular-user-idle';
import { DevicesService } from '../chime/devices.service';
import { SettingsDialogComponent } from '../../patients/patient-call/call-controls/settings-dialog/settings-dialog.component';

const PRESENCE_CHECK_SHOW_DIALOG_PERIOD_SEC = 20 * 60;
const PRESENCE_CHECK_TIMEOUT_MS = 30 * 1000;

@Component({
  selector: 'tdoc-doctor-status',
  templateUrl: './doctor-status.component.html',
  styleUrls: ['./doctor-status.component.scss'],
})
export class DoctorStatusComponent implements OnInit {
  hide = false;
  status: boolean;

  formGroup = this._formBuilder.group({
    available: '',
  });
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      className: 'toggle',
      key: 'available',
      type: 'toggle',
    },
  ];

  constructor(
    public doctorStatusService: DoctorStatusService,
    public profileService: ProfileService,
    private dialog: MatDialog,
    private userIdle: UserIdleService,
    private deviceService: DevicesService,
    private _formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.doctorStatusService.isDoctorAvailable$
      .pipe(
        tap((status) => {
          this.model = {
            available: status,
          };
          this.status = status;
        }),
      )
      .subscribe();

    this.userIdle.setConfigValues({
      idle: PRESENCE_CHECK_SHOW_DIALOG_PERIOD_SEC,
      timeout: PRESENCE_CHECK_TIMEOUT_MS,
      ping: 5,
    });
    this.userIdle.startWatching();
    this.userIdle
      .onTimerStart()
      .pipe(take(1))
      .subscribe(() => {
        this.initMarkAway();
      });
  }

  ngOnDestroy(): void {
    this.model.available = false;
    this.userIdle.resetTimer();
    this.userIdle.stopWatching();
  }

  checkAvailability() {
    const dialog = this.dialog.open(ActionDialogComponent, {
      data: { title: 'AVAILABILITY-CHECK.MESSAGE' },
      width: '500px',
    });
    interval(PRESENCE_CHECK_TIMEOUT_MS)
      .pipe(take(1))
      .subscribe((x) => {
        if (dialog.getState() === MatDialogState.OPEN) {
          console.log(
            'wait is over, was it confirmed? ',
            JSON.stringify(this.model),
          );
          if (!this.model.present) {
            console.log('marking doctor away ');
            this.doctorStatusService.setAway();
          }
          dialog.close();
        }
      });

    dialog
      .afterClosed()
      .pipe(
        tap((result) => {
          if (result === DialogActions.confirm) {
            this.model.present = true;
          }
          this.userIdle.startWatching();
          this.userIdle
            .onTimerStart()
            .pipe(take(1))
            .subscribe(() => {
              this.initMarkAway();
            });
        }),
      )
      .subscribe();
  }

  initMarkAway() {
    console.log('initialising marking away ', this.model.available);
    if (this.model.available) {
      this.model.present = false;
      this.checkAvailability();
      this.userIdle.stopWatching();
    }
  }

  changeStatus() {
    console.log('selected value ', JSON.stringify(this.status));
    if (this.status) {
      this.deviceService.deviceList$.pipe(take(1)).subscribe(async (next) => {
        if (next.currentAudioInputDevice && next.currentVideoInputDevice) {
          // camera and mic are set
          this.doctorStatusService.setAvailable();
          this.model.available = true;
        } else {
          this.doctorStatusService.setUnavailable();
          this.model.available = false;
          // no camera or mic
          const dialog = this.dialog.open(ActionDialogComponent, {
            data: {
              title: 'DEVICE_CHECK.TITLE',
              message: 'DEVICE_CHECK.MESSAGE',
              cancelTitle: 'DEVICE_CHECK.CANCEL',
              okTitle: 'DEVICE_CHECK.OK',
            },
            width: '500px',
          });
          dialog.afterClosed().subscribe((result) => {
            if (result === DialogActions.confirm) {
              this.dialog.open(SettingsDialogComponent, {
                width: '500px',
              });
            }
          });
        }
      });
    } else {
      this.doctorStatusService.setUnavailable();
      this.model.available = false;
    }
  }
}
