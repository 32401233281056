import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DoctorProfileQuery } from 'src/app/API.service';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'tdoc-profile-bio',
  templateUrl: './profile-bio.component.html',
  styleUrls: ['./profile-bio.component.scss'],
})
export class ProfileBioComponent implements OnInit {
  @Input() profile: DoctorProfileQuery;

  translations$: Observable<any>;
  translations: any;
  formGroup = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];

  constructor(
    private profileService: ProfileService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.model = this.profile;
  }

  onChange() {
    this.profileService.updateProfile(this.model).pipe(take(1)).subscribe();
  }

  private initTranslations() {
    this.translations$ = this.translate.get('PROFILE').pipe(
      tap((translations) => {
        this.translations = translations;
        this.setupFormFields();
      }),
    );
  }

  private setupFormFields() {
    this.fields = [
      {
        key: 'biography',
        id: 'bio',
        type: 'textarea',
        templateOptions: {
          label: this.translations['BIO'],
          rows: 10,
          resize: 'none',
        },
        modelOptions: {
          debounce: { default: 1000 },
        },
      },
    ];
  }
}
