import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  APIService,
  SetSpecialistProductMutation,
  SpecialistProductInput,
  SpecialistProductQuery,
} from 'src/app/API.service';

@Injectable({
  providedIn: 'root',
})
export class PriceService {
  constructor(private api: APIService) {}

  public setSpecialistProduct(
    product: SpecialistProductInput,
  ): Observable<SetSpecialistProductMutation> {
    return from(
      this.api.SetSpecialistProduct(this.addProductNameForBackend(product)),
    );
  }

  public getSpecialistProduct(): Observable<SpecialistProductQuery> {
    return from(this.api.SpecialistProduct()).pipe(
      filter((product) => !!product),
    );
  }

  private addProductNameForBackend(
    product: SpecialistProductInput,
  ): SpecialistProductInput {
    const mappedProduct = {
      ...product,
      billingName: '',
    };
    return mappedProduct;
  }

  public addDecimalSeparator(price: number): number | null {
    let double = null;
    const priceString = price.toString();
    const charPosition = -2;
    const separator = '.';
    if (typeof price === 'number') {
      double = Number(
        priceString.slice(0, charPosition) +
          separator +
          priceString.slice(charPosition),
      );
    }
    return double;
  }

  public removeDecimalSeparator(price: number): number | null {
    let int = null;
    let priceString = price.toString();
    if (typeof price === 'number') {
      const charAtPosition = priceString.slice(-2, -1);
      if (charAtPosition === ',' || charAtPosition === '.') {
        priceString = `${priceString}0`;
      }
      if (priceString.includes('.') || priceString.includes(',')) {
        int = Number(priceString.replace(/[.,]/g, ''));
      } else {
        int = price * 100;
      }
    }
    return int;
  }
}
