<audio #audioElementId></audio>

<ng-container class="centered-content" *ngIf="isLoading; else content">
  <div class="centered-content">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</ng-container>

<ng-template #content>
  <ng-container *ngIf="medicalFile$ | async as medicalFile">
    <div class="patient-layout">
      <tdoc-patient-personal
        disabled="false"
        class="personal-card"
        [medicalFile]="medicalFile"
      ></tdoc-patient-personal>
      <tdoc-patient-past-visits
        class="past-visits-card"
        [medicalFile]="medicalFile"
      ></tdoc-patient-past-visits>
      <tdoc-patient-call class="call-card"></tdoc-patient-call>
      <tdoc-patient-medical
        class="medical-card"
        [medicalFile]="medicalFile"
      ></tdoc-patient-medical>
      <tdoc-patient-clinical-report
        class="report-card"
        [medicalFile]="medicalFile"
      ></tdoc-patient-clinical-report>
      <tdoc-patient-controls class="control-card"></tdoc-patient-controls>
      <tdoc-patient-files
        class="files-card"
        [medicalFile]="medicalFile"
      ></tdoc-patient-files>
    </div>
  </ng-container>
</ng-template>

<ng-container *ngIf="meetingStatus$ | async"></ng-container>

<ng-container
  *ngIf="
    !isLoading && (medicalFile$ | async) === undefined && translations$ | async
  "
>
  <div class="centered-content">
    <div class="no-meeting">{{ 'PATIENTS.NO_CALL.HEADER' | translate }}</div>
    <button mat-raised-button color="primary" (click)="navigateToDashboard()">
      {{ 'NAVIGATION.DASHBOARD.HEADER' | translate }}
    </button>
  </div>
</ng-container>
