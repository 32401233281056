import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, first, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { DoctorStatusService } from 'src/app/shared/doctor-status/doctor-status.service';
import { AppointmentsService } from 'src/app/appointments/appointments.service';
import { Appointment, AppointmentStatus } from 'src/app/API.service';

import { AppointmentRequestDialogComponent } from './appointment-request-dialog-component';

@Injectable({
  providedIn: 'root',
})
export class AppointmentRequestDialogService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private isDialogOpen = new BehaviorSubject<boolean>(false);
  private isDialogOpen$ = this.isDialogOpen.asObservable();
  private doctorIsInCall$ = this.doctorStatus.isInCall$;
  private pendingAppointments$ = this.appointmentService.pendingAppointments$;

  appointmentRequestDialog$ = combineLatest([
    this.doctorIsInCall$,
    this.isDialogOpen$,
    this.pendingAppointments$,
  ]).pipe(
    takeUntil(this.destroy$),
    filter(
      ([isInCall, isDialogOpen, pendingAppointments]) =>
        !isInCall && !isDialogOpen && pendingAppointments.length > 0,
    ),
    tap(() => {
      this.isDialogOpen.next(true);
      this.openAppointmentRequestDialog();
    }),
  );

  constructor(
    private doctorStatus: DoctorStatusService,
    private appointmentService: AppointmentsService,
    private dialog: MatDialog,
  ) {}

  private openAppointmentRequestDialog(): void {
    this.pendingAppointments$
      .pipe(
        takeUntil(this.destroy$),
        first((appointments: Appointment[]) =>
          appointments.some(
            (appointment) =>
              appointment.appointmentStatus ===
              AppointmentStatus.DOCTOR_CONFIRMATION_PENDING,
          ),
        ),
        tap(() => {
          const dialog = this.dialog.open(AppointmentRequestDialogComponent, {
            width: '500px',
          });

          dialog
            .afterClosed()
            .subscribe(() => this.postponedAppointmentRequest());
        }),
      )
      .subscribe();
  }

  setIsDialogOpen(status: boolean): void {
    this.isDialogOpen.next(status);
  }

  private postponedAppointmentRequest(): void {
    setTimeout(() => {
      this.setIsDialogOpen(false);
    }, 5 * 60 * 1000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
