import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../shared/material/material.module';
import { AuthLanguageSelectionModule } from './auth-language-selection/auth-language-selection.module';
import { AuthComponent } from './auth.component';
import { ConfirmCodeComponent } from './confirm-code/confirm-code.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LogoModule } from '../shared/logo/logo.module';
import { FooterModule } from '../shared/footer/footer.module';
import { NewPasswordComponent } from './new-password/new-password.component';

@NgModule({
  declarations: [
    AuthComponent,
    ConfirmCodeComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AuthLanguageSelectionModule,
    LogoModule,
    FooterModule,
  ],
  exports: [
    AuthComponent,
    ConfirmCodeComponent,
    SignInComponent,
    SignUpComponent,
  ],
})
export class AuthModule {}
