import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../shared/material/material.module';
import { PatientCallModule } from './patient-call/patient-call.module';
import { PatientClinicalReportModule } from './patient-clinical-report/patient-clinical-report.module';
import { PatientControlsModule } from './patient-controls/patient-controls.module';
import { PatientFilesModule } from './patient-files/patient-files.module';
import { PatientMedicalModule } from './patient-medical/patient-medical.module';
import { PatientPastVisitsModule } from './patient-past-visits/patient-past-visits.module';
import { PatientPersonalModule } from './patient-personal/patient-personal.module';
import { PatientsComponent } from './patients.component';
import { PatientsListModule } from './patients-list/patients-list.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CallOnlyComponent } from './call-only/call-only.component';

@NgModule({
  declarations: [PatientsComponent, CallOnlyComponent],
  imports: [
    CommonModule,
    MaterialModule,
    PatientCallModule,
    PatientMedicalModule,
    PatientPersonalModule,
    PatientClinicalReportModule,
    PatientControlsModule,
    PatientPastVisitsModule,
    PatientFilesModule,
    TranslateModule,
    PatientsListModule,
    ScrollingModule,
    MatProgressSpinnerModule,
  ],
  exports: [PatientsComponent, CallOnlyComponent],
})
export class PatientsModule {}
